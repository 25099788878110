import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { STATUS } from '../../utils/status';
import { AnalyticsState } from '../interfaces/interface';
import { getAnalytics } from '../slices/analytics';

const ExtraReducers = (builder: ActionReducerMapBuilder<AnalyticsState>) => {
  builder
  .addCase(getAnalytics.pending, (state) => {
    state.getStatus = STATUS.PENDING;
  })
  .addCase(getAnalytics.fulfilled, (state, action) => {
    state.getStatus = STATUS.SUCESS;
    state.analytics = action.payload;
  })
  .addCase(getAnalytics.rejected, (state, action) => {
    state.getStatus = STATUS.FAILED;
  });
};

export default ExtraReducers;
