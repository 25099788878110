import React, { useState, ChangeEvent, useRef, useEffect } from 'react';
import { Button, Typography, Box } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CloseIcon from '@mui/icons-material/Close';
import { getProject } from '../../redux/slices/projectSlice/addProjectSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/store/appStore';
import { useLocation, useParams } from 'react-router-dom';
import SnackbarNotification from '../snackbar/snackbarNotification';
import SpreadsheetImportWizard from './uploadExcel';

const UploadTask = () => {
  const dispatch = useDispatch<AppDispatch>();
  const id = useParams();
  const location = useLocation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<{ file: File; name: string }[]>([]);
  const [fileUrls, setFileUrls] = useState<string[]>([]);
  const [isVisible, setIsVisible] = useState<boolean>(false); 
  const [dataCategory, setDataCategory] = useState<string>(''); 
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning'>('success');

  const { projectDetails: initialprojectDetails, id: projectId } = location.state || { projectDetails: null, id: '' };

  useEffect(() => {
    if (initialprojectDetails) {
      const { annotation_method } = initialprojectDetails;
      const data_category = annotation_method?.data_category || '';

      setDataCategory(data_category); 
      setIsVisible(data_category === 'IMAGE');
    }
  }, [initialprojectDetails]);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const validFiles = Array.from(e.target.files).filter((file) =>
        file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg'
      );

      if (validFiles.length < e.target.files.length) {
        handleSnackbar('You can upload only images(png/jpg/jpeg)', 'error');
      }

      const newFiles = validFiles.map((file) => ({
        file,
        name: file.name.replace(/\s/g, '_'), 
      }));

      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
      const urls = validFiles.map((file) => URL.createObjectURL(file));
      setFileUrls((prevUrls) => [...prevUrls, ...urls]);
    }
  };

  const deleteFile = (index: number) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);

    const newUrls = [...fileUrls];
    newUrls.splice(index, 1);
    setFileUrls(newUrls);
  };

  useEffect(() => {
    dispatch(getProject({}));
  }, [dispatch]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSnackbar = (message: string, severity: 'success' | 'error' | 'info' | 'warning') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  return (
    <Box>
      <div
            style={{
              borderBottom: '1px solid',
              borderColor: '#E8EAEF',
              margin: 2,
              padding: '0px, 24px, 0px, 24px',
            }}
          > 
            <SpreadsheetImportWizard/>
          </div>
    </Box>
  );
};

export default UploadTask;
