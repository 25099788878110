import React, { useEffect, useState } from 'react'
import AllocationModal from '../../uploadTask/allocateModal'
import { AppDispatch, RootState } from '../../../redux/store/appStore'
import { useDispatch, useSelector } from 'react-redux'
import { clearEmails } from '../../../redux/reducers/selectedEmail'
import { clearEmailss } from '../../../redux/reducers/selectedEmailReviewer'
import { useParams } from 'react-router-dom'
import { getTaskers } from '../../../redux/slices/expertSlice/addExpertSlice'
import { getProject } from '../../../redux/slices/projectSlice/addProjectSlice'

interface Type1AllocationEmailProps{
    allocationType: string;
}

const getUniqueTaskers = (taskers: any[]) => {
  const uniqueTaskers: any[] = [];
  const seenEmails = new Set<string>();

  taskers && taskers.forEach(tasker => {
    if (!seenEmails.has(tasker.email)) {
      uniqueTaskers.push(tasker);
      seenEmails.add(tasker.email);
    }
  });

  return uniqueTaskers;
};

const Type1AllocationEmail: React.FC<Type1AllocationEmailProps> = ({allocationType}) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState<string | null>(null);
  const selectedIds = useSelector((state: RootState) => state.tasks.selectedIds);
  const dispatch = useDispatch<AppDispatch>();
  const {id} = useParams();

  React.useEffect(()=>{
      dispatch(getProject({ project_id: id }));
  },[id])

  const handleCloseModal = () => {
    setOpenModal(false);
    
    if (allocationType && allocationType === 'TASK') {
      dispatch(clearEmails());
    } else {
      dispatch(clearEmailss());
    }
  };

  const handleEmailClick = ({ id, email }: { id: string; email: string }) => {
    setSelectedEmail(email);
  };

  // useEffect(()=>{
  //   dispatch(clearEmails());
  // },[window.location.reload])

  const handleAllocateClick = () => {
  };

  useEffect(() => {
    dispatch(getTaskers({ projectId: id || '' }));
  }, [dispatch, id]);

  return (
    <div>
      {selectedIds.length !==0 &&
      <AllocationModal
        open={openModal}
        onClose={handleCloseModal}
        onEmailClick={handleEmailClick}
        selectedEmail={selectedEmail}
        onAllocateClick={handleAllocateClick}
        isCheckboxMode={true}
        selectedIds={selectedIds}
        allocationType={allocationType}
      />
      }
    </div>
  )
}

export default Type1AllocationEmail
