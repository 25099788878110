import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import UserListHeading from '../../components/userList/userListHeading';
import UserListTable from '../../components/userList/userListTable';
import { Divider, Typography } from '@mui/material';

const UserListMain = () => {
  return (
    <div style={{ width: '100vw', overflow: 'hidden' }}>
      <Box sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '0rem' }}>
        <UserListHeading />
      </Box>
      <Divider />

      <Box component="main" sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '1rem' }}>
        <Paper sx={{
          bgcolor: '#FFFFFF',
          borderRadius: '8px',
          padding: ".5rem",
          mb: '1rem',
          boxShadow: 2,
        }}>
          <div
            style={{
              borderBottom: '1px solid',
              borderColor: '#E8EAEF',
              margin: 2,
              padding: '0px, 4px, 0px, 4px',
            }}
          > 
            <UserListTable/>
          </div>
        </Paper>
      </Box>
    </div>
  );
                    }
  export default UserListMain;   
