import React, { useEffect, useState } from 'react';
import { Box, Typography, Container } from '@mui/material';
import { Dropzone, FileItem, ExtFile } from '@dropzone-ui/react';
import { AddButton } from '../../button/button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useDispatch, useSelector } from 'react-redux';
import { clearFile, uploadFile, readSheets } from '../../../redux/reducers/sheetAllocationType3';
import { AppDispatch, RootState } from '../../../redux/store/appStore';

interface StepFirstProps {
  setSheets?: any;
}

const StepFirst: React.FC<StepFirstProps> = ({ setSheets }) => {
  const [files, setFiles] = useState<ExtFile[]>([]);
  const dispatch = useDispatch<AppDispatch>();
  const { file, sheets } = useSelector((state: RootState) => state.fileAllocation);

  useEffect(() => {
    if (file) {
      setFiles([{ file }]);
    }
  }, [file]);

  useEffect(() => {
    if (sheets) {
      setSheets(sheets);
    }
  }, [sheets, setSheets]);

  const handleDrop = (newFiles: ExtFile[]) => {
    setFiles(newFiles);
    if (newFiles.length > 0) {
      const file = newFiles[0].file;
      if (file) {
        dispatch(uploadFile(file)); 
        dispatch(readSheets(file)); 
      }
    }
  };

  // useEffect(()=>{
  //   dispatch(clearFile());
  // },[window.location.reload])

  const handleDelete = () => {
    setFiles([]);
    dispatch(clearFile());
  };

  return (
    <Container maxWidth="md">
      <Box sx={{
        background: 'linear-gradient(135deg, #4a90e2, #add8e6)',
        borderRadius: '10px',
        padding: '20px',
        marginTop: '20px',
        boxShadow: 3,
      }}>
        <Typography variant="h6" align="center" sx={{ color: '#fff', marginBottom: '20px', fontWeight: '600' }}>
          Upload Your File
        </Typography>
        <Box mt={2} display="flex" flexDirection="column" alignItems="center" position="relative">
          <Dropzone
            onChange={handleDrop}
            // accept={'.csv,.xls,.xlsx'}
            accept={'.xlsx'}
            maxFiles={1}
            header={false}
            footer={false}
            style={{
              border: '2px dashed #4a90e2',
              borderRadius: '10px',
              padding: '20px',
              backgroundColor: '#ffffff',
              width: '100%',
              textAlign: 'center',
              cursor: 'pointer'
            }}
          >
            {files.length === 0 ? (
              <>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="body2" sx={{ textTransform: 'none', fontSize: '1rem', fontWeight: 'bold', color: '#333' }}>
                    Upload a file here
                  </Typography>
                  <Typography variant="body2" sx={{ textTransform: 'none', fontSize: '14px', color: '#333', marginTop: '10px' }}>
                    You can upload .xlsx
                  </Typography>
                  <AddButton>Choose a file</AddButton>
                </div>
              </>
            ) : (
              files.map((file, index) => (
                <FileItem
                  key={index}
                  file={file.file}
                  onDelete={handleDelete}
                />
              ))
            )}
          </Dropzone>
        </Box>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Box mt={2} display="flex" justifyContent="space-around">
            <Box display="flex" alignItems="center" width="100%">
              <Box flexGrow={1} borderBottom="1px solid black" />
              <Typography variant="body1" mx={2}>
                OR
              </Typography>
              <Box flexGrow={1} borderBottom="1px solid black" />
            </Box>
          </Box>
          <div style={{ display: 'flex', marginLeft: '30%' }}>
            <AddButton disabled><FileUploadIcon />Create Manually</AddButton>
            <AddButton component="a" href="https://data-platform-resources.s3.ap-south-1.amazonaws.com/web-platform-assets/BAT3_Template.xlsx"><FileDownloadIcon /> Download Template</AddButton>
          </div>
        </div>
      </Box>
    </Container>
  );
};

export default StepFirst;
