import { createSlice } from "@reduxjs/toolkit";

type User = {
  name: string;
  photo: string;
};

type UserState = {
  currentUser: User | null;
  isAuthenticated: boolean;
};

const initialState: UserState = { currentUser: null, isAuthenticated: false };
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    signIn: (state, action) => {
      state.currentUser = action.payload;
      state.isAuthenticated = true;
    },
    signOut: (state) => {
      state.currentUser = null;
      state.isAuthenticated = false;
    },
  },
});

export const { signOut, signIn } = userSlice.actions;
export default userSlice.reducer;
