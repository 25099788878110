import axios from 'axios';
import { BASE_URL } from './constants';
import { deleteCookie, getCookie } from '../components/login/cookies';

export const getAuthTokenFromLocalStorage = () => {
    return localStorage.getItem('authToken') || null;
};

const api = axios.create({
    baseURL: BASE_URL,
    timeout: 450000,
});
  

api.interceptors.request.use(
    response => {
        const authToken = getCookie("token");
        if (authToken) {
            response.headers['Authorization'] = `Bearer ${authToken}`;
        }
        return response;
    }
);

api.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (error.response && error.response.status === 403) {
        console.log('Detected 403 status');
        deleteCookie("token");
        window.location.href = '/login';
      }
      return Promise.reject(error);
    }
);

export const headers = {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Authorization': 'Bearer ' + getCookie("token"),
};

export const createRoute = async (requestData: any): Promise<any> => {
    try {
        const response = await api.post(
            '/route/',
            requestData,
            { headers: headers }
        );
        return response.data;
    } catch (error) {
        handleAxiosError(error);
    }
};

export const handleAxiosError = (error: any) => {
    if (axios.isAxiosError(error)) {
        console.error('Axios Error:', error);
        if (error.response) {
            console.error('Response Data:', error.response.data);
            throw new Error(error.response.data.message || 'Failed to process the request');
        } else if (error.request) {
            console.error('No response received from server');
            throw new Error('No response received from server');
        } else {
            console.error('Error setting up the request');
            throw new Error('Error setting up the request');
        }
    } else {
        console.error('Unexpected Error:', error);
        throw new Error('An unexpected error occurred');
    }
};

export default api;
