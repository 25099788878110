import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";

interface PdfShowActionProps {
    action_value?: any;
}

export default function PdfShowAction({ action_value }: PdfShowActionProps) {
    const [loading, setLoading] = useState(true);
    const [isValidPdf, setIsValidPdf] = useState(true);

    useEffect(() => {
        if (action_value) {
            const hasInvalidPdf = action_value.some(
                (action: any) =>
                    action.config_details.default_value &&
                    !action.config_details.default_value.endsWith(".pdf")
            );
            setIsValidPdf(!hasInvalidPdf);
        }
    }, [action_value]);

    const handleLoad = () => {
        setLoading(false);
    };

    return (
        <>
            {action_value && action_value.map((action: any, index: number) => (
                <div key={index} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <Typography sx={{ fontWeight: 'bold', marginRight: '20px' }}>{action.config_details.value.title} :</Typography>
                    {loading && isValidPdf && <p>Loading PDF...</p>}
                    {action.config_details.default_value && action.config_details.default_value.endsWith(".pdf") ? (
                        <iframe
                            src={action.config_details.default_value}
                            title="PDF Document"
                            width="50%"
                            height="500px"
                            onLoad={handleLoad}
                            style={{ display: loading ? 'none' : 'block' }}
                        />
                    ) : (
                        <p style={{ fontSize: '15px' }}>{action.config_details.default_value ? "Not a valid PDF link." : "PDF not available."}</p>
                    )}
                </div>
            ))}
        </>
    );
}
