import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DeadlineState {
  deadline: string | null;
}

const initialState: DeadlineState = {
  deadline: null,
};

const deadlineSlice = createSlice({
  name: 'deadline',
  initialState,
  reducers: {
    setDeadline: (state, action: PayloadAction<string>) => {
      state.deadline = action.payload;
    },
    clearDeadline: (state) => {
      state.deadline = null;
    },
  },
});

export const { setDeadline, clearDeadline } = deadlineSlice.actions;
export default deadlineSlice.reducer;
