import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';
import { DeliveredState } from '../../interfaces/interface';
import extraReducers from '../../extraReducers/deliveredFileExtraReducer';

const initialState: DeliveredState = {
  delivered:{
    count: undefined,
    results: []
  },
  createDeliveredStatus: STATUS.NOT_STARTED,
};

export const createUploadDeliveredFile = createAsyncThunk(
    'task/mark/delivered/company/POST',
    async (payload: { id: string, selectedFile: File }, thunkAPI) => {
      const { id, selectedFile } = payload;
      try {
        const formData = new FormData();
        formData.append('file', selectedFile);
        const response = await api.post(`task/mark/delivered/company/?project_id=${id}`, formData);
        return response.data;
      } catch (error:any) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  );

const MarkDelivereduploadSlice = createSlice({
  name: 'delivered',
  initialState,
  reducers: {
    setCreateDeliveredStatus(state,action){
      state.createDeliveredStatus = action.payload
    },
  },
  extraReducers
});

export const {setCreateDeliveredStatus} = MarkDelivereduploadSlice.actions
export default MarkDelivereduploadSlice.reducer;
