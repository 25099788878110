import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import * as XLSX from 'xlsx';

interface FileState {
  file: File | null;
  sheets: any[] | null;
  loading: boolean;
  error: string | null;
}

const initialState: FileState = {
  file: null,
  sheets: null,
  loading: false,
  error: null,
};

export const uploadFile = createAsyncThunk(
  'fileAllocation/uploadFile',
  async (file: File, { rejectWithValue }) => {
    try {
      return file;
    } catch (error) {
      return rejectWithValue('Failed to upload file');
    }
  }
);

export const readSheets = createAsyncThunk(
  'fileAllocation/readSheets',
  async (file: File, { rejectWithValue }) => {
    try {
      return new Promise<any[]>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const binaryStr = e.target?.result;
          if (typeof binaryStr === 'string') {
            const workbook = XLSX.read(binaryStr, { type: 'binary' });
            const sheetDataArray = workbook.SheetNames.map(sheetName => {
              const worksheet = workbook.Sheets[sheetName];
              const sheetData = XLSX.utils.sheet_to_json<any[][]>(worksheet, { header: 1 });
              return { name: sheetName, data: sheetData };
            });
            resolve(sheetDataArray);
          } else {
            reject('Failed to read file content');
          }
        };
        reader.onerror = () => reject('File reading error');
        reader.readAsBinaryString(file);
      });
    } catch (error) {
      return rejectWithValue('Failed to read sheets');
    }
  }
);

const fileAllocationSlice = createSlice({
  name: 'fileAllocation',
  initialState,
  reducers: {
    clearFile: (state) => {
      state.file = null;
      state.sheets = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadFile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(uploadFile.fulfilled, (state, action: PayloadAction<File>) => {
        state.file = action.payload;
        state.loading = false;
      })
      .addCase(uploadFile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(readSheets.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(readSheets.fulfilled, (state, action: PayloadAction<any[]>) => {
        state.sheets = action.payload;
        state.loading = false;
      })
      .addCase(readSheets.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { clearFile } = fileAllocationSlice.actions;
export default fileAllocationSlice.reducer;
