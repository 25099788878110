import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { createExpert, createMultipleExpert, getExpert, getReviewers, getTaskers, updateExpert} from '../slices/expertSlice/addExpertSlice';
import { STATUS } from '../../utils/status';
import { ExpertTypeState} from '../interfaces/interface';

const ExtraReducers = (builder: ActionReducerMapBuilder<ExpertTypeState>) => {
  builder
    .addCase(createExpert.pending, (state) => {
      state.createStatus = STATUS.PENDING;
    })
    .addCase(createExpert.fulfilled, (state, action: PayloadAction<any>) => { 
      state.experts = action.payload; 
      state.createStatus = STATUS.SUCESS; 
    })
    .addCase(createExpert.rejected, (state, action) => { 
      state.createStatus = STATUS.FAILED;
    })
    .addCase(createMultipleExpert.pending, (state) => {
      state.createMultipleStatus = STATUS.PENDING;
    })
    .addCase(createMultipleExpert.fulfilled, (state, action: PayloadAction<any>) => { 
      state.experts = action.payload; 
      state.createMultipleStatus = STATUS.SUCESS; 
    })
    .addCase(createMultipleExpert.rejected, (state, action) => { 
      state.createMultipleStatus = STATUS.FAILED;
    })
    .addCase(getExpert.pending, (state) => {
      state.getStatus = STATUS.PENDING;
    })
    .addCase(getExpert.fulfilled, (state, action: PayloadAction<any>) => { 
      state.experts = action.payload; 
      state.getStatus = STATUS.SUCESS; 
    })
    .addCase(getExpert.rejected, (state, action) => { 
      state.getStatus = STATUS.FAILED;
    })
    .addCase(updateExpert.pending, (state) => {
      state.updateStatus = STATUS.PENDING;
    })
    .addCase(updateExpert.fulfilled, (state, action: PayloadAction<any>) => {
      const updateExpert = action.payload;
      state.experts.results = state.experts.results.map((expert: any) =>
        expert.id === updateExpert.id ? updateExpert : expert
      );
      state.updateStatus = STATUS.SUCESS;
    })
    .addCase(updateExpert.rejected, (state, action) => {
      state.updateStatus = STATUS.FAILED;
    })

    .addCase(getReviewers.pending, (state) => {
      state.getReviewerStatus = STATUS.PENDING;
    })
    .addCase(getReviewers.fulfilled, (state, action: PayloadAction<any[]>) => {
      state.getReviewerStatus = STATUS.SUCESS;
      state.reviewers = action.payload; // Ensure this matches the useSelector
    })
    .addCase(getReviewers.rejected, (state) => {
      state.getReviewerStatus = STATUS.FAILED;
    })

    .addCase(getTaskers.pending, (state, action) => {
      state.getTaskersStatus = STATUS.PENDING;
    })
    .addCase(getTaskers.fulfilled, (state, action:PayloadAction<any[]>) => {
      state.getTaskersStatus = STATUS.SUCESS;
      state.taskers = action.payload
    })
    .addCase(getTaskers.rejected, (state, action) => {
      state.getTaskersStatus = STATUS.FAILED
    })
};

export default ExtraReducers;
