import React from 'react';
import { TableRow } from '@mui/material';
import { TableCellBody } from '../table/tableCell';

interface TableRowItemProps {
  row: any;
}

const UnallocationTableRowItem: React.FC<TableRowItemProps> = ({ row }) => {
  return (
    <>
      <TableRow
        key={row.email}
        sx={{
          '&:last-child td, &:last-child th': { borderBottom: 0 },
        }}
      >
      <TableCellBody>{row.vendor_name}</TableCellBody>
      <TableCellBody>{row.total_after_this_unallocated}</TableCellBody>
      <TableCellBody>{row.total_tasks_allocated_till_now}</TableCellBody>
      <TableCellBody>{row.total_allocated_tasks_to_experts_by_this_vendor}</TableCellBody>
    </TableRow >
    </>
  );
};

export default UnallocationTableRowItem;
