import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import useStyles from '../../assets/styles/style';
import Config from '../../components/config/config';
import ConfigHeading from '../../components/config/configHeading';

const ConfigMain = () => {
  const classes = useStyles(); 
  return (
    <div className={classes.formMainContainer}>
      <Box className={classes.formMainHeadingBox}>
        <ConfigHeading />
      </Box>
      <Box component="main" className={classes.formMainBox}>
        <Paper className={classes.formMainpaper}>
          <Config />
        </Paper>
      </Box>
    </div>
  );
};

export default ConfigMain;
