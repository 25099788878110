import TickList from "../../components/login/Login/TickList";
import Logo from "../../components/login/Logo";
import LoginAndTerms from "../../components/login/Login/LoginAndTerms";
import SignInSide from "../../components/login/Login/signIn";
import OAuth from "../../components/login/GoogleOAuth";
import { Box, colors, Divider, Typography } from "@mui/material";
import image1 from "../../assets/img/onee.png";
import image2 from "../../assets/img/two.png"
import image3 from "../../assets/img/four.png"
import logo from "../../assets/img/Accunai_Logo_Blue.png"


export default function LoginPage() {

  const text = `LLM Fine-Tuning, RLHF & SFT, Annotation, Model Evaluation, AI Development`
  const words = text.split(',')

  const styles: any = {
    container: {
      color: "white",
      maxWidth: "530px",
      margin: "0 auto",
      padding: "20px",
      textAlign: "justify",
      lineHeight: "1.8",
      fontSize: "44px",
      letterSpacing: "2px",
      fontWeight: "800",
      fontFamily: "Poppins",
      position: 'relative'
    },
    accelerate: {
      position: "absolute",
      top: "0",
      left: "55",
      fontSize: "50px",
      fontFamily: "Poppins",
      textAlign: "center",
      fontWeight: "900",
      background: "linear-gradient(90deg, #FF5F6D, #FFC371)",
      WebkitBackgroundClip: "text",
      color: "transparent",
      animation: "zoomIn 2s ease-out",
    },
    word: {
      display: "inline-block",
      opacity: 0,
      fontFamily: "Poppins",

      animation: "fadeIn 1s forwards",
    }
  };


  return (
    <div style={{ height: "calc(100vh - 0px)", backgroundColor: "#000000", overflow: "hidden" }}>

      <div
        style={{
          // width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <section>
          <img src={image2} alt="" style={{ height: "100px", position: "absolute", left: "-50px", top: "100px" }} />
        </section>

        <section>
          <img src={image1} alt="" style={{ height: "140px", position: "absolute", left: "90px", bottom: '170px' }} />
        </section>




        <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", zIndex: "1" }}>
          <div className="grid place-items-center relative">
            <div className="h-[828px] flex flex-col justify-center gap-2" style={{ width: "450px" }}>

              <h1 className="font-poppins text-2xl leading-[3rem] font-bold text-white" style={{ fontSize: "1.5rem", marginBottom: "15px" }}>
                <section style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", paddingBottom: "80px" }}>
                  <img
                    src={logo}
                    alt=""
                    style={{
                      marginRight: "10px",  // Add some margin to create space between logo and text
                      filter: "invert(1) brightness(15)",
                      height: "45px"
                    }}
                  />

                </section>

                Login to continue...
              </h1>
              <SignInSide />
              <Box display="flex" alignItems="center" width="100%">
                <Box flexGrow={1} borderBottom="1px solid white" />
                <Typography variant="body1" mx={2} color="white" style={{ fontSize: "10px" }}>
                  Or
                </Typography>
                <Box flexGrow={1} borderBottom="1px solid white" />
              </Box>
              <OAuth />
            </div>

          </div>
        </div>
        {/* </Box> */}

        <section>
          <img src={image2} alt="" style={{ height: "130px", position: "absolute", right: "10px", bottom: "80px" }} />
        </section>

        <section>
          <img src={image1} alt="" style={{ height: "200px", position: "absolute", right: "70px", top: '130px' }} />
        </section>
      </div>

      <footer style={{ display: "flex", alignItems: "center", justifyContent: "center", color: "white" }}>
        <p className="text-sm font-poppins text-tri-text absolute bottom-5" >
          © 2024 Accunai India Services Pvt. Ltd
        </p>
      </footer>

    </div>
  );
}
