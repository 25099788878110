import React from 'react';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { CircularProgress, Divider, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store/appStore';
import { STATUS } from '../../utils/status';
import { create } from 'domain';

interface DisableModalProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  isDisabled: boolean;
}

const DisableModal: React.FC<DisableModalProps> = ({ open, onCancel, onConfirm, isDisabled }) => {
  const updateStatus = useSelector((state: RootState) => state.experts.updateStatus);
  console.log(updateStatus)
  return (
    <Modal
      open={open}
      onClose={onCancel}
      aria-labelledby="delete-modal-title" 
      aria-describedby="delete-modal-description"
    >
      <>
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '16px', borderRadius: '8px', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <Typography variant="h6" gutterBottom style={{ fontFamily: 'Montserrat', fontWeight: '600', fontSize: '16px', lineHeight: '18px', display: 'flex', alignItems: 'center', color: '#3D85C6' }}>
                {isDisabled ? 'Enable' : 'Disable'} Expert <CloseIcon onClick={onCancel} fontSize='small' style={{ color: '#3D85C6', cursor: 'pointer', marginLeft: '200px' }} />
              </Typography>
            </div>
          </div>
          <Divider style={{ marginBottom: '10px' }} />

          <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '500', fontSize: '14px', lineHeight: '18px', color: '#3D85C6', marginTop: '30px' }} id="delete-modal-description">Are you sure you want to {isDisabled ? 'enable' : 'disable'} this expert?</Typography>
          <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '500', fontSize: '14px', lineHeight: '18px', color: '#3D85C6' }} id="delete-modal-description">You can click again on icon to {isDisabled ? 'disable' : 'enable'} it.</Typography>
          <div style={{ marginTop: '30px', marginLeft: '18%' }}>

            <Button sx={{ minWidth: '81px', minHeight: '40px', backgroundColor: '#F7F9FA', padding: '8px, 16px, 8px, 16px', gap: '4px', borderRadius: '8px', marginRight: '10px' }} onClick={onCancel}>
              <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '600', fontSize: '14px', lineHeight: '24px', textAlign: 'center', color: '#10383D', textTransform: 'none' }}>Cancel</Typography>
            </Button>

            {/* <Button sx={{ minWidth: '166px', minHeight: '40px', backgroundColor: '#C00F00', padding: '8px, 16px, 8px, 16px', gap: '4px', borderRadius: '8px', '&:hover': { backgroundColor: '#C00F00' } }} 
                    onClick={onConfirm} 
                    disabled={updateStatus === STATUS.PENDING} startIcon={updateStatus === STATUS.PENDING ? <CircularProgress size={20} /> : <></> } 
              >
              <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '600', fontSize: '14px', lineHeight: '24px', textAlign: 'center', color: '#FFFFFF', textTransform: 'none' }}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <DoDisturbIcon sx={{marginRight:'2px'}}/>Confirm {isDisabled ? 'Enable' : 'Disable'}
                </div>  
              </Typography>
            </Button> */}

            <Button variant='contained' color='error' onClick={onConfirm} disabled={updateStatus === STATUS.PENDING} startIcon={updateStatus === STATUS.PENDING ? <CircularProgress size={15} /> : <DoDisturbIcon sx={{ marginRight: '2px' }} />} >
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '600', fontSize: '13px', lineHeight: '24px', textAlign: 'center', color: '#FFFFFF', textTransform: 'none' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    Confirm {isDisabled ? 'Enable' : 'Disable'}
                  </div>
                </Typography>
              </div>
            </Button>


          </div>
        </div>
      </>
    </Modal>
  );
}

export default DisableModal;
