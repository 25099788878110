import React, { useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import { useNavigate, useParams } from 'react-router-dom';
import VideoModal from './videoModal';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

interface AllocationStepHeadingProps {
  allocationType: string | null;
}

const AllocationStepHeading: React.FC<AllocationStepHeadingProps> = ({allocationType}) => {
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const { option } = useParams();
  const words = option && option.split(' ');
  const taskerType = words && words[0];
  const allocationOrUnallocationtype = words && words[1];

  const handleBackToTasks = () => {
    const confirmLeave = window.confirm('You will lose any unsaved changes. Are you sure you want to leave?');
    if (confirmLeave) {
      navigate(`/project/${id}?tab=task`);
    }
  };

  const handleOpenVideoModal = () => {
    setOpenVideoModal(true);
  };

  const handleCloseVideoModal = () => {
    setOpenVideoModal(false);
  };

  const handleGotItClick = () => {
    setOpenVideoModal(false);
  };

  const typeTitles = (type: string | null) => {
    switch (type) {
      case 'Type 1':
        return 'Task Selection';
      case 'Type 2':
        return 'Task Count';
      case 'Type 3':
        return 'Task IDs';
      default:
        return '';
    }
  };

  return (
    <div>
      <Box sx={{
        bgcolor: '#FFFFFF',
        border: '0px 0px 1px 0px',
        borderColor: '#E8EAEF',
        borderRadius: '8px',
        padding: '16px 24px 16px 24px',
        mt:'4rem',
        height:'84px',
        display:'flex',
        justifyContent:'space-between'
      }}>
        <div style={{display:'flex', flexDirection:'column', marginLeft:'13%'}}>
        <Typography paragraph sx={{ display: 'flex', alignItems: 'center', fontWeight: 600, fontSize: '12px', lineHeight: '18px', mb: '5px',cursor: 'pointer' }} onClick={handleBackToTasks}>
          <ArrowBackIosNewTwoToneIcon sx={{ fontSize: 'inherit', cursor: 'pointer' }} /> Back to Tasks
        </Typography>
        <Typography paragraph sx={{ fontWeight: 600, fontSize: '24px', lineHeight: '36px' }}>
             Bulk {taskerType} {allocationOrUnallocationtype} {allocationType? <KeyboardArrowRightIcon/> : ''}{typeTitles(allocationType) || ''}
        </Typography>
        </div>
        <IconButton
          onClick={handleOpenVideoModal}
          style={{
            height: '40px',
            borderRadius: '8px',
            padding: '8px, 6px, 8px, 16px',
            marginTop: "10px",
            backgroundColor: '#3D85C6',
            textTransform: 'none',
            width: '150px',
          }}
        >
          <Typography sx={{ fontSize: '14px', lineHeight: '24px', textTransform: 'none', fontWeight: '600', color: 'white' }}>How to Allocate</Typography>
        </IconButton>
        <VideoModal
          open={openVideoModal}
          onClose={handleCloseVideoModal}
          onGotItClick={handleGotItClick}
        />
      </Box>
    </div>
  );
};

export default AllocationStepHeading;
