import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Box } from '@mui/material';
import { AppDispatch, RootState } from '../../redux/store/appStore';
import { useNavigate, useLocation } from 'react-router-dom';
import { STATUS } from '../../utils/status';
import SnackbarNotification from '../snackbar/snackbarNotification';
import { FormField } from '../inputField/textfield';
import useStyles from '../../assets/styles/style';
import { createVendorAction, setCreateVendorStatus } from '../../redux/slices/vendor';
interface FormState {
  id?: string;
  company_name: string;
  email: string;
  pseudo_name: string;
  name: string;
  phone_number: string;
}

const VendorForm = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const [vendorDetails, setVendorDetails] = useState<FormState>({ company_name: '', email: '', pseudo_name: '', phone_number: '', name: '' });
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning'>('success');

  const createVendorStatus = useSelector((state: RootState) => state.vendors.createVendorStatus)

  useEffect(() => {
    if (vendorDetails) {
      setVendorDetails(vendorDetails);
    }
  }, [vendorDetails]);

  const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
  
    setVendorDetails((prevDetails) => {
      if (name === 'email') {
        const emailRegex = /^[a-zA-Z0-9@.-]*$/; 
        if (!emailRegex.test(value)) {
          return prevDetails; 
        }
      }  
      return {
        ...prevDetails,
        [name]: value,
      };
    });
  };
  

  const handlePhoneFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'phone_number' && !/^\d{0,10}$/.test(value)) {
      return;
    }
    setVendorDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleSnackbar = (message: string, severity: 'success' | 'error' | 'info' | 'warning') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFormSubmitted(true);

    const errors: { [key: string]: string } = {};

    if (!vendorDetails?.email?.trim()) {
      errors.email = 'Email is Required';
    } else if (!isValidEmail(vendorDetails.email)) {
      errors.email = 'Invalid email format';
    }

    if (!vendorDetails?.company_name?.trim()) {
      errors.company_name = 'Company Name is Required';
    }
    if (!vendorDetails?.name?.trim()) {
      errors.name = 'Vendor User Name is Required';
    }
    if (!vendorDetails?.pseudo_name?.trim()) {
      errors.pseudo_name = 'Vendor Pseudo Name is Required';
    }
    // if (!vendorDetails?.phone_number?.trim()) {
    //   errors.phone_number = 'Vendor Phone Number is Required';
    // }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      handleSnackbar('Make sure you fill all required fields correctly.', 'warning'); 
      return;
    }

    const payload = {
      id: vendorDetails.id,
      company_name: vendorDetails.company_name,
      email: vendorDetails.email,
      name: vendorDetails.name,
      pseudo_name: vendorDetails.pseudo_name,
      ...(vendorDetails.phone_number && {phone_number: vendorDetails.phone_number})
    };
   
    dispatch(createVendorAction(payload)); 
  }

  useEffect(() => {
    const handleVendorCreateStatus = () => {
      if (createVendorStatus === STATUS.SUCESS) {
        handleSnackbar('Vendor Added Successfully', 'success');
        setTimeout(() => {
          navigate('/vendors');
        }, 1000);
      }
      if (createVendorStatus === STATUS.FAILED) {
        handleSnackbar('Vendor user with this email or phone already exists.', 'error')
      }
    }
    handleVendorCreateStatus();

    return () => {
      if(createVendorStatus === STATUS.SUCESS) {
        dispatch(setCreateVendorStatus(STATUS.NOT_STARTED))
      }
    };
  }, [createVendorStatus])

  return (
    <Box component={'form'} onSubmit={handleSubmit} className={classes.formContainer}>
        <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <FormField
            label="Vendor Company Name"
            placeholder="Enter Vendor Compnay Name"
            name="company_name"
            value={vendorDetails.company_name}
            onChange={handleFieldChange}
            error={formSubmitted && formErrors.company_name ? formErrors.company_name : ''}

          />
          <FormField
            label="Vendor User Name"
            placeholder="Enter Vendor User Name"
            name="name"
            value={vendorDetails.name}
            onChange={handleFieldChange}
            error={formSubmitted && formErrors.name ? formErrors.name : ''}

          />
          <FormField
            label="Vendor Psuedo Name"
            placeholder="Enter Vendor Pseudo Name"
            name="pseudo_name"
            value={vendorDetails.pseudo_name}
            onChange={handleFieldChange}
            error={formSubmitted && formErrors.pseudo_name ? formErrors.pseudo_name : ''}

          />
          </div>
          <div style={{ display: 'flex', marginTop:'20px' }}>
          <FormField
            label="Vendor User Email ID"
            placeholder="Enter Vendor User Email ID"
            name="email"
            value={vendorDetails.email}
            onChange={handleFieldChange}
            error={formSubmitted && formErrors.email ? formErrors.email : ''}
          />
          <FormField
            label="Phone Number"
            placeholder="Enter Phone Number"
            name="phone_number"
            value={vendorDetails.phone_number}
            onChange={handlePhoneFieldChange}
            // error={formSubmitted && formErrors.phone_number ? formErrors.phone_number : ''}
            typographySx={{ marginLeft:'6.5%' }} 
            textFieldSx={{marginLeft:'6.5%', width:'100%'}}
          />
          </div>
        </div>
          <Button type="submit" variant="contained" sx={{fontSize: '14px',textTransform: 'none',color: '#FFFFFF',width: '100%',backgroundColor: '#3D85C6',borderRadius: '8px',marginTop:'20px',}}>Add Vendor</Button>
      <SnackbarNotification
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
    </Box>
  );
};

export default VendorForm;
