import debounce from 'lodash.debounce';
import React, { useEffect, useMemo, useState } from 'react'
import { useStyles } from '../../../assets/styles/listTableStyle';
import { CircularProgress, Drawer, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import VendorDrawerContent from './VendorDrawerContent';
import TableHeader from '../../table/tableHeader';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/store/appStore';
import { STATUS } from '../../../utils/status';
import Error from '../../error/error';
import { deleteVendorAction, fetchVendorsAction, getVendorAction } from '../../../redux/slices/vendor';
import { SearchBar } from '../../inputField/textfield';
import { AddButton } from '../../button/button';
import DisableModal from '../../projectList/disableModal';
import DeleteModal from '../../modal/deleteModal';
import SnackbarNotification from '../../snackbar/snackbarNotification';
import PaginationEntries from '../../pagination/paginationEntries';
import VendorPagination from './userVendorPagination';
import TableRowItem from './userVendorsTableRowItem';


const UserVendorsList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showDisableModal, setShowDisableModal] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error" | "info" | "warning">("success");
  const [vendorToDisable, setVendorToDisable] = useState<any>(null);
  const [vendorId, setVendorId] = useState("")
  const [isEdit, setIsEdit] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [isClicked, setIsClicked] = useState(false);

  const vendorsList = useSelector((state: RootState) => state.vendors.ListOnBoardVendors)
  const vendorStatus = useSelector((state: RootState) => state.vendors.fetchVendorStatus)

  const tableFields = ["VENDOR NAME", "VENDOR PSEUDO NAME", "ACTIONS"]
  const totalPages = Math.ceil((vendorsList.results.count || 0) / itemsPerPage);

  const capitalizeAll = (str: string) => {
    return str.replace(/_/g, ' ')
      .split(' ')
      .map(word => word.toUpperCase())
      .join(' ');
  };
  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleChangeRowsPerPage = (value: number) => {
    setItemsPerPage(value as number);
    setCurrentPage(1);
    dispatch(getVendorAction({ search_query: searchTerm, page: 1, pageSize: value as number }));
  };
  const handleOnChange = (value: number) => {
    setItemsPerPage(value)
  }

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const handleSnackbar = (
    message: string,
    severity: "success" | "error" | "info" | "warning"
  ) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const debouncedResults = useMemo(() => {
    return debounce(handleSearchChange, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  useEffect(() => {
    dispatch(
      fetchVendorsAction({
        paginate: true,
        search_query: searchTerm,
        project_id: id,
        page: currentPage,
        pageSize: itemsPerPage
      })
    )
  }, [dispatch, searchTerm, currentPage, itemsPerPage])

  const handleDisableCancel = () => {
    setShowDisableModal(false);
    setVendorToDisable(null)
  };

  const handleDeleteClick = (userId: string) => {
    setVendorId(userId);
    setShowDeleteModal(true);
  };

  const handleDisableConfirm = async () => {
    setShowDisableModal(false);
    if (vendorToDisable) {
      const updatedStatus = !vendorToDisable.is_blocked;
      try {
        await dispatch(deleteVendorAction({ is_blocked: updatedStatus, id: vendorId }));
        dispatch(
          getVendorAction({})
        );
        handleSnackbar('Success', 'success');
        dispatch(fetchVendorsAction({project_id: id}))
      } catch (error) {
        console.error('Failed to update vendor status', error);
      }
    }
    setVendorToDisable(null);
  };

  const cancelDelete = () => {
    setShowDeleteModal(false);
  };

  const confirmDelete = async () => {
    setShowDeleteModal(false);
    try {
      await dispatch(deleteVendorAction({ is_deleted: true, id: vendorId }));
      dispatch(
        getVendorAction({})
      );
      handleSnackbar('Vendor Deleted Successfully.', 'success');
      dispatch(fetchVendorsAction({project_id: id}))
    } catch (error) {
      handleSnackbar('Error occured please try again.', 'error');
    }
  };
  const handleToggleDisable = (row: any) => {
    setVendorId(row.id)
    setVendorToDisable(row);
    setShowDisableModal(true);
  };

  const handleClick = (row: any) => {
    setIsClicked(true);
    setIsDrawerOpen(true);
    setSelectedRow(row);
  }

  const handleAddClick = () =>{
    setIsClicked(false);
    setSelectedRow(null);
    setIsDrawerOpen(true);
  }

  return (
    <>
      <Drawer
        anchor={"right"}
        open={isDrawerOpen}
        onClose={() => {
          setIsDrawerOpen(false)
          setIsEdit(false)
        }}
        PaperProps={{ sx: { width: "70%", padding: "40px" } }}
      >
        <VendorDrawerContent setIsDrawerOpen={setIsDrawerOpen} isDrawerOpen={isDrawerOpen} selectedRow={selectedRow} isRowClicked={isClicked}/>
      </Drawer>


      <div style={{ display: "flex", justifyContent: "space-between", padding: "15px 0px 15px 0px" }}>
        <SearchBar onSearchChange={debouncedResults} />
        <div className={classes.actionsContainer}>
          <PaginationEntries itemsPerPage={itemsPerPage} onChangeRowsPerPage={handleChangeRowsPerPage} handleOnChange={handleOnChange} />
          <AddButton color="primary" onClick={handleAddClick} style={{}}>
            <LibraryAddIcon sx={{ mr: 1 }} />
            Assign Vendors
          </AddButton>
        </div>
      </div >

      <TableContainer>
        <Table className={classes.tableContainer} aria-label='simple table'>
          <TableHeader headers={tableFields.map(capitalizeAll)} />
          <TableBody>
            {
              vendorStatus === STATUS.PENDING ? (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div style={{ position: 'relative', height: '10vh' }}>
                      <CircularProgress
                        size={35}
                        style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                vendorsList && vendorsList?.results.results?.length > 0 ? (
                  vendorsList.results.results.map((row: any) => (
                    <TableRowItem
                    key={row.id}
                    row={row}
                    onDeleteClick={handleDeleteClick}
                    onToggleDisable={handleToggleDisable}
                    handleClick={handleClick}
                  />
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} className={classes.noUsersMessage}>
                      <Error message={'There are no vendors added as of yet. You can add a vendor to see it here.'} />
                    </TableCell>
                  </TableRow>
                )
              )
            }
          </TableBody>
        </Table>
      </TableContainer>

      <VendorPagination
        currentPage={currentPage}
        totalPages={totalPages}
        paginate={paginate}
        itemsPerPage={itemsPerPage}
      />
      <DisableModal
        open={showDisableModal}
        onCancel={handleDisableCancel}
        onConfirm={handleDisableConfirm}
        isDisabled={vendorToDisable ? vendorToDisable.is_blocked : false}
      />
      <DeleteModal
        open={showDeleteModal}
        onCancel={cancelDelete}
        onConfirm={confirmDelete}
      />
      <SnackbarNotification
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
    </>
  )
}

export default UserVendorsList