import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';
import { AnalyticsPayload, VendorAnalyticsCompletedState } from '../../interfaces/interface';
import extraReducers from '../../extraReducers/vendorAnalytics';

const initialState: VendorAnalyticsCompletedState = {
  VendorAnalyticsCompleted: { 
    count: undefined,
    results: [] 
  },
  error: null,
  getVendorAnalyticsStatus: STATUS.NOT_STARTED,
};

export const getCompletedVendorAnalytics = createAsyncThunk(
    '/company/analytics/vendor/get',
    async (payload: AnalyticsPayload = {}, thunkAPI) => {
      const { search, page, pageSize, sort_by, sort_order, project_id } = payload;
      const queryString = new URLSearchParams({
        ...(search && { search }),
        ...(page && { page: page.toString() }),
        ...(pageSize && { page_size: pageSize.toString() }),
        ...(sort_by && { sort_by }),
        ...(sort_order && { sort_order }),
        ...(project_id && { project_id }),
      }).toString();
      const response = await api.get(`/company/analytics/vendor?${queryString}`);
      const { status, data } = response;
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue({ response, data });
      }
    }
  );

const vendoranalyticsCompletedSlice = createSlice({
  name: 'vendorAnalyticsCompleted',
  initialState,
  reducers: {
  },
  extraReducers
});

export default vendoranalyticsCompletedSlice.reducer;
