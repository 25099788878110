import { Box, Tooltip, Typography } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store/appStore';

const VendorListHeading = () => {
  return (
    <div>
      <Box sx={{
        bgcolor: '#FFFFFF',
        border: '0px 0px 1px 0px',
        borderColor: '#E8EAEF',
        borderRadius: '8px',
        padding: '16px 24px 16px 24px',
        color: '#13282A',
        fontWeight: 600,
        fontSize: '24px',
        lineHeight: '36px',
        mt:'4rem',
        height:'84px',
      }}>
        
        <Typography paragraph sx={{ fontWeight: 600, fontSize: '24px', lineHeight: '36px' }}>
          Vendors
          {/* <Tooltip title={}>
            <span style={{cursor:'default'}}>{truncateName(projectName)}</span>
          </Tooltip> */}
        </Typography>
      </Box>
    </div>
  )
}

export default VendorListHeading