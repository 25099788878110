import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { STATUS } from '../../utils/status';
import { VendorAnalyticsCompletedState } from '../interfaces/interface';
import { getCompletedVendorAnalytics } from '../slices/analytics/vendor';

const ExtraReducers = (builder: ActionReducerMapBuilder<VendorAnalyticsCompletedState>) => {
  builder
  .addCase(getCompletedVendorAnalytics.pending, (state) => {
    state.getVendorAnalyticsStatus = STATUS.PENDING;
  })
  .addCase(getCompletedVendorAnalytics.fulfilled, (state, action) => {
    state.getVendorAnalyticsStatus = STATUS.SUCESS;
    state.VendorAnalyticsCompleted = action.payload;
  })
  .addCase(getCompletedVendorAnalytics.rejected, (state, action) => {
    state.getVendorAnalyticsStatus = STATUS.FAILED;
  });
};

export default ExtraReducers;
