import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { STATUS } from '../../utils/status';
import { MetaQCState } from '../interfaces/interface';
// import { createMetaQC } from '../slices/taskCompleted/metaQC';

const ExtraReducers = (builder: ActionReducerMapBuilder<MetaQCState>) => {
  // builder
  // .addCase(createMetaQC.pending, (state) => {
  //   state.createMetaQCStatus = STATUS.PENDING;
  // })
  // .addCase(createMetaQC.fulfilled, (state, action) => {
  //   state.createMetaQCStatus = STATUS.SUCESS;
  //   state.metaqc = action.payload;
  // })
  // .addCase(createMetaQC.rejected, (state, action) => {
  //   state.createMetaQCStatus = STATUS.FAILED;
  // });
};

export default ExtraReducers;
