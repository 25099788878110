import { Checkbox, CircularProgress, List, ListItem, ListItemText, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { AddButton } from '../../button/button'
import { STATUS } from '../../../utils/status'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store/appStore'

const AdminPermissions = ({checkedItems, handleCheckboxChange, handleAddAdmin, AdminStatus, adminId, isAddVendorBtnEnable, selectedRow}:any) => {

    const Permissions = useSelector((state: RootState) => state.projects.permissions)
    const reviewAllocation = Permissions?.review_allocation?.[0]
    
    return (
        <>
            <div style={{ marginTop: "20px", display: 'flex' }}>
                <Typography sx={{ marginTop: '10px', marginRight: '10px', fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}>Allow Permissions : </Typography>
                <Checkbox
                    name="allowAll"
                    checked={
                        checkedItems.export_data[0] &&
                        checkedItems.task_allocation[0] &&
                        checkedItems.review_allocation[0] &&
                        checkedItems.project_details[0] &&
                        checkedItems.project_config[0] &&
                        checkedItems.project_payments[0] &&
                        checkedItems.project_analytics[0] &&
                        checkedItems.project_users[0] &&
                        checkedItems.completing_task_action_details[0]
                    }
                    disabled = {
                        !Permissions.export_data[0] ||
                        !Permissions.task_allocation[0] ||
                        !Permissions.review_allocation[0] ||
                        !Permissions.project_details[0] ||
                        !Permissions.project_config[0] ||
                        !Permissions.project_payments[0] ||
                        !Permissions.project_analytics[0] ||
                        !Permissions.project_users[0] ||
                        !Permissions.completing_task_action_details[0] ||
                        !(Permissions.completing_task_action_details[1] == 'read') ||
                        !(Permissions.completing_task_action_details[2] == 'edit')
                        
                    }
                    onChange={handleCheckboxChange}
                />

                <span style={{ fontSize: "13px", marginTop: '1%' }} >All permissions</span>
            </div>

            <div style={{ display: 'flex', marginTop: '5px' }}>
                <Typography sx={{ marginTop: '17px', marginRight: '10px', fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}>Export Permission : </Typography>
                <List>
                    <Checkbox
                        name="allowExportData"
                        checked={checkedItems.export_data[0]}
                        onChange={handleCheckboxChange}
                        disabled={Permissions.export_data[0] == false}
                        sx={{ marginLeft: '3px' }}
                    />
                    <span style={{ fontSize: "13px" }} >Export data</span>

                </List>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ marginTop: '17px', marginRight: '10px', fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}>Project Permission : </Typography>
                <List style={{ display: 'flex' }}>
                    <Checkbox
                        name="allowProjetAnalytics"
                        checked={checkedItems.project_analytics[0]}
                        onChange={handleCheckboxChange}
                        disabled={Permissions.project_analytics[0] == false}
                        sx={{ marginLeft: '0.5px' }}
                    />
                    <span style={{ fontSize: "13px", marginTop: '10px' }} >Project analytics</span>
                    <Checkbox
                        name="projectConfigs"
                        checked={checkedItems.project_config[0]}
                        disabled={Permissions.project_config[0] == false}
                        onChange={handleCheckboxChange}
                    />

                    <ListItemText style={{}} primary={<h1 style={{ fontSize: "13px", marginTop: '6px' }} >Project configs</h1>} />

                    <Checkbox
                        name="projectDetails"
                        checked={checkedItems.project_details[0]}
                        disabled={Permissions.project_details[0] == false}
                        onChange={handleCheckboxChange}
                    />

                    <ListItemText style={{}} primary={<h1 style={{ fontSize: "13px", marginTop: '6px' }} >Project details</h1>} />

                    <Checkbox
                        name="paymentSettings"
                        checked={checkedItems.project_payments[0]}
                        disabled={Permissions.project_payments[0] == false}
                        onChange={handleCheckboxChange}
                    />

                    <ListItemText style={{}} primary={<h1 style={{ fontSize: "13px", marginTop: '6px' }} >Payment settings</h1>} />

                    <Checkbox
                        name="projectUsers"
                        checked={checkedItems.project_users[0]}
                        disabled={Permissions.project_users[0] == false}
                        onChange={handleCheckboxChange}
                    />

                    <ListItemText style={{}} primary={<h1 style={{ fontSize: "13px", marginTop: '6px' }} >User Management</h1>} />
                </List>
            </div>

            <div style={{ position: "relative", marginTop: '10px' }}>
                <Typography sx={{ marginTop: '5px', marginRight: '10px', fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}>Task permissions : </Typography>
                {/* <List>

                    <Checkbox
                        name="allowTasking"
                        checked={
                            checkedItems.task_allocation[0] &&
                            checkedItems.review_allocation[0]
                        }
                        onChange={handleCheckboxChange}
                    />
                    <span style={{ fontSize: "13px", paddingTop: "35px", position: "absolute", top: "-16px" }} >Allow Tasking</span>

                </List> */}

                <div style={{ marginTop: "-20px", display: 'flex' }} >
                    <List>
                        <ListItem>
                            <Checkbox
                                name="tasking"
                                checked={checkedItems.task_allocation[0]}
                                onChange={handleCheckboxChange}
                                disabled={Permissions.task_allocation[0] == false}
                                sx={{ marginLeft: '10%' }}
                            />
                            <ListItemText style={{}} primary={<h1 style={{ fontSize: "13px" }} >Tasking</h1>} />

                            <Checkbox
                                name="reviewing"
                                checked={checkedItems.review_allocation[0]}
                                disabled={Permissions.review_allocation[0] == false}
                                onChange={handleCheckboxChange}
                                sx={{ marginLeft: '20%' }}
                            />
                            <ListItemText style={{}} primary={<h1 style={{ fontSize: "13px" }} >Reviewing</h1>} />
                        </ListItem>

                    </List>
                </div>

                <div style={{ position: "relative", marginTop: '10px' }}>
                    <Typography sx={{ marginTop: '5px', marginRight: '10px', fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}>Completing Task Permissions : </Typography>

                    <div style={{ marginTop: "-10px", display: 'flex' }} >
                        <List>
                            <ListItem>
                                <Checkbox
                                    name="edit"
                                    checked={checkedItems.completing_task_action_details.includes("edit")}
                                    disabled={!Permissions.completing_task_action_details.includes("edit")}
                                    onChange={handleCheckboxChange}
                                    sx={{ marginLeft: '15%' }}
                                />
                                <ListItemText style={{}} primary={<h1 style={{ fontSize: "13px" }} >Edit</h1>} />

                                <Checkbox
                                    name="read"
                                    checked={checkedItems.completing_task_action_details.includes("read")}
                                    disabled={!Permissions.completing_task_action_details.includes("read")}
                                    onChange={handleCheckboxChange}
                                    sx={{ marginLeft: '49%' }}
                                />
                                <ListItemText style={{}} primary={<h1 style={{ fontSize: "13px" }} >Read</h1>} />
                            </ListItem>
                        </List>

                    </div>
                </div>
            </div>

            
        </>
    )
}

export default AdminPermissions