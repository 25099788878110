import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
    title: {
      fontFamily: 'Montserrat',
      fontSize: 20,
      fontWeight: 600,
      lineHeight: '24px',
      color: '#10383D',
      marginTop: 5,
      marginBottom: 20,
      textTransform: 'capitalize',
    },
    checkboxContainer: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 15,
    },
    checkboxLabel: {
      fontFamily: 'Montserrat',
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '24px',
      color: '#10383D',
    },
    optionContainer: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 10,
    },
    optionLabel: {
      fontFamily: 'Montserrat',
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '24px',
      color: '#10383D',
      marginRight: 50,
    },
    addButton: {
      marginTop: 10,
    },
    addMoreOptionsContainer:{
      display: 'flex', 
      flexDirection: 'column'
    },
    addMoreOptions:{
      fontFamily: 'Montserrat', 
      fontSize: '14px', 
      fontWeight: 600, 
      lineHeight: '24px', 
      letterSpacing: '0px', 
      textAlign: 'left', 
      color: '#10383D', 
      marginTop: '35px', 
      marginRight: '22px'
    },
    fieldContainer: {
      position: 'relative',
      marginBottom: 10,
      marginRight: 10,
    },
    dragIcon: {
      zIndex: 1,
      color: 'grey',
      pointerEvents: 'none',
    },
    textField: {
      marginRight: 10,
    },
    removeButton: {
      position: 'relative',
      top: -8,
      right: 23,
      width: 24,
      height: 24,
      borderRadius: '50%',
      backgroundColor: 'black',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 0,
      cursor: 'pointer',
    },
    removeIcon: {
      zIndex: 1,
      color: 'white',
    },
    fieldContent: {
      display: 'flex',
      alignItems: 'center',
    },
  });