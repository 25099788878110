import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';
import { UploadPayload, UploadState } from '../../interfaces/interface';
import extraReducers from '../../extraReducers/uploadExtraReducer';

const initialState: UploadState = {
  upload: { 
    count: undefined,
    results: [] 
  },
  error: null,
  unallocatedCount: undefined,
  createStatus: STATUS.NOT_STARTED,
  getStatus: STATUS.NOT_STARTED,
  updateStatus: STATUS.NOT_STARTED,
  createAllocationStatus: STATUS.NOT_STARTED,
  getTaskCountStatus: STATUS.NOT_STARTED,
  createBulkAllocationStatus: STATUS.NOT_STARTED,
  createBulkUnallocationStatus: STATUS.NOT_STARTED,
  metaqc: {},
  createMetaQCStatus: STATUS.NOT_STARTED
};

export const createUpload = createAsyncThunk(
    'upload/POST',
    async (payload: { id: string, selectedFile: File }, thunkAPI) => {
      const { id, selectedFile } = payload;
      try {
        const formData = new FormData();
        formData.append('file', selectedFile);
        const response = await api.post(`task/company/upload/?project_id=${id}`, formData);
        return response.data;
      } catch (error:any) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  );

  export const createAllocation = createAsyncThunk(
    'task/company/allocate-tasks/POST',
    async (payload: any, thunkAPI) => {
      const { payload:payload_updated, extraData }=payload
      console.log({payload_updated, extraData, payload})
      const response = await api.post('task/company/allocate-tasks/', payload_updated);
      const { status, data } = response;
      if (status === 200) {
        return {data, payload};
      } else {
        return thunkAPI.rejectWithValue({ response, data });
      }
    }
  );

  export const createBulkAllocation = createAsyncThunk(
    '/task/allocate/bulk/company/POST',
    async (payload: any, thunkAPI) => {
      const { payload:payload_updated, extraData }=payload
      console.log({payload_updated, extraData, payload})
      const response = await api.post('/task/allocate/bulk/company/', payload_updated);
      const { status, data } = response;
      if (status === 201) {
        return {data, payload};
      } else {
        return thunkAPI.rejectWithValue({ response, data });
      }
    }
  );

  export const createBulkUnallocation = createAsyncThunk(
    '/task/unallocate/bulk/company/PATCH',
    async (payload: any, thunkAPI) => {
      const { payload:payload_updated, extraData }=payload
      console.log({payload_updated, extraData, payload})
      const response = await api.patch('/task/unallocate/bulk/company/', payload_updated);
      const { status, data } = response;
      if (status === 200) {
        return {data, payload};
      } else {
        return thunkAPI.rejectWithValue({ response, data });
      }
    }
  );

  export const getUpload = createAsyncThunk(
    'task/company/get',
    async (payload: UploadPayload = {project_id: ''}, thunkAPI) => {
      const { search, page, pageSize, sort, order, project_id, type, task_name, task_allocation, review_Allocation, pseudoname, allocated_task_type, statuses, vendor_id, autoCompleteSearch} = payload;
      const queryString = new URLSearchParams({
        ...(search && { search }),
        ...(page && { page: page.toString() }),
        ...(pageSize && { page_size: pageSize.toString() }),
        ...(sort && { sort }),
        ...(order && { order }),
        ...(project_id && { project_id }),
        ...(type && { type }),
        ...(task_name && {task_name}),
        ...(task_allocation && {task_allocation}),
        ...(review_Allocation && {review_Allocation}),
        ...(pseudoname && {pseudoname}),
        ...(allocated_task_type && {allocated_task_type}),
        ...(statuses && statuses.length > 0 && { status: statuses.map((s:any) => s.toUpperCase()).join(',') }),
        ...(vendor_id && {vendor_id}),
        ...(autoCompleteSearch && {search: autoCompleteSearch}),

        
      }).toString();
      const response = await api.get(`task/company/?${queryString}`);
      const { status, data } = response;
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue({ response, data });
      }
    }
  );

  export const getUnallocatedTaskCount = createAsyncThunk(
    'task/company/unallocated-task-count/get',
    async (payload: UploadPayload = {project_id: ''}, thunkAPI) => {
      const { project_id } = payload;
      const queryString = new URLSearchParams({
        ...(project_id && { project_id }),
      }).toString();
      const response = await api.get(`task/company/unallocated-task-count/?${queryString}`);
      const { status, data } = response;
      if (status === 200) {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue({ response, data });
      }
    }
  );

  export const updateUpload = createAsyncThunk(
    'task/company/patch',
    async (payload: any, thunkAPI) => {
      const response = await api.patch(`task/company/`, payload);
      const { status, data } = response;
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue({ response, data });
      }
    }
  );

  export const createMetaQC = createAsyncThunk(
    'task/update_meta_qc_status/company/post',
    async (payload: any, thunkAPI) => {
      const response = await api.post(`task/update_meta_qc_status/company/`, payload);
      const { status, data } = response;
      if (status === 200) {
        return {payload, data};
      } else {
        return thunkAPI.rejectWithValue({ response, data });
      }
    }
  );

const uploadSlice = createSlice({
  name: 'upload',
  initialState,
  reducers: {
    setCreateUploadStatus(state,action){
      state.createStatus = action.payload
    },
    setCreateAllocationStatus(state,action){
      state.createAllocationStatus = action.payload
    },
    setCreateBulkAllocationStatus(state,action){
      state.createBulkAllocationStatus = action.payload
    },
    setCreateBulkUnallocationStatus(state,action){
      state.createBulkUnallocationStatus = action.payload
    },
    setCreateSingleAllocationStatus(state,action){
      state.createAllocationStatus = action.payload
    },
    setCreateMetaQC (state, action) {
      state.createMetaQCStatus = action.payload;
    }
  },
  extraReducers
});

export const {setCreateUploadStatus, setCreateMetaQC, setCreateAllocationStatus,setCreateSingleAllocationStatus, setCreateBulkAllocationStatus, setCreateBulkUnallocationStatus} = uploadSlice.actions
export default uploadSlice.reducer;
