import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface RowError {
    email?: boolean;
    count?: boolean;
}

interface RowErrorsState {
    rowErrors: RowError[];
}

const initialState: RowErrorsState = {
    rowErrors: []
};

const rowErrorsSlice = createSlice({
    name: 'rowErrors',
    initialState,
    reducers: {
        setRowErrors: (state, action: PayloadAction<RowError[]>) => {
            state.rowErrors = action.payload;
        },
        updateRowError: (state, action: PayloadAction<{ index: number; error: RowError }>) => {
            const { index, error } = action.payload;
            state.rowErrors[index] = error;
        },
        clearRowErrors: (state) => {
            state.rowErrors = [];
        },
    },
});

export const { setRowErrors, updateRowError, clearRowErrors } = rowErrorsSlice.actions;

export default rowErrorsSlice.reducer;
