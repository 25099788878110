import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress, IconButton} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store/appStore';
import { STATUS } from '../../utils/status';
import { TableCellHead } from '../table/tableCell';
import { useStyles } from '../../assets/styles/listTableStyle';
import Error from '../error/error';
import TableRowItem from './vendorAnalyticsTableRow';
import { useParams } from 'react-router-dom';
import { getCompletedAnalytics } from '../../redux/slices/analytics/completed';
import Pagination from './vendorAnalyticsPagination';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { getCompletedVendorAnalytics } from '../../redux/slices/analytics/vendor';

const VendorAnalyticsListTable = () => {
  const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const analytics = useSelector((state:RootState)=> state.analyticsVendor.VendorAnalyticsCompleted)
  const getStatus = useSelector((state: RootState) => state.analyticsVendor.getVendorAnalyticsStatus);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [sortColumn, setSortColumn] = useState<string>('date');
  const [sortOrder, setSortOrder] = useState<string>('asc');
  const {id} = useParams();

  useEffect(()=>{
    dispatch(getCompletedVendorAnalytics({project_id: id,page: currentPage, pageSize: itemsPerPage, sort_by: sortColumn, sort_order: sortOrder}))
   },[dispatch, id, currentPage, itemsPerPage, sortColumn, sortOrder])

   const totalPages = Math.ceil((analytics.count || 0) / itemsPerPage);

   const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const headers = ["VENDOR ID", "PSEUDO NAME", "TOTAL TASKER ONBOARDED ON PROJECT", "TOTAL TASK", "TOTAL TASK ALLOCATED", "NUMBER OF TASK COMPLETED", "PERCENT COMPLETED TASK", "NUMBER OF TASK NOT STARTED", "NUMBER OF ACTIVE TASK", "NUMBER OF QUALITY ACCEPTED TASK", "NUMBER OF QUALITY REJECTED TASK", "NUMBER OF DELIVERED TASK", "NUMBER OF TASK WITH NO REVIEW", "TIME TAKEN TO COMPLETE", "AVG TASK COMPLETING PER DAY"];

  const handleSort = (column: string) => {
    const formattedHeader = column.toLowerCase().replace(/\s+/g, '_');
    const newOrder = sortColumn === formattedHeader && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortColumn(formattedHeader);
    setSortOrder(newOrder);
  };

  return (
      <>
      <TableContainer>
        {getStatus === STATUS.PENDING && (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <CircularProgress />
          </div>
        )}
        {getStatus === STATUS.SUCESS && (
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ backgroundColor: '#F7F9FA', '&:first-child td, &:first-child th': { borderBottom: 0 } }}>
            <TableRow>
                {headers.map((header) => (
                   <TableCell align="left" onClick={() => handleSort(header)} sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '14px'}} key={header}>
                     {header}  <SwapVertIcon fontSize='small' sx={{marginLeft:'3px', cursor:'pointer'}}/>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              
            {getStatus === STATUS.SUCESS && analytics.results && analytics.results.length > 0 ? (
            <>
              {analytics.results.map((row: any) => (
                <TableRowItem
                  key={row.id}
                  row={row}
                />
              ))}
            </>
          ) : (
            <TableRow>
              <TableCell colSpan={8} className={classes.noUsersMessage}>
                <Error message={'There is no data added as of yet.'} />
              </TableCell>
            </TableRow>
          )}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          paginate={paginate}
          itemsPerPage={itemsPerPage} 
        />
    </>
  );
};

export default VendorAnalyticsListTable;
