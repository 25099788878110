import React from 'react';
import { TableRow } from '@mui/material';
import { TableCellBody } from '../table/tableCell';

interface TableRowItemProps {
  row: any;
  allocationType: string;
}

const TableRowItem: React.FC<TableRowItemProps> = ({ row, allocationType }) => {
  return (
    <>
      <TableRow
        key={row.email}
        sx={{
          '&:last-child td, &:last-child th': { borderBottom: 0 },
        }}
      >
      <TableCellBody>{row.email}</TableCellBody>
      <TableCellBody>{row.active_tasks}</TableCellBody>
      <TableCellBody>{row.completed_tasks}</TableCellBody>
      <TableCellBody>{row.pushbacked_tasks}</TableCellBody>
      <TableCellBody>{row.not_started_tasks}</TableCellBody>
      <TableCellBody>{row.in_review_tasks}</TableCellBody>
      <TableCellBody>{row.reviewed_tasks}</TableCellBody>
      <TableCellBody>{row.total_allocated_tasks}</TableCellBody>
      <TableCellBody>{allocationType === 'allocation' ? row.allocation_count: row.unallocation_count}</TableCellBody>
      <TableCellBody>{allocationType === 'allocation' ? row.total_allocated_tasks_after_this_allocation : row.total_allocated_tasks_after_this_unallocation}</TableCellBody>
    </TableRow >
    </>
  );
};

export default TableRowItem;
