import React, { useEffect } from 'react';
import { FormField } from '../inputField/textfield';
import { MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { AddButton } from '../button/button';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store/appStore';
import { getVendorList } from '../../redux/slices/vendorList';
import { useParams } from 'react-router-dom';
import { getProject } from '../../redux/slices/projectSlice/addProjectSlice';

interface VendorAllocationBodyProps {
  formDataArray: Array<{ vendor: string; task_count: string; type: string }>;
  setFormDataArray: React.Dispatch<React.SetStateAction<Array<{ vendor: string; task_count: string; type: string}>>>;
}

const VendorAllocationBody: React.FC<VendorAllocationBodyProps> = ({ formDataArray, setFormDataArray }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();
  const vendorList = useSelector((state: RootState) => state.vendorList.vendor?.results || []);
  const fetchPermissions = useSelector((state: RootState) => state.projects.permissions);
  useEffect(() => {
    dispatch(getVendorList({ paginate: true, project_id: id || '' }));
  }, [id, dispatch]);

  useEffect(() => {
    dispatch(getProject({ project_id: id || '' }));
  }, [id, dispatch]);

  const handleInputChange = (e: { target: { name: string; value: string } }, index: number) => {
    const { name, value } = e.target;
    const newFormDataArray = [...formDataArray];
    newFormDataArray[index] = {
      ...newFormDataArray[index],
      [name]: name === 'task_count' ? Number(value) : value, 
    };
    setFormDataArray(newFormDataArray);
  };

  const handleSelectFieldChange = (event: SelectChangeEvent<string>, index: number, fieldName: string) => {
    const value = event.target.value;
    const newFormDataArray = [...formDataArray];
    newFormDataArray[index] = {
      ...newFormDataArray[index],
      [fieldName]: value,
    };
    setFormDataArray(newFormDataArray);
  };

  const handleAddMore = () => {
    setFormDataArray([...formDataArray, { vendor: '', task_count: '', type: '' }]);
  };

  const handleDelete = (index: number) => {
    const newFormDataArray = formDataArray.filter((_, i) => i !== index);
    setFormDataArray(newFormDataArray);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
  };
  console.log('Form Data:', formDataArray);

  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
      <form onSubmit={handleSubmit}>
        {formDataArray.map((formData, index) => (
          <div key={index} style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', flexGrow: 1 }}>
              <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '32%' }}>
                <Typography
                  sx={{
                    fontFamily: 'Montserrat',
                    fontSize: '14px',
                    fontWeight: 600,
                    lineHeight: '24px',
                    textAlign: 'left',
                    color: '#10383D',
                  }}
                >
                  Select Vendor
                </Typography>
                <Select
                  name="vendor"
                  value={formData.vendor}
                  onChange={(e) => handleSelectFieldChange(e, index, 'vendor')}
                  sx={{ width: '220px' }}
                  size="small"
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    Select Vendor
                  </MenuItem>
                  {vendorList.map((vendor:any) => (
                    <MenuItem key={vendor.vendor_id} value={vendor.vendor_id}>
                      {vendor.vendor_pseudo_name ? vendor.vendor_pseudo_name : vendor.vendor_name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px', width: '32%' }}>
                <Typography
                  sx={{
                    fontFamily: 'Montserrat',
                    fontSize: '14px',
                    fontWeight: 600,
                    lineHeight: '24px',
                    textAlign: 'left',
                    color: '#10383D',
                  }}
                >
                  Select Type
                </Typography>
                <Select
                  name="type"
                  value={formData.type}
                  onChange={(e) => handleSelectFieldChange(e, index, 'type')}
                  sx={{ width: '220px' }}
                  size="small"
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    Select Type
                  </MenuItem>
                  {/* <MenuItem value="TASKING" disabled={fetchPermissions && (fetchPermissions.task_allocation_single?.[0] === 'false' || fetchPermissions.task_allocation_bulk?.[0] === 'false') }>Tasking</MenuItem>
                  <MenuItem value="REVIEWING" disabled={fetchPermissions && (fetchPermissions.review_allocation_single?.[0] === 'false' || fetchPermissions.review_allocation_bulk?.[0] === 'false') }>Reviewing</MenuItem> */}

                  <MenuItem value="BOTH" disabled={fetchPermissions && (fetchPermissions.task_allocation_single?.[0] === 'false' || fetchPermissions.task_allocation_bulk?.[0] === 'false' || fetchPermissions.review_allocation_single?.[0] === 'false' || fetchPermissions.review_allocation_bulk?.[0] === 'false')}>Both (Tasking & Reviewing) </MenuItem>
                </Select>
              </div>
              <FormField
                label="Task Count"
                placeholder="Enter Task Count"
                name="task_count"
                value={formData.task_count}
                onChange={(e) => handleInputChange(e, index)}
                textFieldSx={{ width: '220px' }}
              />
              <DeleteIcon
                sx={{ marginLeft: '10px', color: '#ec480f', marginTop: '28px', cursor: 'pointer' }}
                onClick={() => handleDelete(index)}
              />
            </div>
          </div>
        ))}
        <AddButton onClick={handleAddMore}>Add More</AddButton>
      </form>
    </div>
  );
};

export default VendorAllocationBody;
