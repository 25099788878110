import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Box, CircularProgress } from '@mui/material';
import { AppDispatch, RootState } from '../../redux/store/appStore';
import { useNavigate, useLocation } from 'react-router-dom';
import { STATUS } from '../../utils/status';
import SnackbarNotification from '../snackbar/snackbarNotification';
import { FormField } from '../inputField/textfield';
import useStyles from '../../assets/styles/style';
import { setCreateExpertsStatus, setUpdateExpertsStatus, createExpert, updateExpert } from '../../redux/slices/expertSlice/addExpertSlice';

interface FormState {
  id?: string;
  first_name: string;
  email: string;
  last_name: string;
  phone: string;
}

const ExpertForm = () => {
  const classes = useStyles();  
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { expertDetails: initialExpertDetails, id: expertId } = location.state || { expertDetails: null, id: '' };
  const [expertDetails, setExpertDetails] = useState<FormState>({ first_name: '', email: '', phone: '', last_name: '' });
  const createStatus = useSelector((state: RootState) => state.experts.createStatus);
  const updateStatus = useSelector((state: RootState) => state.experts.updateStatus);
  const { error } = useSelector((state: RootState) => state.experts);
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning'>('success');

  useEffect(() => {
    if (initialExpertDetails) {
      setExpertDetails(initialExpertDetails.expert_details);
      handleSnackbar('You can make your changes now.', 'info');
    }
  }, [initialExpertDetails]);

  const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setExpertDetails((prevDetails) => {
      if (name === 'email') {
        const emailRegex = /^[a-zA-Z0-9@.-]*$/;
        if (!emailRegex.test(value)) {
          return prevDetails;
        }
      }
      return {
        ...prevDetails,
        [name]: value,
      };
    });
  };
  

  const handleSnackbar = (message: string, severity: 'success' | 'error' | 'info' | 'warning') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFormSubmitted(true);

    const errors: { [key: string]: string } = {};

    if (!expertDetails?.email?.trim()) {
      errors.email = 'Email is Required';
    } else if (!isValidEmail(expertDetails.email)) {
      errors.email = 'Invalid email format';
    }
    if (!expertDetails?.first_name?.trim()) {
      errors.first_name = 'First Name is Required';
    }
    if (!expertDetails?.last_name?.trim()) {
      errors.last_name = 'Last Name is Required';
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      handleSnackbar('Make sure you fill all required fields.', 'warning');
      return;
    }
   if(expertId){
    const updatedFields = Object.keys(expertDetails).reduce((acc, key) => {
      if ((expertDetails as any)[key] !== (initialExpertDetails.expert_details as any)[key]) {
        (acc as any)[key] = (expertDetails as any)[key];
      }
      return acc;
    }, {} as Partial<FormState>);

    dispatch(updateExpert({ id: expertId, ...updatedFields }));
  }

   else {
    const payload = {
      first_name: expertDetails.first_name,
      email: expertDetails.email,
      last_name: expertDetails.last_name,
      ...(expertDetails.phone && { phone: expertDetails.phone }),
    }
      dispatch(createExpert(payload));
    }
  };

  const handlePhoneFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target; 
    if (name === 'phone' && !/^\d{0,10}$/.test(value)) {
      return;
    }
    setExpertDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  useEffect(() => {
    const handleUpdateStatus = () => {
      if (updateStatus === STATUS.SUCESS) {
        handleSnackbar('Expert Updated Successfully', 'success');
        setTimeout(() => {
          navigate('/experts');
        }, 1000);
      }
  
      if (updateStatus === STATUS.FAILED) {
        handleSnackbar('Error Occurred, Please try again!', 'error');
      }
    };

    handleUpdateStatus();
    return () => {
      if (updateStatus === STATUS.SUCESS) {
      dispatch(setUpdateExpertsStatus(STATUS.NOT_STARTED));
      }
    };
  }, [updateStatus, navigate, dispatch]);

  useEffect(
    () => {
      if (createStatus === STATUS.SUCESS) {
        handleSnackbar('Expert Added Successfully', 'success');
        setTimeout(() => {
          navigate('/experts');
        }, 1000);
      }
    
      if (createStatus === STATUS.FAILED) {
        handleSnackbar('Expert already exists', 'warning');
      }

      return () => {
        if (createStatus === STATUS.SUCESS) {
          dispatch(setCreateExpertsStatus(STATUS.NOT_STARTED));
        }
      };
    }, 
    [createStatus]
  );

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidPhoneNumber = (phone: string) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phone);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} className={classes.formContainer}>
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <FormField
            label="First Name"
            placeholder="Enter First Name"
            name="first_name"
            value={expertDetails.first_name}
            onChange={handleFieldChange}
            error={formSubmitted && formErrors.first_name ? formErrors.first_name : ''}
          />
          <FormField
            label="Last Name"
            placeholder="Enter Last Name"
            name="last_name"
            value={expertDetails.last_name}
            onChange={handleFieldChange}
            error={formSubmitted && formErrors.last_name ? formErrors.last_name : ''}
          />
          <FormField
            label="Email ID"
            placeholder="Enter Email ID"
            name="email"
            value={expertDetails.email}
            onChange={handleFieldChange}
            error={formSubmitted && formErrors.email ? formErrors.email : ''}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <FormField
            label="Phone Number"
            placeholder="Enter Phone Number"
            name="phone"
            value={expertDetails.phone}
            onChange={handlePhoneFieldChange}
            typographySx={{ marginTop: '20px' }}
          />  
        </div>
        <Button 
          type="submit" 
          variant="contained" 
          sx={{fontSize: '14px',textTransform: 'none',color: '#FFFFFF',width: '100%',backgroundColor: '#3D85C6',borderRadius: '8px', marginTop:'20px'}}
          disabled={createStatus == STATUS.PENDING}
          startIcon={createStatus == STATUS.PENDING ? <CircularProgress size={24} /> : null} 
        >
          {expertId ? "Update Expert" : "Add Expert"}
        </Button>
      </div>
      <SnackbarNotification
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
    </Box>  
  );
};

export default ExpertForm;
