import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import useStyles from '../../assets/styles/style';
import ProjectHeading from '../../components/project/projectHeading';
import ProjectForm from '../../components/project/projectForm';

const ProjectMain = () => {
  const classes = useStyles(); 
  return (
    <div className={classes.formMainContainer}>
      <Box className={classes.formMainHeadingBox}>
        <ProjectHeading />
      </Box>
      <Box component="main" className={classes.formMainBox}>
        <Paper className={classes.formMainpaper}>
          <ProjectForm/>
        </Paper>
      </Box>
    </div>
  );
};

export default ProjectMain;
