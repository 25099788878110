import React from 'react';
import { TableHead, TableRow } from '@mui/material';
import { TableCellHead } from './tableCell';

interface TableHeaderProps {
  headers: string[];
}

const TableHeader: React.FC<TableHeaderProps> = ({ headers }) => {
  return (
    <TableHead sx={{ backgroundColor: '#F7F9FA', '&:first-child td, &:first-child th': { borderBottom: 0 } }}>
      <TableRow>
        {headers.map((header) => (
          <TableCellHead key={header}>{header}</TableCellHead>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
