import React from 'react';
import { Box, Typography, Container, Icon } from '@mui/material';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';

interface StepSecondProps {
    selectedBox?: string | null;
    setSelectedBox: React.Dispatch<React.SetStateAction<string | null>>;
}

const Type2AllocationSteps: React.FC<StepSecondProps> = ({selectedBox,setSelectedBox = () => {}}) => {

    console.log({setSelectedBox})
    
    const handleBoxClick = (boxId: string) => {
        setSelectedBox(boxId);
    };

    return (
        <>
            {selectedBox === null &&
                <Container maxWidth="lg" sx={{ mt: 4, display: 'flex' }}>
                    <Box
                        onClick={() => handleBoxClick('copyPaste')}
                        sx={{
                            background: selectedBox === 'copyPaste'
                                ? 'linear-gradient(135deg, #4A90E2, #50B6FF)'
                                : 'white',
                            borderRadius: '16px',
                            border:'1px solid #3D85C6',
                            padding: '24px',
                            boxShadow: 2,
                            transition: 'transform 0.3s ease, background 0.3s ease',
                            '&:hover': {
                                transform: 'scale(1.05)',
                                cursor: 'pointer',
                            },
                            textAlign: 'center',
                            width: '300px',
                            marginRight: '40px',
                            marginLeft: '20%'
                        }}
                    >
                        <Icon
                            component={ContentCopyOutlinedIcon}
                            sx={{
                                fontSize: '78px',
                                color: selectedBox === 'copyPaste' ? '#fff' : '#3D85C6',
                                mb: 2
                            }}
                        />
                        <Typography
                            variant="h6"
                            sx={{
                                color: selectedBox === 'copyPaste' ? '#fff' : '#000',
                                padding: '12px 16px',
                                borderRadius: '4px',
                                marginBottom: '2px',
                                fontWeight: 'bold',
                                fontFamily:'Montserrat'
                            }}
                        >
                            Copy and Paste
                        </Typography>
                        <Typography 
                            sx={{
                                borderRadius: '4px',
                                fontFamily:'Montserrat',
                                fontSize:'12px'
                            }}>
                            Copy Paste email and task count from sheet to allocate.
                            
                        </Typography>
                    </Box>

                    <Box
                        onClick={() => handleBoxClick('uploadSheet')}
                        sx={{
                            background: selectedBox === 'uploadSheet'
                                ? 'linear-gradient(135deg, #4A90E2, #50B6FF)'
                                : 'white',
                            borderRadius: '16px',
                            border:'1px solid #3D85C6',
                            padding: '24px',
                            boxShadow: 2,
                            transition: 'transform 0.3s ease, background 0.3s ease',
                            '&:hover': {
                                transform: 'scale(1.05)',
                                cursor: 'pointer',
                            },
                            textAlign: 'center',
                            width: '300px'
                        }}
                    >
                        <Icon
                            component={UploadFileOutlinedIcon}
                            sx={{
                                fontSize: '78px',
                                color: selectedBox === 'uploadSheet' ? '#fff' : '#3D85C6',
                                mb: 2
                            }}
                        />
                        <Typography
                            variant="h6"
                            sx={{
                                color: selectedBox === 'uploadSheet' ? '#fff' : '#000',
                                padding: '12px 16px',
                                borderRadius: '12px',
                                marginBottom: '2px',
                                fontWeight: 'bold',
                                fontFamily:'Montserrat'
                            }}
                        >
                            Upload Using Sheet
                        </Typography>
                        <Typography 
                            sx={{
                                borderRadius: '4px',
                                fontFamily:'Montserrat',
                                fontSize:'12px'
                            }}>
                            Upload excel sheet with email and count to allocate.
                        </Typography>
                    </Box>

                </Container>
            }
        </>
    );
};

export default Type2AllocationSteps;
