import { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

export default function Card({ children }: Props) {
  return (
    <div className="bg-white drop-shadow-md rounded-lg p-6">{children}</div>
  );
}
