import React from 'react';
import { TableRow } from '@mui/material';
import { TableCellBody } from '../table/tableCell';

interface TableRowItemProps {
  row: any;
}

const CompletedTaskTable: React.FC<TableRowItemProps> = ({ row }) => {
  return (
    <TableRow
      key={row.id}
    >
      <TableCellBody>{row.project_name ? row.project_name : '-'}</TableCellBody> 
      <TableCellBody>{row.total_tasks ? row.total_tasks : '-'}</TableCellBody>
      <TableCellBody>{row.total_tasks_allocated ? row.total_tasks_allocated : '-'}</TableCellBody>
      <TableCellBody>{row.not_started_tasks_count ? row.not_started_tasks_count : '-'}</TableCellBody>
      <TableCellBody>{row.active_task_count ? row.active_task_count : '-'}</TableCellBody>
      <TableCellBody>{row.tasks_completed_count ? row.tasks_completed_count : '-'}</TableCellBody>
      <TableCellBody>{row.avg_task_completeing_per_day ? row.avg_task_completeing_per_day : '-'}</TableCellBody>
    </TableRow>
  );
};

export default CompletedTaskTable;
