import React from 'react';
import { Typography } from '@mui/material';

interface EditActionProps {
    actionValue: {
        index: number;
        edited: string;
    }[];
}

export default function EditAction({ actionValue }: EditActionProps) {
    if (actionValue?.length === 0) {
        return <Typography>No edits available.</Typography>;
    }

    return (
        <>
            {actionValue.map((value, valueIndex) => (
                <div key={valueIndex} style={{ marginBottom: '10px' }}>
                    <Typography sx={{ fontWeight: 'bold', marginBottom: '5px' }}>
                        Edit {value.index}:
                    </Typography>
                    <Typography>
                        {value.edited}
                    </Typography>
                </div>
            ))}
        </>
    );
}
