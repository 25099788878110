import { Box, Divider, Paper, Typography } from '@mui/material'
import React from 'react'
import VendorListTable from './VendorListTable'
import ProjectListHeading from '../projectList/projectListHeading'
import VendorListHeading from './VendorListHeading'

const VendorManagment = () => {
  return (
    <div style={{ width: '100vw', overflow: 'hidden' }}>
    <Box sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '0rem' }}>
      <VendorListHeading />
    </Box>
    <Divider />
    <Box component="main" sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '1rem' }}>
      <Paper sx={{
        bgcolor: '#FFFFFF',
        borderRadius: '8px',
        padding: '1rem',
        mb: '2rem',
        boxShadow: 2,
      }}>
        <div
          style={{
            borderBottom: '1px solid',
            borderColor: '#E8EAEF',
            margin: 2,
            padding: '0px, 24px, 0px, 24px',
          }}
        >
          <VendorListTable />
        </div>
      </Paper>
    </Box>
  </div>
  )
}

export default VendorManagment