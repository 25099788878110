import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Box, Typography, Button, TextField, ListItem, ListItemText, Checkbox, FormControlLabel, RadioGroup, Radio, Tooltip, CircularProgress, Container } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { addEmail, removeEmail } from '../../redux/reducers/selectedEmail';
import { AppDispatch, RootState } from '../../redux/store/appStore';
import { setDeadline } from '../../redux/reducers/deadline';
import { STATUS } from '../../utils/status';
import { useParams } from 'react-router-dom';
import { addEmails, removeEmails } from '../../redux/reducers/selectedEmailReviewer';
import { AddButton } from '../button/button';
import { getProjectExperts } from '../../redux/slices/projectSlice/addProjectSlice';
import debounce from 'lodash.debounce';
import { getUserAccess } from '../../redux/slices/company';
import { getExpert } from '../../redux/slices/expertSlice/addExpertSlice';

interface SelectedId {
  id: string;
  task_name: string;
}

interface AllocationModalProps {
  open: boolean;
  onClose: () => void;
  onEmailClick: any;
  selectedEmail: string | null;
  onAllocateClick: () => void;
  isCheckboxMode?: boolean;
  selectedIds?: SelectedId[];
  allocationType?: string;
  taskers?: any,
  searchQuery?:any,
  onSearchChange?:any,
  

}

const defaultDeadlineValue = () => {
  const today = new Date();
  const defaultDeadline = new Date(today);
  defaultDeadline.setDate(today.getDate() + 6);
  return defaultDeadline.toISOString().split('T')[0];
};

const AllocationModal: React.FC<AllocationModalProps> = ({
  open,
  onClose,
  onEmailClick,
  selectedEmail,
  onAllocateClick,
  isCheckboxMode = false,
  selectedIds = [],
  allocationType
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const selectedEmails = useSelector((state: RootState) => state.selectedEmails.selectedEmail);
  const selectedEmailsReview = useSelector((state: RootState) => state.selectedEmailsReview.selectedEmailReview);
  const selectedTasksWithoutAllocation = useSelector((state: RootState) => state.allocationTask.tasksWithoutAllocation);
  const selectedReviewsWithoutAllocation = useSelector((state: RootState) => state.allocationTask.reviewWithoutAllocation);
  const { getStatus:getCompanyExpertsStatus, experts: taskers } = useSelector((state: RootState) => state.experts)
  // const experts = useSelector((state: RootState) => state.experts.experts.results);

  // const companyExperts = useSelector((state: RootState) => state.company.company.data)
  const [deadline, setDeadlineState] = useState(defaultDeadlineValue());
  const [emailSelected, setEmailSelected] = useState(false);
  const [selectedTaskerId, setSelectedTaskerId] = useState('');
  const { id } = useParams<string>();
  const path = window.location.href.includes('allocation')
  const [newEmails, setNewEmails] = useState('');

  const handleCheckboxChange = (id: string) => {
    const emails = allocationType === 'TASK' ? selectedEmails : selectedEmailsReview;
    if (emails.includes(id)) {
      if (allocationType === 'TASK') {
        dispatch(removeEmail(id));
      } else {
        dispatch(removeEmails(id));
      }
    } else {
      if (allocationType === 'REVIEW') {
        if (selectedEmailsReview.length <= selectedIds.length) {
          dispatch(addEmails(id));
        }
      } else if (allocationType === 'TASK') {
        if (selectedTasksWithoutAllocation.length === 0 || selectedEmails.length < selectedTasksWithoutAllocation.length) {
          dispatch(addEmail(id));
        }
      }
    }
  };

  const handleAddEmails = () => {
    const emailsArray = newEmails.split(',').map(email => email.trim()).filter(email => email !== '');
    if (emailsArray.length === 0) {
      return;
    }

    const validTaskers = emailsArray.map(email => taskers.results.find((tasker:any) => tasker.email.toLowerCase() === email.toLowerCase()))
      .filter((tasker) => tasker !== undefined);

    if (validTaskers.length === 0) {
      setNewEmails('');
      return;
    }

    let maxSelectableEmails = 0;
    let currentSelected = 0;

    if (isCheckboxMode) {
      if (allocationType === 'TASK') {
        maxSelectableEmails = selectedTasksWithoutAllocation.length;
        currentSelected = selectedEmails.length;
      } else if (allocationType === 'REVIEW') {
        maxSelectableEmails =
          selectedTasksWithoutAllocation.length > 0
            ? Math.abs(selectedTasksWithoutAllocation.length - selectedReviewsWithoutAllocation.length)
            : selectedReviewsWithoutAllocation.length;
        currentSelected = selectedEmailsReview.length;
      }
    }

    const remainingSlots = maxSelectableEmails - currentSelected;
    if (remainingSlots <= 0) {
      setNewEmails('');
      return;
    }

    const emailsToAdd = validTaskers.slice(0, remainingSlots);
    const emailsNotAdded = validTaskers.slice(remainingSlots);

    emailsToAdd.forEach(tasker => {
      if (allocationType === 'TASK') {
        if (tasker !== undefined) {
          dispatch(addEmail(tasker.id));
        }
      } else {
        if (tasker !== undefined) {
          dispatch(addEmails(tasker.id));
        }
      }
    });
    setNewEmails('');
  };

  const handleDeadlineChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDeadline = event.target.value;
    setDeadlineState(newDeadline);
    dispatch(setDeadline(newDeadline));
  };

  const isAllocateButtonDisabled = !(deadline && emailSelected);
  const today = new Date().toISOString().split('T')[0];

  useEffect(() => {
    dispatch(setDeadline(defaultDeadlineValue()));
  }, [dispatch]);

  useEffect(() => {
    setEmailSelected(selectedEmail !== null && selectedEmail !== '');
  }, [selectedEmail]);

  useEffect(() => {
    if (isCheckboxMode) {
      const emails = allocationType === 'TASK' ? selectedEmails : selectedEmailsReview;
      setEmailSelected(emails.length > 0);
    }
  }, [selectedEmails, selectedEmailsReview, isCheckboxMode, allocationType]);



  useEffect(() => {
    if (path) {
      dispatch(getExpert({
        // type: allocationType,
        paginate: true,
        is_blocked: false,
        project_id: id,
        pageSize: 50,
        fields: JSON.stringify(['id', 'expert.id', 'sub_project.id', 'expert.first_name', 'expert.last_name', 'expert.email', 'expert.pseudo_name', 'created_at',]),
      }))
    }
    else if(open){
      dispatch(getExpert({
        //type: allocationType,
        paginate: true,
        is_blocked: false,
        project_id: id,
        pageSize: 50,
        fields: JSON.stringify(['id', 'expert.id', 'sub_project.id', 'expert.first_name', 'expert.last_name', 'expert.email', 'expert.pseudo_name', 'created_at',]),
      }))
    }
  }, [open, path, allocationType])

  const debouncedResults = useMemo(() => {
    return debounce((searchQuery) => {
      dispatch(getExpert({
        type: allocationType,
        paginate: true,
        is_blocked: false,
        project_id: id,
        fields: JSON.stringify(['id', 'expert.id', 'sub_project.id', 'expert.first_name', 'expert.last_name', 'expert.email', 'expert.pseudo_name', 'created_at']),
        ...(searchQuery.length > 0 ? { search: searchQuery } : {})
      }))
    }, 300);
  }, [allocationType, dispatch, id]);
  

  return !path ?
    (
      <>
        <Modal open={open} onClose={onClose} aria-labelledby="modal-title" aria-describedby="modal-description">
          <Box sx={{ p: 2, maxWidth: 950, maxHeight: 660, overflow: 'hidden', backgroundColor: 'white', borderRadius: '8px', margin: 'auto', marginTop: '2%', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }} >
              <Box sx={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1, p: 2, borderBottom: '1px solid #E0E0E0' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Typography id="modal-title" sx={{ color: 'black', fontWeight: '400', fontSize: '24px' }}>
                    Allocate
                  </Typography>
                  <TextField
                    placeholder="Search Taskers" variant="outlined" size="small" style={{ width: '50%' }}
                    onChange={(e: any) => { debouncedResults.cancel(); debouncedResults(e.target.value) }}
                    InputProps={{ endAdornment: <SearchIcon style={{ cursor: 'pointer' }} />, }}
                  />
                </div>
              </Box>

              <Box sx={{ flex: 1, overflowY: 'auto', p: 2, pt: 0 }}>
                {getCompanyExpertsStatus === STATUS.PENDING && (<div style={{ marginTop: '10px', 'textAlign': 'center' }} ><CircularProgress size={40} /></div>)}
                {getCompanyExpertsStatus === STATUS.SUCESS && (
                  <RadioGroup
                    value={selectedTaskerId || ''}
                    onChange={(e) => {
                      const selectedTasker = taskers.results.find((tasker:any) => tasker.expert_details.id === e.target.value).expert_details;
                      if (selectedTasker) {
                        onEmailClick({ id: selectedTasker.id, email: selectedTasker.email, name: selectedTasker.first_name != null ? selectedTasker.first_name : '' });
                        setSelectedTaskerId(selectedTasker.id);
                      }
                    }}
                  >
                    <>
                    {taskers.results.map((tasker:any) => {
                      tasker = tasker.expert_details
                      return (
                        <ListItem
                          key={tasker.id}
                          sx={{
                            color: 'black',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <FormControlLabel
                            value={tasker.id}
                            control={<Radio />}
                            label={<ListItemText primary={tasker.email} />}
                          />
                        </ListItem>
                      )
                    })}
                    </>
                  </RadioGroup>
                )}
                {getCompanyExpertsStatus === STATUS.FAILED && (
                  <Typography sx={{ color: 'black', fontWeight: '400', fontSize: '14px' }}>
                    No Experts to allocate
                  </Typography>
                )}
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: '10px', p: 2, borderTop: '1px solid #E0E0E0' }}>
                <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', color: '#10383D', marginLeft: '10px' }}>
                  Select Deadline:
                </Typography>
                <input type="date" id="deadline" name="deadline" value={deadline} onChange={handleDeadlineChange} min={today} />
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', gap: '10px', p: 2, borderTop: '1px solid #E0E0E0', }}>
                <Button sx={{ minWidth: '5px', minHeight: '40px', backgroundColor: '#F7F9FA', padding: '8px 16px', gap: '4px', ml: '80%', borderRadius: '8px', '&:hover': { backgroundColor: '#E0E0E0' }, }} onClick={onClose}>
                  <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '600', fontSize: '14px', color: '#10383D', textTransform: 'none' }}>
                    {isCheckboxMode ? 'Previous' : 'Cancel'}
                  </Typography>
                </Button>
                <Button
                  sx={{ minWidth: '66px', minHeight: '40px', backgroundColor: '#3D85C6', padding: '8px 16px', gap: '4px', borderRadius: '8px', '&:hover': { backgroundColor: '#3D85C6' }, '&:disabled': { cursor: 'not-allowed', backgroundColor: '#A9A9A9' } }}
                  onClick={onAllocateClick}
                  disabled={isAllocateButtonDisabled}
                >
                  <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '600', fontSize: '14px', color: '#FFFFFF', textTransform: 'none' }}> Allocate</Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      </>
    ) : (
      <Container maxWidth="lg" sx={{ background: 'white', borderRadius: '10px', padding: '20px', marginTop: '20px', boxShadow: 3 }}>
        <Box>
          {/* <Typography sx={{ display: 'flex', flexDirection: 'column', height: '100%', border: '2px dashed #4a90e2', borderRadius: '10px', padding: '20px', backgroundColor: '#ffffff', width: '100%', textAlign: 'center', marginTop: '2%', maxHeight: '800px', overflowY: 'auto'}}> */}
          <Box sx={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1, p: 2, borderBottom: '1px solid #E0E0E0' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography id="modal-title" sx={{ color: 'black', fontWeight: '400', fontSize: '24px' }}>Allocate</Typography>
              {/* <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                    {isCheckboxMode &&
                      <>
                        <TextField sx={{ marginRight: '20px', marginTop: '5px' }} placeholder="Enter emails separated by commas" variant="outlined" size="small" value={newEmails} onChange={e => setNewEmails(e.target.value)} />
                        <AddButton onClick={handleAddEmails}>Select</AddButton>
                      </>
                    }
                  </div> */}
              <TextField
                placeholder="Search Taskers" variant="outlined" size="small"
                onChange={(e: any) => { debouncedResults.cancel(); debouncedResults(e.target.value) }}
                style={{ width: '50%' }}
                InputProps={{ endAdornment: (<SearchIcon style={{ cursor: 'pointer' }} />) }}
              />
            </div>

          </Box>

          <Box sx={{ flex: 1, overflowY: 'auto', p: 2, pt: 0 }}>
          {getCompanyExpertsStatus !== STATUS.SUCESS ? <div style={{ marginTop: '10px', 'textAlign': 'center' }} ><CircularProgress size={40} /></div> :
              taskers.results.map((tasker:any) => {

                tasker = tasker.expert_details
                const emails = allocationType === 'TASK' ? selectedEmails : selectedEmailsReview;
                const isCheckboxDisabled = emails.length >= selectedIds.length && !emails.includes(tasker.id);
                return (
                  <ListItem key={tasker.id} sx={{ color: 'black', cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                    {allocationType === 'REVIEW' &&
                      isCheckboxDisabled ? (
                      <Tooltip
                        title={`You can't select more than ${selectedIds.length} reviewers because you have selected only ${selectedIds.length} reviewers unallocated for allocation.`}
                        arrow placement="bottom-start"
                      >
                        <span>
                          <Checkbox
                            checked={emails.includes(tasker.id)}
                            onChange={() => handleCheckboxChange(tasker.id)}
                            disabled={!emails.includes(tasker.id)}
                             />
                        </span>
                      </Tooltip>
                    ) : allocationType === 'TASK' && selectedTasksWithoutAllocation.length > 0 && emails.length >= selectedTasksWithoutAllocation.length && !emails.includes(tasker.id) ? (
                      <Tooltip
                        title={`You can't select more than ${selectedTasksWithoutAllocation.length} taskers because you have selected only ${selectedTasksWithoutAllocation.length} unallocated tasks for allocation.`}
                        arrow placement="bottom-start"
                      >
                        <span>
                          <Checkbox
                            checked={emails.includes(tasker.id)}
                            onChange={() => handleCheckboxChange(tasker.id)}
                            disabled={!emails.includes(tasker.id)}
                            />
                        </span>
                      </Tooltip>
                    ) : (
                      <Checkbox
                        checked={emails.includes(tasker.id)}
                        onChange={() => handleCheckboxChange(tasker.id)}
                        disabled={allocationType === 'TASK' && selectedTasksWithoutAllocation.length > 0 && emails.length >= selectedTasksWithoutAllocation.length && !emails.includes(tasker.id)}
                      />
                    )
                    }
                    <ListItemText primary={tasker.email} />
                  </ListItem>
                );
              })
            }
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: '10px', p: 2, borderTop: '1px solid #E0E0E0' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', color: '#10383D', marginLeft: '10px' }}>
              Select Deadline:
            </Typography>
            <input type="date" id="deadline" name="deadline" value={deadline} onChange={handleDeadlineChange} min={today} />
          </Box>
        </Box>
      </Container>
    )
};


export default AllocationModal;