import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import StorefrontIcon from '@mui/icons-material/Storefront';

export const usersTabData = [
    {
      'label': 'Admins',
      'value': 'users',
      'to': '/users',
      'icon': ManageAccountsRoundedIcon

    },
    {
      'label': 'Experts',
      'value': 'experts',
      'to': '/experts',
      'icon': ManageAccountsRoundedIcon

    },
    {
      'label': 'Vendors',
      'value': 'vendors',
      'to': '/vendors',
      'icon': StorefrontIcon
    },
  ]