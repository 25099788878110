import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { createUser, getUser, updateUser} from '../slices/userSlice/addUserSlice';
import { STATUS } from '../../utils/status';
import { UserTypeState } from '../interfaces/interface';

const ExtraReducers = (builder: ActionReducerMapBuilder<UserTypeState>) => {
  builder
    .addCase(createUser.pending, (state) => {
      state.createStatus = STATUS.PENDING;
    })
    .addCase(createUser.fulfilled, (state, action: PayloadAction<any>) => {
      console.log('Create Fulfilled:', action.payload); 
      state.users = action.payload; 
      state.createStatus = STATUS.SUCESS; 
    })
    .addCase(createUser.rejected, (state, action) => { 
      state.createStatus = STATUS.FAILED;
    })
    .addCase(getUser.pending, (state) => {
      state.getStatus = STATUS.PENDING;
    })
    .addCase(getUser.fulfilled, (state, action: PayloadAction<any>) => {
      state.users = action.payload; 
      state.getStatus = STATUS.SUCESS; 
    })
    .addCase(getUser.rejected, (state, action) => { 
      state.getStatus = STATUS.FAILED;
    })
    .addCase(updateUser.pending, (state) => {
      state.updateStatus = STATUS.PENDING;
    })
    .addCase(updateUser.fulfilled, (state, action: PayloadAction<any>) => {
      const updatedUser = action.payload;
      state.users.results = state.users.results.map((user: any) =>
        user.id === updatedUser.id ? updatedUser : user
      );
      state.updateStatus = STATUS.SUCESS;
    })
    .addCase(updateUser.rejected, (state, action) => {
      state.updateStatus = STATUS.FAILED;
    })
};

export default ExtraReducers;
