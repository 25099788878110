import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface RichTextEditorProps {
  value?: string;
  defaultValue?: string;
  onChange: (value: string) => void;
  toolbarOptions?: any;
}

const RichTextEditor: React.FC<RichTextEditorProps> = ({ value, defaultValue, onChange, toolbarOptions }) => {
  const [editorValue, setEditorValue] = useState(defaultValue || '');

 
  useEffect(() => {
    if (value !== undefined) {
      setEditorValue(value);
    }
  }, [value]);

  const handleChange = (content: string) => {
    setEditorValue(content);
    onChange(content);
  };

  const modules = {
    toolbar: toolbarOptions || [
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' },
      { 'indent': '-1' }, { 'indent': '+1' }],
      ['link'],
    ],
  };

  const formats = [
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link',
  ];

  return (
    <div style={{ height: '350px', border: '1px solid #ccc' }}>
      <ReactQuill
        value={editorValue}
        onChange={handleChange}
        modules={modules}
        formats={formats}
        style={{ height: '88%' }}
      />
    </div>
  );
};

export default RichTextEditor;
