import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

interface StyledButtonProps extends ButtonProps {
  children: React.ReactNode;
}

export const SubmitButton: React.FC<StyledButtonProps> = ({ children, ...props }) => {
  return (
    <Button
      {...props}
      sx={{
        mt: 2,
        fontSize: '14px',
        textTransform: 'none',
        color: '#FFFFFF',
        width: '100%',
        backgroundColor: '#3D85C6',
        borderRadius: '8px',
      }}
    >
      {children}
    </Button>
  );
};

export const AddButton: React.FC<StyledButtonProps> = ({ children, ...props }) => {
  const path = window.location.href.includes("tab=task")
  return (
    <Button
      {...props}
  
      variant="contained"
      disableElevation
      sx={{
        marginRight: '1rem',
        width: path ? '150px' : 'auto',
        height: '40px',
        marginTop: '10px',
        borderRadius: '8px',
        padding: '8px, 16px, 8px, 16px',
        backgroundColor: '#3D85C6',
        '&:hover': {
          backgroundColor: '#3D85C6',
        },
      }}
    >
      <Typography sx={{ fontSize: '14px', lineHeight: '24px', textTransform:'none', fontWeight:'600' }}>{children}</Typography>
    </Button>
  );
};

export const AnalyticsButton: React.FC<StyledButtonProps> = ({ children, ...props }) => {
  return (
    <Button
      {...props}
      sx={{
        marginRight: '1rem',
        width: 'auto',
        height: '40px',
        marginTop: '10px',
        borderRadius: '8px',
        padding: '8px, 16px, 8px, 16px',
        backgroundColor: '#3D85C6',
        '&:hover': {
          backgroundColor: '#3D85C6',
        },
      }}
    >
      <Typography sx={{ fontSize: '14px', lineHeight: '24px', textTransform:'none', fontWeight:'600' }}>{children}</Typography>
    </Button>
  );
};







