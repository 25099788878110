import { TableCell, TableRow, Tooltip } from '@mui/material'
import React from 'react'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
interface VendorListTableProps {
    row: any;
    onDeleteClick: (id: string) => void;
  }

const VendorTableRowItem: React.FC<VendorListTableProps> = ({row, onDeleteClick}) => {
    return (
        <TableRow 
        key={row.id}
        >
            <TableCell><span>{row.vendor_name}</span></TableCell>
            <TableCell><span>{row.pseudo_name && row.pseudo_name != null ? row.pseudo_name : row.vendor_pseudo_name}</span></TableCell>
            <TableCell><span>{row.vendor_email}</span></TableCell>
            <TableCell><span>{row.vendor_phone_number? row.vendor_phone_number: '-'}</span></TableCell>
            <TableCell>{
                <>
                    <Tooltip title={"Delete Vendor"}>
                        <DeleteOutlineOutlinedIcon
                            onClick={() => onDeleteClick(row.id)}
                            sx={{
                                color: "#C00F00",
                                cursor: "pointer",
                            }} />
                    </Tooltip>
                </>
            }</TableCell>
        </TableRow>
    )
}

export default VendorTableRowItem