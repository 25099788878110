import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface RadioContentState {
    radio: boolean;
    single_select: boolean;
    content: boolean;
    code: boolean;
    checkbox: boolean;
    multi_select: boolean;
    rate: boolean;
    adjective_list: boolean;
}

const initialState: RadioContentState = {
    radio: false,
    single_select: false,
    content: false,
    code: false,
    checkbox: false,
    multi_select: false,
    rate: false,
    adjective_list: false,
};

const radioContentSlice = createSlice({
    name: 'radioContent',
    initialState,
    reducers: {
        setRadioEdited(state, action: PayloadAction<boolean>) {
            state.radio = action.payload;
        },
        setContentEdited(state, action: PayloadAction<boolean>) {
            state.content = action.payload;
        },
        setCheckboxEdited(state, action: PayloadAction<boolean>) {
            state.checkbox = action.payload;
        },
        setSingleSelectEdited(state, action: PayloadAction<boolean>) {
            state.single_select = action.payload;
        },
        setCodeEdited(state, action: PayloadAction<boolean>) {
            state.code = action.payload;
        },
        setMultiSelectEdited(state, action: PayloadAction<boolean>) {
            state.multi_select = action.payload;
        },
        setAdjectiveSelectEdited(state, action: PayloadAction<boolean>) {
            state.adjective_list = action.payload;
        },
        setRateSelectEdited(state, action: PayloadAction<boolean>) {
            state.rate = action.payload;
        },
        resetEditedStates(state) {
            state.radio = false;
            state.content = false;
            state.checkbox = false;
            state.single_select = false;
            state.code = false;
            state.multi_select = false;
            state.rate = false;
        },
    },
});

export const { setRadioEdited, setContentEdited, setAdjectiveSelectEdited, setCheckboxEdited, resetEditedStates, setCodeEdited, setMultiSelectEdited, setSingleSelectEdited, setRateSelectEdited } = radioContentSlice.actions;
export default radioContentSlice.reducer;
