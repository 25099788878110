import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';
import { MetaQCState } from '../../interfaces/interface';
import extraReducers from '../../extraReducers/metaqcExraReducer';

const initialState: MetaQCState = {
  metaqc: {},
  error: null,
  createMetaQCStatus: STATUS.NOT_STARTED
};

// export const createMetaQC = createAsyncThunk(
//   'task/update_meta_qc_status/company/post',
//   async (payload: any, thunkAPI) => {
//     const response = await api.post(`task/update_meta_qc_status/company/`, payload);
//     const { status, data } = response;
//     if (status === 200) {
//       return {payload, data};
//     } else {
//       return thunkAPI.rejectWithValue({ response, data });
//     }
//   }
// );

const metaqcslice = createSlice({
  name: 'metaqc',
  initialState,
  reducers: {},
  extraReducers
});

// export const {setCreateMetaQC} = metaqcslice.actions
export default metaqcslice.reducer;
