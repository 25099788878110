import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '1rem',
    marginTop: '1rem',
  },
  searchInput: {
    padding: '8px, 16px, 8px, 16px',
    border: '1px',
    borderRadius: '4px',
    gap: '12px',
    width: '320px',
    marginBottom: '4px',
  },
  showEntries: {
    display: 'flex',
    marginRight: '20px',
    alignItems: 'center',
  },
  showEntriesText: {
    paddingTop: '25px',
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: '600',
    color: '#10383D',
  },
  tableContainer: {
    minWidth: 650,
  },
  noUsersMessage: {
    textAlign: 'center',
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  progressDiv: {
    display: 'flex', 
    justifyContent: 'center', 
    marginTop: '20px'
  },
});
