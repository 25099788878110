import React, { useEffect, useState } from 'react';
import InviteTasker from './inviteTasker';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/store/appStore';
import { setUpdateProjectStatus } from '../../../redux/slices/projectSlice/addProjectSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import SnackbarNotification from '../../snackbar/snackbarNotification';
import { STATUS } from '../../../utils/status';
import InviteReviewer from './inviteReviewer';

const InviteExpert = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning'>('success');
  const { id } = useParams();
  const navigate = useNavigate();
  const updateStatus = useSelector((state: RootState) => state.projects.updateStatus);

  useEffect(() => {
    const handleUpdateStatus = () => {
      if (updateStatus === STATUS.SUCESS) {
        handleSnackbar('Success', 'success');
        // window.location.reload();
      }      
      if (updateStatus === STATUS.FAILED) {
        handleSnackbar('Error Occurred, Please try again!', 'error');
      }
    };

    handleUpdateStatus();
    return () => {
      dispatch(setUpdateProjectStatus(STATUS.NOT_STARTED));
    };
  }, [updateStatus, navigate, dispatch]);

  const handleSnackbar = (message: string, severity: 'success' | 'error' | 'info' | 'warning') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div>
      <Typography sx={{ marginLeft: '2%', marginBottom: '10px', marginTop: '10px', fontSize: '20px', fontWeight: '600' }}>Invite Tasker</Typography>
      <InviteTasker/>

      <Typography sx={{ marginLeft: '2%', marginBottom: '10px', marginTop: '10px', fontSize: '20px', fontWeight: '600' }}>Invite Reviewer</Typography>
      <InviteReviewer/>

      <SnackbarNotification
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
    </div>


  );
};

export default InviteExpert;
