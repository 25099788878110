import React, { useEffect, useMemo, useState } from 'react';
import { Typography, Checkbox, TextField, CircularProgress, FormControl, RadioGroup, FormControlLabel, Radio, Autocomplete } from '@mui/material';
import TaskReviewToggle from './taskReviewToggle';
import { AddButton } from '../button/button';
import SnackbarNotification from '../snackbar/snackbarNotification';
import { AppDispatch, RootState } from '../../redux/store/appStore';
import { STATUS } from '../../utils/status';
import { useDispatch, useSelector } from 'react-redux';
import { createConfig, setCreateConfigStatus, setUpdateConfigStatus, updateConfig } from '../../redux/slices/config';
import { useNavigate, useParams } from 'react-router-dom';
import debounce from 'lodash.debounce';

interface BooleanTypeContentProps {
  chipLabel: string;
  chipId: string;
  isChecked: boolean;
  setIsChecked: (checked: boolean) => void;
  taskCategory: string;
}

const BooleanTypeContent: React.FC<BooleanTypeContentProps> = ({ chipLabel, chipId, isChecked, setIsChecked, taskCategory }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();
  const createStatus = useSelector((state: RootState) => state.config.createStatus);
  const updateStatus = useSelector((state: RootState) => state.config.updateStatus);
  const booleanTypeData = useSelector((state: RootState) => state.config.currentConfig)
  const navigate = useNavigate();
  const [order, setOrder] = useState('');
  const [formValues, setFormValues] = useState({ identifier: '' });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning'>('success');
  const fetchedConfig = useSelector((state: RootState) => state.config.config);
  const [selectedOption, setSelectedOption] = useState<'TASK' | 'REVIEW'>('TASK');
  const getConfig = useSelector((state: RootState) => state.config.currentConfig);
  const config_id = getConfig.id;
  const [localIsChecked, setLocalIsChecked] = useState<boolean>(isChecked);
  const [checked, setChecked] = useState<boolean>(false);

  useEffect(() => {
    setLocalIsChecked(isChecked);
  }, [isChecked]);

  useEffect(() => {
    if (getConfig) {
      console.log({ getConfig })
      setLocalIsChecked(getConfig.value);
      setSelectedOption(getConfig.type);
      setFormValues({ identifier: getConfig.identifier });
      setOrder(getConfig.order);
      setChecked(getConfig.required);
    }
  }, [getConfig]);

  useEffect(() => {
    if (fetchedConfig) {
      setIsChecked(isChecked);
    }
  }, [fetchedConfig, chipId, setIsChecked]);

  const handleSnackbar = (message: string, severity: 'success' | 'error' | 'info' | 'warning') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleOrderChange = (e: any) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === '' || regex.test(e.target.value)) {
      setOrder(e.target.value);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setLocalIsChecked(checked);
    setIsChecked(checked);
  };

  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setChecked(checked);
  };

  const handleSubmit = () => {
    const payload = {
      ...(config_id && { id: config_id }),
      project_id: id,
      task_category_id: taskCategory,
      config_id: chipId,
      name: chipLabel,
      value: localIsChecked,
      type: selectedOption,
      identifier: formValues.identifier,
      ...(order && Number(order) !== 0 && { order: Number(order) }),
      required: localIsChecked
    };

    const newUpdateValues = Object.keys(payload).reduce((acc, key) => {
      const fetchedValue = booleanTypeData[key];
      const newValue = payload[key];
      if (
        key in booleanTypeData &&
        newValue !== fetchedValue &&
        (fetchedValue !== '' || newValue !== undefined) &&
        newValue !== ''
      ) {
        acc[key] = newValue;
      }

      return acc;
    }, {} as Record<string, string | boolean | string[] | undefined>);

    if (config_id) {
      dispatch(updateConfig({ id: config_id, ...newUpdateValues }));
    } else {
      dispatch(createConfig(payload));
    }
  };

  useEffect(() => {
    const handleCreateStatus = () => {
      if (createStatus === STATUS.SUCESS) {
        handleSnackbar('Config Added Successfully', 'success');
        setTimeout(() => {
          navigate(`/project/${id}`);
        }, 1000);
      }
      if (createStatus === STATUS.FAILED) {
        handleSnackbar('error occured please try again', 'warning');
      }
    }
    handleCreateStatus();
    return () => {
      if (createStatus === STATUS.SUCESS) {
        dispatch(setCreateConfigStatus(STATUS.NOT_STARTED));
      }
    };
  }, [createStatus]);

  useEffect(() => {
    const handleUpdateStatus = () => {
      console.log({ updateStatus })
      if (updateStatus === STATUS.SUCESS) {
        handleSnackbar('Config Updated Successfully', 'success');
        setTimeout(() => {
          navigate(`/project/${id}`);
        }, 1000);
      }

      if (updateStatus === STATUS.FAILED) {
        handleSnackbar('Error Occurred, Please try again!', 'error');
      }
    };

    handleUpdateStatus();
    return () => {
      dispatch(setUpdateConfigStatus(STATUS.NOT_STARTED));
    };
  }, [updateStatus, navigate, dispatch]);

  const handleOptionChange = (
    event: React.MouseEvent<HTMLElement>,
    newOption: 'TASK' | 'REVIEW'
  ) => {
    if (newOption !== null) {
      setSelectedOption(newOption);
    }
  };

  return (
    <>
      <div style={{ display: 'flex' }}>
        <Typography sx={{ fontFamily: 'Montserrat', fontSize: 20, fontWeight: 600, lineHeight: '24px', color: '#10383D', marginTop: 2, marginBottom: 2, textTransform: 'capitalize', }}>
          {chipLabel.replace(/_/g, ' ')}
        </Typography>
      </div>
      <div style={{ display: 'flex', marginBottom: '20px' }}>
        <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '5px' }}>
          Enter Name:<br />
          <Typography sx={{ fontFamily: 'Montserrat', fontSize: '12px', fontWeight: 400, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}> This Name will identify unique configs </Typography>
        </Typography>
        <TextField
          placeholder="Enter Name"
          name="formValues"
          value={formValues.identifier}
          onChange={(e) => setFormValues({ identifier: e.target.value })}
          sx={{ marginLeft: '70px', marginTop: '10px' }}
          size="small"
        />
      </div>
      <div style={{ display: 'flex', marginBottom: '20px' }}>
        <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '5px' }}>
          Order:<br />
          <Typography sx={{ fontFamily: 'Montserrat', fontSize: '12px', fontWeight: 400, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}> This Order will arrange config on expert side </Typography>
        </Typography>
        <TextField
          placeholder="Enter Order"
          name="order"
          value={order}
          onChange={handleOrderChange}
          sx={{ marginLeft: '35px', marginTop: '10px' }}
          size="small"
        />
      </div>
      <div style={{ display: 'flex' }}>
        <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '15px' }}>
          Do you want to mark it true?
        </Typography>
        <Checkbox
          checked={localIsChecked}
          onChange={handleCheckboxChange}
          color="primary"
          sx={{ marginTop: '5px', marginLeft: '30px' }}
        />
      </div>

      <div style={{ display: 'flex' }}>
        <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '15px' }}>
          Do you want to mark it required?
        </Typography>
        <Checkbox
          checked={checked}
          onChange={handleCheckbox}
          color="primary"
          sx={{ marginTop: '5px' }}
        />
      </div>
      <div style={{marginTop:'20px'}}>
        <Typography>
          Choose Type:
        </Typography>
        <TaskReviewToggle
          selectedOption={selectedOption}
          onChange={handleOptionChange}
        />
      </div>
      <AddButton
        disabled={config_id ? updateStatus == STATUS.PENDING : createStatus == STATUS.PENDING}
        startIcon={config_id ? updateStatus == STATUS.PENDING : createStatus == STATUS.PENDING ? <CircularProgress size={20} /> : null}
        onClick={handleSubmit}
      >
        {config_id ? "Update" : "Submit"}
      </AddButton>
      <SnackbarNotification
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
    </>
  );
};

export default BooleanTypeContent;
