import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { createUser, getUser, updateUser} from '../slices/userSlice/addUserSlice';
import { STATUS } from '../../utils/status';
import { ConfigState } from '../interfaces/interface';
import { createConfig, getConfig, getConfigCategoryList, getConfigForTable, getConfigsData, updateConfig } from '../slices/config';

const ExtraReducers = (builder: ActionReducerMapBuilder<ConfigState>) => {
  builder
  .addCase(createConfig.pending, (state) => {
    state.createStatus = STATUS.PENDING;
    
  })
  .addCase(createConfig.fulfilled, (state, action: PayloadAction<any>) => { 
    state.config = action.payload; 
    state.createStatus = STATUS.SUCESS; 
  })
  .addCase(createConfig.rejected, (state, action) => { 
    state.createStatus = STATUS.FAILED;
    console.log(STATUS.FAILED)
  })
  .addCase(getConfig.fulfilled, (state, action) => {
    state.getStatus = STATUS.SUCESS;
    state.config = action.payload;
  })
  .addCase(getConfig.pending, (state, action) => {
    state.getStatus = STATUS.PENDING;
  })
  .addCase(getConfigForTable.pending, (state, action) => {
    state.getConfigStatus = STATUS.PENDING;
    console.log(STATUS.PENDING)
  })
  .addCase(getConfigForTable.fulfilled, (state, action) => {
    state.getConfigStatus = STATUS.SUCESS;
    state.config = action.payload;
  })
  .addCase(getConfigForTable.rejected, (state, action) => { 
    state.getStatus = STATUS.FAILED;
    console.log(STATUS.FAILED)
  })
  .addCase(getConfigCategoryList.pending, (state, action) => {
    state.getConfigCategoryListStatus = STATUS.PENDING;
  })
  .addCase(getConfigCategoryList.fulfilled, (state, action) => {
    state.getConfigCategoryListStatus = STATUS.SUCESS;
    state.configCategories = action.payload;
  })
  .addCase(getConfigCategoryList.rejected, (state, action) => { 
    state.getConfigCategoryListStatus = STATUS.FAILED;
  })
  .addCase(updateConfig.pending, (state) => {
    state.updateStatus = STATUS.PENDING;
    console.log(STATUS.PENDING)
  })
  .addCase(updateConfig.fulfilled, (state, action: PayloadAction<any>) => { 
    state.config = action.payload; 
    state.updateStatus = STATUS.SUCESS; 
    console.log(STATUS.SUCESS)
  })
  .addCase(updateConfig.rejected, (state, action) => { 
    state.updateStatus = STATUS.FAILED;
    console.log(STATUS.FAILED)
  })

  .addCase(getConfigsData.pending, (state) => {
    state.getConfigDataStatus = STATUS.PENDING
  })
  .addCase(getConfigsData.fulfilled,(state, action:any)=> {
    state.configData = action.payload
    state.getConfigDataStatus = STATUS.SUCESS
  })
  .addCase(getConfigsData.rejected, (state) => {
    state.getConfigDataStatus = STATUS.FAILED
  })
};

export default ExtraReducers;
