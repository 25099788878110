import { ActionReducerMapBuilder, current } from '@reduxjs/toolkit';
import { STATUS } from '../../utils/status';
import { TaskCompletedState } from '../interfaces/interface';
import { getCompletedTask, updateCompletedTask } from '../slices/taskCompleted';

const ExtraReducers = (builder: ActionReducerMapBuilder<TaskCompletedState>) => {
  builder
  .addCase(getCompletedTask.pending, (state) => {
    state.getStatus = STATUS.PENDING;
  })
  .addCase(getCompletedTask.fulfilled, (state, action) => {
    state.getStatus = STATUS.SUCESS;
    state.taskCompleted = action.payload;
  })
  .addCase(getCompletedTask.rejected, (state, action) => {
    state.getStatus = STATUS.FAILED;
  })
  .addCase(updateCompletedTask.pending, (state) => {
    state.updateCompletedTaskStatus = STATUS.PENDING;
  })
  .addCase(updateCompletedTask.fulfilled, (state, action) => {
    state.updateCompletedTaskStatus = STATUS.SUCESS;
    const action_id = action.payload.payload.action_id
    const response_id = action.payload.payload.response_id
    const data = action.payload.data.data
    console.log({data, action_id})
    let currentResponseIndex = state.taskCompleted.results.findIndex(result => result.tasks[0].responses[0].id === response_id)
    let currentTask = state.taskCompleted.results[currentResponseIndex].tasks[0].responses[0].actions.find((action:any) => action.id === action_id)
    let currentTaskIndex = state.taskCompleted.results[currentResponseIndex].tasks[0].responses[0].actions.findIndex((action:any) => action.id === action_id)
    currentTask = {
      ...currentTask,
      id: data.id,
      action_value : currentTask.action_value.map((av:any) => {
        return {
          ...av,
          ...data.action_value
        }
      })
    }
    state.taskCompleted.results[currentResponseIndex].tasks[0].responses[0].actions[currentTaskIndex] = currentTask
    // state.taskCompleted = action.payload;
  })
  .addCase(updateCompletedTask.rejected, (state, action) => {
    state.updateCompletedTaskStatus = STATUS.FAILED;
  });
};

export default ExtraReducers;
