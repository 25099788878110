import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store/appStore';
import { STATUS } from '../../utils/status';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { getExpert } from '../../redux/slices/expertSlice/addExpertSlice';
import { SelectChangeEvent,Typography } from '@mui/material';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  paginate: (pageNumber: number) => void;
  itemsPerPage: number;
}

const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages, paginate, itemsPerPage }) => {

  const experts = useSelector((state: RootState) => state.experts.experts);
  const getStatus = useSelector((state: RootState) => state.experts.getStatus);
  return (
    <>
    <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ color: '#10383D', fontFamily: 'Montserrat', fontSize: '14px', fontWeight: '400', lineHeight: '24px' }}>
            {getStatus === STATUS.SUCESS ? (currentPage - 1) * itemsPerPage + 1 : '-'} to{' '}
            {getStatus === STATUS.SUCESS
              ? Math.min(currentPage * itemsPerPage, experts.count || 0)
              : '-'}{' '}
            of {getStatus === STATUS.SUCESS ? (experts.count || 0) : '-'}
          </Typography>
            </div>
                <Button
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                  sx={{ '&:hover': { backgroundColor: 'transparent' } }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ChevronLeftIcon
                      sx={{
                        color: 'black',
                        backgroundColor: currentPage === 1 ? '#F3F5F7' : '#E8EBEE',
                        width: '32px',
                        height: '32px',
                      }}
                    />
                  </div>
                </Button>
                <Button
                  onClick={() => paginate(currentPage + 1)}
                  disabled={currentPage == totalPages}
                  sx={{ '&:hover': { backgroundColor: 'transparent' } }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ChevronRightIcon
                      sx={{
                        color: 'black',
                        backgroundColor: !experts.results || experts.results.length < itemsPerPage ? '#F3F5F7' : '#E8EBEE',
                        width: '32px',
                        height: '32px',
                      }}
                    />
                  </div>
                </Button>
              </div>
    </>
  )
}

export default Pagination