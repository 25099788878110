import React, { useState } from 'react';
import { Typography, FormControlLabel, Checkbox, CircularProgress } from '@mui/material';
import { AddButton } from '../button/button';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store/appStore';
import { STATUS } from '../../utils/status';
import { useParams } from 'react-router-dom';
import { updateCompletedTask } from '../../redux/slices/taskCompleted';
import { setCheckboxEdited } from '../../redux/reducers/isEditTaskCompleted';

interface RadioActionProps {
    actionValue: any[];
    actionIndex: number;
    action: string;
    result: any;
    projectStatus: string;
}

export default function CheckboxAction({ action, actionValue, actionIndex, result, projectStatus }: RadioActionProps) {
    const isProjectStatusCompleted = projectStatus && projectStatus === 'COMPLETED'
    const [selectedOptions, setSelectedOptions] = useState(actionValue);
    const [selectedValues, setSelectedValues] = useState<any[]>(actionValue);
    const [currentConfig, setcurrentConfig] = useState<any>(undefined);
    const [isEdited, setIsEdited] = useState(false);
    const { id } = useParams();
    const dispatch = useDispatch<AppDispatch>();
    const { updateCompletedTaskStatus } = useSelector((state: RootState) => state.taskCompleted);
    const fetchPermissions = useSelector((state: RootState) => state.projects.permissions)

    if (actionValue?.length === 0) {
        return <Typography>No options available.</Typography>;
    }

    const isChecked = (selected: any[], optionIndex: number) => {
        return selected.some((sel: any) => sel.index === optionIndex);
    };

    const onChange = (valueIndex: number, optionIndex: number, optionValue: string, checked: boolean) => {
        if (isProjectStatusCompleted) return;
        const updatedOptions = selectedOptions.map((option) => ({
            ...option,
            selected: [...(option.selected || [])],
        }));

        const selected = updatedOptions[valueIndex].selected || [];

        if (checked) {
            if (!selected.some((sel: any) => sel.index === optionIndex)) {
                selected.push({ index: optionIndex, value: optionValue });
            }
        } else {
            const optionIndexToRemove = selected.findIndex((sel: any) => sel.index === optionIndex);
            if (optionIndexToRemove > -1) {
                selected.splice(optionIndexToRemove, 1);
            }
        }

        updatedOptions[valueIndex].selected = selected;
        setSelectedOptions(updatedOptions);
        setSelectedValues([...updatedOptions]);
        setIsEdited(true);
        dispatch(setCheckboxEdited(true));
    };

    const handleSave = () => {
        if (isProjectStatusCompleted) return;
        setcurrentConfig(actionIndex);
        const payload = {
            project_id: id,
            task_id: result.tasks[0].task_id,
            allocation_id: result.tasks[0].task_allocation_id,
            response_id: result.tasks[0].responses[0].id,
            action_id: actionIndex,
            action: selectedValues[0].config_details.name,
            action_value: {
                id: selectedValues[0].id,
                selected: selectedValues[0].selected,
            },
        };
        dispatch(updateCompletedTask(payload));
        setIsEdited(false);
        dispatch(setCheckboxEdited(false));
    };

    return (
        <>
            {selectedOptions.map((value: any, valueIndex: number) => (
                <div
                    key={valueIndex}
                    style={{ display: 'flex', marginBottom: '10px', alignItems: 'center' }}
                >
                    <Typography sx={{ fontWeight: 'bold', marginRight: '20px' }}>
                        {value.config_details?.value?.label} : 
                    </Typography>

                    {value.selected?.length === 0 ? (
                        <Typography sx={{ fontStyle: 'italic', color: 'gray' }}>
                            No selection made.
                        </Typography>
                    ) : (
                        <div>
                            {value.config_details?.value?.value?.map((option: any) => (
                                fetchPermissions?.completing_task_action_details?.[1] === "read" ? (
                                    <FormControlLabel
                                        key={option.index}
                                        value={option.value}
                                        disabled
                                        control={
                                            <Checkbox
                                                checked={isChecked(value.selected, option.index)}
                                                onChange={(event) =>
                                                    onChange(valueIndex, option.index, option.value, event.target.checked)
                                                }
                                            />
                                        }
                                        label={option.value}
                                    />
                                ) : (
                                    <FormControlLabel
                                        key={option.index}
                                        value={option.value}
                                        control={
                                            <Checkbox
                                                checked={isChecked(value.selected, option.index)}
                                                onChange={(event) =>
                                                    onChange(valueIndex, option.index, option.value, event.target.checked)
                                                }
                                            />
                                        }
                                        label={option.value}
                                    />)
                            ))}
                        </div>
                    )}
                </div>
            ))}
            {!isProjectStatusCompleted && isEdited && (
                fetchPermissions?.completing_task_action_details?.[1] === "read" ?
                    (<></>)
                    : (
                        <AddButton
                            startIcon={
                                actionIndex === currentConfig && updateCompletedTaskStatus === STATUS.PENDING ? (
                                    <CircularProgress />
                                ) : null
                            }
                            disabled={actionIndex === currentConfig && updateCompletedTaskStatus === STATUS.PENDING}
                            onClick={handleSave}
                        >
                            Save
                        </AddButton>)

            )}
        </>
    );
}
