import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as XLSX from 'xlsx';

interface SheetData {
  email: string;
  count: number;
}

interface FileState {
  fileUpload: File | null;
  sheetsUpload: SheetData[] | null; 
}

const initialState: FileState = {
  fileUpload: null,
  sheetsUpload: null,
};

export const uploadFileType2 = createAsyncThunk(
  'fileAllocationUpload/uploadFile',
  async (file: File, { rejectWithValue }) => {
    try {
      return file;
    } catch (error) {
      return rejectWithValue('Failed to upload file');
    }
  }
);

export const readSheets = createAsyncThunk(
  'fileAllocationUpload/readSheets',
  async (file: File, { rejectWithValue }) => {
    try {
      return new Promise<any[]>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const binaryStr = e.target?.result;
          if (typeof binaryStr === 'string') {
            const workbook = XLSX.read(binaryStr, { type: 'binary' });
            const sheetDataArray = workbook.SheetNames.map(sheetName => {
              const worksheet = workbook.Sheets[sheetName];
              const sheetData = XLSX.utils.sheet_to_json<any[][]>(worksheet, { header: 1 });
              return { name: sheetName, data: sheetData };
            });
            resolve(sheetDataArray);
          } else {
            reject('Failed to read file content');
          }
        };
        reader.onerror = () => reject('File reading error');
        reader.readAsBinaryString(file);
      });
    } catch (error) {
      return rejectWithValue('Failed to read sheets');
    }
  }
);

const uploadSheetType2 = createSlice({
  name: 'uploadSheet',
  initialState,
  reducers: {
    clearFileType2: (state) => {
      state.fileUpload = null;
      state.sheetsUpload = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadFileType2.pending, (state) => {
      })
      .addCase(uploadFileType2.fulfilled, (state, action: PayloadAction<File>) => {
        state.fileUpload = action.payload;
      })
      .addCase(uploadFileType2.rejected, (state, action) => {
        console.log("error in slice upload sheet type 2")
      })
      .addCase(readSheets.pending, (state) => {
      })
      .addCase(readSheets.fulfilled, (state, action: PayloadAction<any[]>) => {
        
         state.sheetsUpload = action.payload;
      })
      .addCase(readSheets.rejected, (state, action) => {
        console.log("error in slice upload sheet type 2")
      });
  },
});

export const { clearFileType2 } = uploadSheetType2.actions;
export default uploadSheetType2.reducer;
