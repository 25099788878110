import React, { useEffect } from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store/appStore';
import { setTasksWithAllocation, setTasksWithoutAllocation } from '../../redux/reducers/allocationReducer';
import { STATUS } from '../../utils/status';

interface InfoModalProps {
  open: boolean;
  onClose: () => void;
  selectedIds?: { id: string; task_name: string }[];
  onNextClick: () => void;
}

const InfoModal: React.FC<InfoModalProps> = ({
  open,
  onClose,
  selectedIds = [],
  onNextClick,
}) => {
  const marginTop = selectedIds.length === 0 ? '2%' : '2%';
  const dispatch = useDispatch<AppDispatch>();
  const upload = useSelector((state: RootState) => state.upload.upload);
  const selectedTaskIds = useSelector((state: RootState) => state.tasks.selectedIds);
  const selectedTasksWithAllocation = useSelector((state: RootState) => state.allocationTask.tasksWithAllocation);
  const selectedTasksWithoutAllocation = useSelector((state: RootState) => state.allocationTask.tasksWithoutAllocation);

  
  useEffect(() => {
    if (upload) {
      if (upload.results) {
        const tasksWithAllocation = upload.results.filter(
          (task) => task.task_allocations && task.task_allocations.length > 0
        );

        const tasksWithoutAllocation = upload.results.filter(
          (task) => !tasksWithAllocation.includes(task)
        );

        const tasksWithAlloc = selectedTaskIds.filter(
          (selectedTask) => tasksWithAllocation.some(task => task.id === selectedTask.id)
        );

        const tasksWithoutAlloc = selectedTaskIds.filter(
          (selectedTask) => tasksWithoutAllocation.some(task => task.id === selectedTask.id)
        );

        dispatch(setTasksWithAllocation(tasksWithAlloc));
        dispatch(setTasksWithoutAllocation(tasksWithoutAlloc));
      }
    }
  }, [upload, selectedTaskIds, dispatch]);

  return (
          <>
            <Modal
              open={open}
              onClose={onClose}
              aria-labelledby="modal-title"
              aria-describedby="modal-description"
            >
              <Box
                sx={{
                  p: 2,
                  maxWidth: 600,
                  maxHeight: 400,
                  overflow: 'hidden',
                  backgroundColor: 'white',
                  borderRadius: '8px',
                  margin: 'auto',
                  marginTop: marginTop,
                  position: 'absolute',
                  top: 200,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {selectedTasksWithoutAllocation.length === 0 ? (
                  <>
                    <Typography
                      sx={{
                        textAlign: 'center',
                        fontSize: '18px',
                        fontFamily: 'Montserrat',
                        color: '#888888',
                        p: 2,
                        mt: '20%',
                      }}
                    >
                      All tasks that you have selected are already allocated.<br />
                      Please select a task which has no allocation.
                    </Typography>
                  </>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                    }}
                  >
                    <Box
                      sx={{
                        position: 'sticky',
                        top: 0,
                        backgroundColor: 'white',
                        zIndex: 1,
                        p: 2,
                        borderBottom: '1px solid #E0E0E0',
                      }}
                    >

                      <Typography
                        id="modal-title"
                        sx={{ color: 'black', fontWeight: '400', fontSize: '24px' }}
                      >
                        Unallocated Tasks: {selectedTasksWithoutAllocation.length}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        flex: 1,
                        overflowY: 'auto',
                        p: 2,
                        pt: 0,
                      }}
                    >

                      <Box
                        sx={{
                          flex: 1,
                          overflowY: 'auto',
                          p: 0,
                          pt: 0,
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: 'Montserrat',
                            fontWeight: '600',
                            fontSize: '18px',
                            color: '#000000',
                            textTransform: 'none',
                            marginTop: '20px'
                          }}>
                          {`You have selected ${selectedTasksWithAllocation.length + selectedTasksWithoutAllocation.length} tasks to allocate, with ${selectedTasksWithAllocation.length} already allocated. Currently, a task can only be allocated to a single tasker.`}
                        </Typography>

                        <Typography
                          sx={{
                            fontFamily: 'Montserrat',
                            fontWeight: '600',
                            fontSize: '14px',
                            color: '#10383D',
                            textTransform: 'none',
                            marginTop: '40px'
                          }}>
                          {`Would you like to proceed with allocating the remaining ${selectedTasksWithoutAllocation.length} unallocated tasks? Click "Next" to choose taskers.`}
                        </Typography>
                      </Box>

                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '10px',
                        p: 2,
                        borderTop: '1px solid #E0E0E0',
                      }}
                    >
                      <Button
                        sx={{
                          minWidth: '66px',
                          minHeight: '40px',
                          backgroundColor: '#F7F9FA',
                          padding: '8px 16px',
                          gap: '4px',
                          ml: '80%',
                          borderRadius: '8px',
                          '&:hover': { backgroundColor: '#E0E0E0' },
                        }}
                        onClick={onClose}
                      >
                        <Typography
                          sx={{
                            fontFamily: 'Montserrat',
                            fontWeight: '600',
                            fontSize: '14px',
                            color: '#10383D',
                            textTransform: 'none',
                          }}
                        >
                          Cancel
                        </Typography>
                      </Button>
                      <Button
                        sx={{
                          minWidth: '66px',
                          minHeight: '40px',
                          backgroundColor: '#3D85C6',
                          padding: '8px 16px',
                          gap: '4px',
                          borderRadius: '8px',
                          '&:hover': { backgroundColor: '#3D85C6' },
                        }}
                        onClick={onNextClick}
                      >
                        <Typography
                          sx={{
                            fontFamily: 'Montserrat',
                            fontWeight: '600',
                            fontSize: '14px',
                            color: '#FFFFFF',
                            textTransform: 'none',
                          }}
                        >
                          Next
                        </Typography>
                      </Button>
                    </Box>
                  </Box>
                )}
              </Box>
            </Modal>
          </>
  );
};

export default InfoModal;
