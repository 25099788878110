import React, { useEffect, useMemo, useState } from 'react';
import { Autocomplete, Checkbox, CircularProgress, FormControl, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { createConfig, getConfigCategoryList, setCreateConfigStatus, updateConfig } from '../../redux/slices/config';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store/appStore';
import { AddButton } from '../button/button';
import { STATUS } from '../../utils/status';
import SnackbarNotification from '../snackbar/snackbarNotification';
import TaskReviewToggle from './taskReviewToggle';
import debounce from 'lodash.debounce';

type DictTypeContentProps = {
  chipId: string;
  chipLabel: string;
  keys: string[] | null | undefined;
  handleInputChange: (key: string, value: string) => void;
  taskCategory: string;
  interactiveConfig: boolean;
};

const DictTypeContent: React.FC<DictTypeContentProps> = ({
  chipId,
  chipLabel,
  keys,
  handleInputChange,
  taskCategory,
  interactiveConfig = false,
}) => {
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const [fieldValues, setFieldValues] = useState<{ [key: string]: string }>({});
  const createStatus = useSelector((state: RootState) => state.config.createStatus);
  const updateStatus = useSelector((state: RootState) => state.config.updateStatus);
  const configValue = useSelector((state: RootState) => state.config.currentConfig);
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState<any>({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning'>('success');
  const [selectedOption, setSelectedOption] = useState<'TASK' | 'REVIEW'>('TASK');
  const [order, setOrder] = useState('');
  const [localIsChecked, setLocalIsChecked] = useState<boolean>(false);
  const [grammerCheck, setGrammerCheck] = useState<boolean>(false);
  const [configType, setConfigType] = useState<'DYNAMIC' | 'STATIC'>('STATIC');
  const [open, setOpen] = React.useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [options, setOptions] = useState<any[]>([]);
  const [loading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<any>(null);
  const categoryList = useSelector((state: RootState) => state.config.configCategories)
  const getConfigCategpryListStatus = useSelector((state: RootState) => state.config.getConfigCategoryListStatus)

  useEffect(() => {
    if (interactiveConfig === false) {
      dispatch(getConfigCategoryList({ project_id: id }));
    }
  }, [interactiveConfig, chipId, id])

  const handleOrderChange = (e: any) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === '' || regex.test(e.target.value)) {
      setOrder(e.target.value);
    }
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setLocalIsChecked(checked);
  };

  const handleGrammerCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setGrammerCheck(checked);
  };

  const getConfigFromReducer = useSelector((state: RootState) => state.config.currentConfig);
  const config_id = getConfigFromReducer.id


  useEffect(() => {
    if (getConfigFromReducer && getConfigFromReducer.value) {
      const configValues = getConfigFromReducer.value;
      const configKeys = Object.keys(configValues);
      const initialFieldValues: { [key: string]: string } = {};

      configKeys.forEach((key) => {
        initialFieldValues[key] = configValues[key];
      });

      setFieldValues(initialFieldValues);
      setSelectedOption(getConfigFromReducer.type);
      setOrder(getConfigFromReducer.order);
      setFormValues({ identifier: getConfigFromReducer.identifier });
      setLocalIsChecked(getConfigFromReducer.required);
      setConfigType(getConfigFromReducer.default_value_type || 'STATIC');
      setSelectedCategory(getConfigFromReducer.default_value)
      setGrammerCheck(getConfigFromReducer.sapling)
    }
  }, [getConfigFromReducer]);

  

  const handleSnackbar = (message: string, severity: 'success' | 'error' | 'info' | 'warning') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const debouncedResults = useMemo(() => { return debounce(handleSearchChange, 300); }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  useEffect(() => {
    if (getConfigCategpryListStatus === STATUS.SUCESS) {
      setOptions(categoryList)
    }
  }, [getConfigCategpryListStatus])

  const handleSubmit = () => {
    const initialValues = {
      ...(config_id && { id: config_id }),
      project_id: id,
      task_category_id: taskCategory,
      config_id: chipId,
      name: chipLabel,
      value: fieldValues,
      type: selectedOption,
      identifier: formValues.identifier,
      ...(order && Number(order) !== 0 && { order: Number(order) }),
      required: localIsChecked,
      sapling: grammerCheck,
      ...(!interactiveConfig && { default_value_type: configType }),
      ...(!interactiveConfig && { default_value: selectedCategory?.key })
    };

    // const newUpdateValues = Object.keys(updateValues).reduce((acc: any, key: any) => {
    //   if (updateValues[key] !== initialValues[key]) {
    //     acc[key] = updateValues[key]; 
    //   }
    //   return acc;
    // }, {});

    const newUpdateValues = Object.keys(initialValues).reduce((acc, key) => {
      const fetchedValue = configValue[key];
      const newValue = initialValues[key];
      if (
        key in configValue &&
        newValue !== fetchedValue &&
        (fetchedValue !== '' || newValue !== undefined) &&
        newValue !== ''
      ) {
        acc[key] = newValue;
      }

      return acc;
    }, {} as Record<string, string | boolean | string[] | undefined>);

    if (config_id) {
      // dispatch(updateConfig(updateValues));
      dispatch(updateConfig({ id: config_id, ...newUpdateValues }));
    } else {
      dispatch(createConfig(initialValues));
    }
  };


  useEffect(() => {
    const handleCreateStatus = () => {
      if (createStatus === STATUS.SUCESS) {
        handleSnackbar('Config Added Successfully', 'success');
        setTimeout(() => {
          navigate(`/project/${id}`);
        }, 1000);
      }
      if (createStatus === STATUS.FAILED) {
        handleSnackbar('error occured please try again', 'warning');
      }
    }
    handleCreateStatus();
    return () => {
      if (createStatus === STATUS.SUCESS) {
        dispatch(setCreateConfigStatus(STATUS.NOT_STARTED));
      }
    };
  }, [createStatus]);

  useEffect(() => {
    const handleUpdateStatus = () => {
      if (updateStatus === STATUS.SUCESS) {
        handleSnackbar('Config Updated Successfully', 'success');
        setTimeout(() => {
          navigate(`/project/${id}`);
        }, 1000);
      }

      if (updateStatus === STATUS.FAILED) {
        handleSnackbar('Error Occurred, Please try again!', 'error');
      }
    };

    handleUpdateStatus();
    // return () => {
    //   if(updateStatus == STATUS.SUCESS || updateStatus == STATUS.FAILED) {
    //     dispatch(setUpdateConfigStatus(STATUS.NOT_STARTED));
    //   }
    // };
  }, [updateStatus, navigate, dispatch]);

  if (!keys) {
    return null;
  }

  const handleFieldChange = (key: string, value: string) => {
    setFieldValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
    handleInputChange(key, value);
  };

  const handleOptionChange = (
    event: React.MouseEvent<HTMLElement>,
    newOption: 'TASK' | 'REVIEW'
  ) => {
    if (newOption !== null) {
      setSelectedOption(newOption);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfigType(event.target.value as 'DYNAMIC' | 'STATIC');
  };

  const handleAutocompleteInput = (e: any) => {
    debouncedResults(e)
  };

  return (
    <>
      <div style={{ display: 'flex' }}>
        <Typography sx={{ fontFamily: 'Montserrat', fontSize: 20, fontWeight: 600, lineHeight: '24px', color: '#10383D', marginTop: 2, marginBottom: 2, textTransform: 'capitalize', }}>
          {chipLabel.replace(/_/g, ' ')}
        </Typography>
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '20px' }}>

        <div style={{ flex: 1 }}>
          <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '5px' }}>
            Enter Name:<br />
          </Typography>
          <Typography sx={{ fontFamily: 'Montserrat', fontSize: '12px', fontWeight: 400, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}> This Name will identify unique configs </Typography>
        </div>

        <div style={{ flex: 3 }} >
          <TextField
            placeholder="Enter Name"
            name="formValues"
            value={formValues.identifier}
            onChange={(e) => setFormValues({ identifier: e.target.value })}
            sx={{ marginTop: '10px' }}
            size="small"
          />
        </div>

      </div>

      <div style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '20px' }}>

        <div style={{ flex: 1 }}>
          <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '5px' }}>
            Enter Value
          </Typography>
          <Typography sx={{ fontFamily: 'Montserrat', fontSize: '12px', fontWeight: 400, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}> This heading will be visible on expert side </Typography>
        </div>

        <div style={{ flex: 3, display: 'flex', flexWrap: 'wrap', marginTop: '10px' }}>
          {keys.map((key, index) => (
            <div style={{ marginRight: '20px' }} key={index}>
              <TextField
                size='small'
                placeholder={key}
                variant="outlined"
                fullWidth
                value={fieldValues[key] || ''}
                onChange={(e) => handleFieldChange(key, e.target.value)}
                style={{ marginBottom: '10px' }}
              />
            </div>
          ))}
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '20px' }}>

        <div style={{ flex: 1 }} >
          <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '5px' }}>
            Order:<br />
          </Typography>
          <Typography sx={{ fontFamily: 'Montserrat', fontSize: '12px', fontWeight: 400, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}> This Order will arrange config on expert side </Typography>
        </div>

        <div style={{ flex: 3 }} >
          <TextField
            placeholder="Enter Order"
            name="order"
            value={order}
            onChange={handleOrderChange}
            sx={{ marginTop: '10px' }}
            size="small"
          />
        </div>
      </div>

      {!interactiveConfig &&
        (<>
          <div style={{ display: 'flex' }}>
            <FormControl component="fieldset">
              <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '5px' }}>
                Config Type:
              </Typography>
              <RadioGroup row value={configType} onChange={handleTypeChange}>
                <FormControlLabel value="DYNAMIC" control={<Radio />} label="Dynamic" />
                <FormControlLabel value="STATIC" control={<Radio />} label="Static" />
              </RadioGroup>
            </FormControl>
          </div>

      {configType === 'STATIC' && (
        <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          marginTop: "20px",
        }}
      >
        <Typography sx={{ marginTop: '2px', fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}>Default Content : </Typography>
        <TextField
          placeholder='Enter content or link here...'
          size='small'
          sx={{ width: 300, marginLeft:'20px', marginBottom:'20px' }}
          value={configValue.default_value}
          onChange={(event) => {
            if (event) {
              setSelectedCategory(event.target.value); 
            } else {
              setSelectedCategory(null);
            }
          }}
        />
        </div>
      )}

          {configType === 'DYNAMIC' && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                marginTop: "20px",
              }}
            >
              <Typography sx={{ marginTop: '2px', fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}>Select Category: </Typography>
              <Autocomplete
                sx={{ width: 950, marginLeft: '20px', marginBottom: '20px' }}
                open={open}
                onOpen={handleOpen}
                onClose={handleClose}
                autoSelect
                value={selectedCategory}
                getOptionLabel={(option) => option.value}
                options={options}
                loading={getConfigCategpryListStatus === STATUS.PENDING}
                onChange={(event, value) => {
                  if (value) {
                    setSelectedCategory(value);
                  } else {
                    setSelectedCategory(null);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Category"
                    size="small"
                    sx={{ width: '30%' }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                    onChange={(e) => { handleAutocompleteInput(e) }}
                  />
                )}
              />
            </div>
          )}
        </>)
      }

      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <div style={{ flex: 1 }} >
          <Typography
            sx={{
              fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px',
              textAlign: 'left', color: '#10383D', marginTop: '15px', marginRight: '30px'
            }}
          >
            Choose Type:
          </Typography>
        </div>
        <div style={{ flex: 7 }} >
          <TaskReviewToggle
            selectedOption={selectedOption}
            onChange={handleOptionChange}
          />
        </div>
      </div>

      {chipLabel === 'content' &&
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <div style={{ flex: 1 }} >
            <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '15px' }}>
              Do you want to check for grammatical errors?
            </Typography>
          </div>
          <div style={{ flex: 3 }} >
            <Checkbox
              checked={grammerCheck}
              onChange={handleGrammerCheckboxChange}
              color="primary"
              sx={{ marginTop: '5px' }}
            />
          </div>
        </div>
      }

      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <div style={{ flex: 1 }} >
          <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '15px' }}>
            Do you want to mark it required?
          </Typography>
        </div>
        <div style={{ flex: 3 }} >
          <Checkbox
            checked={localIsChecked}
            onChange={handleCheckboxChange}
            color="primary"
            sx={{ marginTop: '5px' }}
          />
        </div>
      </div>
      <br />
      <AddButton
        disabled={config_id ? updateStatus == STATUS.PENDING : createStatus == STATUS.PENDING}
        startIcon={config_id ? updateStatus == STATUS.PENDING : createStatus == STATUS.PENDING ? <CircularProgress size={20} /> : null}
        onClick={handleSubmit}>{config_id ? "Update" : "Submit"}
      </AddButton>
      <SnackbarNotification
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
    </>
  );
};

export default DictTypeContent;
