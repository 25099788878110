import { Box } from '@mui/material';
import TaskDeliveredFileUpload from './taskDeliveredFileUpload';

const UploadDeliveredTask = () => {
  return (
    <Box>
      <div
            style={{
              borderBottom: '1px solid',
              borderColor: '#E8EAEF',
              margin: 2,
              padding: '0px, 24px, 0px, 24px',
            }}
          > 
            <TaskDeliveredFileUpload/>
          </div>
    </Box>
  );
};

export default UploadDeliveredTask;
