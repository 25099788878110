import React from 'react';
import { Typography, Tooltip } from '@mui/material';

interface Label {
    start: number;
    end: number;
    label: string;
    background: string;
    tag: string;
    color: string;
    tokens: string[];
}

interface LabelActionProps {
    actionValue: {
        labels: Label[];
    }[];
}

const removeHash = (color: string) => color.replace(/^##/, '#');

const HighlightedText = ({ text, tokens, color }: { text: string; tokens: string[]; color: string }) => {
    let adjustedColor = color
    let regex = new RegExp(``)
    let highlightedText = ''
    if(color) {
        adjustedColor = removeHash(color);
    } else {
        adjustedColor = color
    }
    if(tokens) {
        regex = new RegExp(`(${tokens.join('|')})`, 'gi');
    }
    if(text) {
        highlightedText = text.replace(regex, (match) => `<span style="background-color:${adjustedColor};">${match}</span>`);
    }
    const parts = highlightedText.split(/(<span[^>]*>.*?<\/span>)/g);

    let result = '';
    let inSpan = false;
    parts.forEach(part => {
        if (/^<span/.test(part)) {
            if (!inSpan) {
                inSpan = true;
                result += '<span style="background-color:' + adjustedColor + ';">';
            }
            result += part.replace(/<\/span>$/, ''); // Remove closing span tag if it's ending a continuous span
        } else {
            if (inSpan) {
                inSpan = false;
                result += '</span>';
            }
            result += part;
        }
    });
    if (inSpan) result += '</span>'; // Close any open span tag

    return <span dangerouslySetInnerHTML={{ __html: result }} />;
};

export default function LabelAction({ actionValue }: LabelActionProps) {
    if (actionValue?.length === 0) {
        return <Typography>No label available.</Typography>;
    }

    return (
        <>
            {actionValue.map((value, valueIndex) => (
                <div key={valueIndex} style={{ marginBottom: '20px' }}>
                    <Typography sx={{ fontWeight: 'bold', marginBottom: '5px' }}>Labelled Text :</Typography>
                    {value.labels.map((label, labelIndex) => (
                        
                        <div key={labelIndex} style={{display:'flex'}}>
                            
                            <Tooltip title={label.tag} placement='bottom'>
                                <Typography variant="body2">
                                    <HighlightedText
                                        text={label.label}
                                        tokens={label.tokens}
                                        color={(label.color)}
                                    />
                                </Typography>
                            </Tooltip>
                        </div>
                    ))}
                </div>
            ))}
        </>
    );
}
