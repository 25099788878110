import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { STATUS } from '../../utils/status';
import { ProjectExpertsState } from '../interfaces/interface';
import { getProjectExperts } from '../slices/projectExpert';

const ExtraReducers = (builder: ActionReducerMapBuilder<ProjectExpertsState>) => {
  builder
    .addCase(getProjectExperts.pending, (state) => {
      state.getStatus = STATUS.PENDING;
    })
    .addCase(getProjectExperts.fulfilled, (state, action: PayloadAction<any>) => {
      state.projectExperts = action.payload; 
      state.getStatus = STATUS.SUCESS; 
    })
    .addCase(getProjectExperts.rejected, (state, action) => { 
      state.getStatus = STATUS.FAILED;
    })
};

export default ExtraReducers;
