import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';
import { ProjectExpertsState, UploadPayload } from '../../interfaces/interface';
import extraReducers from '../../extraReducers/projectExpertExtraReducer';

const initialState: ProjectExpertsState = {
  projectExperts: {
    count: undefined,
    results: []
  },
  getStatus: STATUS.NOT_STARTED,
  
};

export const getProjectExperts = createAsyncThunk(
    'project/company/expert/get',
    async (payload: UploadPayload = {
      project_id: ''
    }, thunkAPI) => {
      const { project_id, type, paginate, is_blocked, fields, search_query} = payload;
      const queryString = new URLSearchParams({
        ...(project_id && { project_id }),
        ...(type && { type }),
        ...(paginate && { paginate: paginate.toString() }),
        ...(is_blocked && { is_blocked: is_blocked.toString() }),
        ...(fields && { fields }),
        ...(search_query && { search_query })
      }).toString();
      const response = await api.get(`project/company/expert/?${queryString}`);
      const { status, data } = response;
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue({ response, data });
      }
    }
  );

const projectExpertSlice = createSlice({
  name: 'projectExpert',
  initialState,
  reducers: {},
  extraReducers
});

export default projectExpertSlice.reducer;
