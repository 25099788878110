import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SelectedEmailsReviewState = {
  selectedEmailReview: string[];
};

const initialState: SelectedEmailsReviewState = {
  selectedEmailReview: []
};

const selectedEmailsReviewSlice = createSlice({
  name: 'selectedEmailsReview',
  initialState,
  reducers: {
    addEmails: (state, action: PayloadAction<string>) => {
      state.selectedEmailReview.push(action.payload);
    },
    removeEmails: (state, action: PayloadAction<string>) => {
      state.selectedEmailReview = state.selectedEmailReview.filter(email => email !== action.payload);
    },
    clearEmailss: (state) => {
      state.selectedEmailReview = [];
    }
  }
});

export const { addEmails, removeEmails, clearEmailss } = selectedEmailsReviewSlice.actions;
export default selectedEmailsReviewSlice.reducer;
