import React from 'react';
import { TableRow } from '@mui/material';
import { TableCellBody } from '../table/tableCell';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store/appStore';
import { onBoardDateParts } from '../../utils/helper';

interface TableRowItemProps {
  row: any;
  onEditClick: (row: any) => void;
  onDeleteClick: (userId: string) => void;
  onToggleDisable: (row: any) => void;
}

const formatDate = (dateString: string) => {
  if (!dateString) return '-';
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('en-GB', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  }).format(date);
};


const TableRowItem: React.FC<TableRowItemProps> = ({ row, onEditClick, onDeleteClick, onToggleDisable }) => {
  const { tableActionsEditAccess, tableActionsDeleteAccess, tableActionsDisableAccess } = useSelector((state: RootState) => {
    const tableActions = state.company?.company?.data?.modules?.admin?.table?.table_actions || [];
    return {
      tableActionsEditAccess: tableActions.includes('edit'),
      tableActionsDeleteAccess: tableActions.includes('delete'),
      tableActionsDisableAccess: tableActions.includes('disable'),
    };
  });

  const { name, role, email, phone, last_login_time, actions } = useSelector((state: RootState) => {
    const tableActions = state.company?.company?.data?.modules?.admin?.table?.table_fields || [];
    return {
      name: tableActions.includes('name'),
      email: tableActions.includes('email'),
      role: tableActions.includes('role'),
      phone: tableActions.includes('phone'),
      last_login_time: tableActions.includes('last_login_time'),
      actions: tableActions.includes('actions'),
    };
  });

  return (
    <>
    <TableRow
      key={row.id}
      sx={{
        '&:last-child td, &:last-child th': { borderBottom: 0 },
      }}
    >
      {name && (<TableCellBody>{`${row.first_name} ${row.last_name}`}</TableCellBody>)}
      {role && (<TableCellBody>{row.role ? row.role : ''}</TableCellBody>)}
      {email && (<TableCellBody>{row.email}</TableCellBody>)}
      {phone && (<TableCellBody>{row.phone ? row.phone : '-'}</TableCellBody>)}
      {last_login_time && (<TableCellBody>{row.last_login ? onBoardDateParts(formatDate(row.last_login)) : 'Not Login Yet'}
      </TableCellBody>)}
      {actions && (
        <TableCellBody>
        {tableActionsEditAccess && (
          <EditNoteOutlinedIcon
          sx={{ cursor: 'pointer', marginRight: '5px' }}
          onClick={() => onEditClick(row)}
          />
        )}
        
        {tableActionsDeleteAccess && (
          <DeleteOutlineOutlinedIcon
            sx={{ color: '#C00F00', cursor: 'pointer', marginRight: '5px' }}
            onClick={() => onDeleteClick(row.id)}
          />
        )}
        {tableActionsDisableAccess && (
          <DoDisturbIcon
            fontSize="small"
            sx={{
              color: row.is_disabled ? 'grey' : 'red',
              cursor: 'pointer',
              marginRight: '5px',
            }}
            onClick={() => onToggleDisable(row)}
          />
        )}
      </TableCellBody>
      )}
      
    </TableRow>

    </> 
  );
};

export default TableRowItem;
