import { Link } from "react-router-dom";
import logo from "../../assets/img/accunaiLogo.png";

type Props = {
  width?: number;
  text?: "lg" | "xl" | "2xl";
};

export default function Logo({ width, text }: Props) {
  const imgWidth = width || 30;
  const textSize = `text-${text}` || "text-lg";
  return (
    <Link to="/">
      <section className="flex justify-self-start items-center gap-[10px]">
        <span>
          <img src={logo} alt="company logo" width={imgWidth} />
        </span>
        <span className={textSize + " text-[#3D85C6] font-sora font-semibold"}>
          Accunai
        </span>
      </section>
    </Link>
  );
}
