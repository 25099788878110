
export const onBoardDateParts = (utcTime: string) => {
    // Parse the UTC date string into a Date object

    // Regular expression to match the format "13th August 2024, 02:56 PM"
    const dateFormatRegex = /^\d{1,2}(st|nd|rd|th)?\s\w+\s\d{4},\s\d{2}:\d{2}\s(AM|PM)$/;
    let utcDateParts = undefined

    // Check if the utcTime matches the desired format
    if (dateFormatRegex.test(utcTime)) {
        utcDateParts = utcTime.split(" ");
        // return utcDateParts; // or perform other operations
    } else {
        console.log("The date is in ISO format or another format.");
        // return null; // Or handle the ISO date format
    }
    
    const months: any = {
      January: 0,
      February: 1,
      March: 2,
      April: 3,
      May: 4,
      June: 5,
      July: 6,
      August: 7,
      September: 8,
      October: 9,
      November: 10,
      December: 11,
    };

    if(utcDateParts !== undefined) {
        const [day, month, year, time] = utcDateParts;
        const [hours, minutes, period] = time.split(":");
        
        const utcDate = new Date(
          Date.UTC(
            parseInt(year),
            months[month],
            parseInt(day),
            parseInt(hours),
            parseInt(minutes),
            0
          )
        );
      
        // Convert to local time
        const localDate = new Date(utcDate);
      
        // Format the local date as desired (you can customize this)
        const options: object = {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        };
      
        const formattedLocalDate = localDate.toLocaleDateString("en-US", options);
      
        return formattedLocalDate;
    } else {
        return utcTime
    }
  };