import React from "react";
import { Typography } from "@mui/material";

interface VideoShowActionProps {
    action_value?: any;
}

export default function VideoShowAction({ action_value }: VideoShowActionProps) {
    return (
        <>
        {action_value && action_value.map((action: any, index: number) => (

                <div key={index} style={{ display: 'flex', marginBottom: '10px', flexDirection:'column' }}>
                    <Typography sx={{ fontWeight: 'bold', marginRight: '20px' }}>{action.config_details.value.title}: </Typography>
                    {action.config_details.default_value ? (
                        <video controls className="w-full h-[70vh]">
                            <source src={action.config_details.default_value} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    ) : (
                        <p>Video not available.</p>
                    )}
                </div>
        ))}
        </>
    );
}
