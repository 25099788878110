import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';
import { ExpertTypeState, GetPayload } from '../../interfaces/interface';
import extraReducers from '../../extraReducers/expertExtraReducers';

interface Tasker {
  id: number;
  email: string;
}

const initialState: ExpertTypeState = {
  results: null,
  experts: { 
    count: undefined,
    results: [] ,
  },
  error: null,
  createStatus: STATUS.NOT_STARTED,
  deleteStatus: STATUS.NOT_STARTED,
  updateStatus: STATUS.NOT_STARTED,
  getStatus: STATUS.NOT_STARTED,
  createMultipleStatus: STATUS.NOT_STARTED,
  reviewers: [],
  taskers: [],
  getCompanyExpertsStatus: STATUS.NOT_STARTED,
  getReviewerStatus: STATUS.NOT_STARTED,
  getTaskersStatus: STATUS.NOT_STARTED
};

export const createExpert = createAsyncThunk(
  'company/expert/POST',
  async (payload: any, thunkAPI) => {
    try {
      const response = await api.post('company/expert/', payload);
      const { status, data } = response;
      
      if (status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue({ response, data });
      }
    } catch (error:any) {
      if (error.response) {
        const { status, data } = error.response;
        let errorMessage = `Request failed with status ${status}`;
        if (data && data.message) {
          errorMessage += `: ${data.message}`;
          console.log({errorMessage})
        }
        return thunkAPI.rejectWithValue({ message: errorMessage });
      } else if (error.request) {
        return thunkAPI.rejectWithValue({ message: 'No response received' });
      } else {
        return thunkAPI.rejectWithValue({ message: error.message });
      }
    }
  }
);


export const createMultipleExpert = createAsyncThunk(
  'company/expert/multiple/POST',
  async (payload: any, thunkAPI) => {
      const response = await api.post('company/expert/multiple/', payload);
      const { status, data } = response; 
      if (status === 201) {
        return data;
      } else {
        return thunkAPI.rejectWithValue({ response, data });
      }
  }
);


export const getExpert = createAsyncThunk(
  'company/expert/get',
  async (payload: GetPayload = {}, thunkAPI) => {
    const { search, page, pageSize, sort, order, type, is_blocked } = payload;
    const queryString = new URLSearchParams(
      {
        ...(is_blocked !== undefined && { is_blocked: is_blocked.toString() }), // Convert boolean to string
        ...(type && { type }),
        ...(search && { search }),
        ...(page && { page: page.toString() }),
        ...(pageSize && { page_size: pageSize.toString() }),
        ...(sort && { sort }),
        ...(order && { order }),
      }
    ).toString();
    
    const response = await api.get(`company/expert/?${queryString}`);
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

export const getReviewers = createAsyncThunk<Tasker[], { search: string, projectId: string }>(
  'company/reviewers/get',
  async ({ search = '', projectId }, thunkAPI) => {
    try {
      const params = new URLSearchParams();
      if (search) params.append('search', search);
      params.append('paginate', 'false');
      params.append('project_id', projectId);
      params.append('is_blocked', 'false');

      const response = await api.get(`company/expert`, { params });
      const reviewers: Tasker[] = response.data.map((result: any) => ({
        id: result.expert_details.id,
        email: result.expert_details.email,
      }));
      return reviewers;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getTaskers = createAsyncThunk<Tasker[], { search?: string, projectId: string }>(
  'company/taskers/get',
  async ({ search = '', projectId }, thunkAPI) => {
    const params = new URLSearchParams();
    if (search) params.append('search', search);
    params.append('paginate', 'false');
    params.append('project_id', projectId);
    params.append('is_blocked', 'false');

    const response = await api.get(`company/expert`, { params });

    const {status, data } = response

    if(status === 200) {
      return data.map((result: any) => ({
        id: result.expert_details.id,
        email: result.expert_details.email,
      }));
    } else {
      return thunkAPI.rejectWithValue({response, data});
    }
  }
);

export const updateExpert = createAsyncThunk(
  'company/expert/patch',
  async (payload: any, thunkAPI) => {
    const response = await api.patch(`company/expert/`, payload);
    const { status, data } = response;
    if (status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
);

const addExpertSlice = createSlice({
  name: 'experts',
  initialState,
  reducers: {
    setCreateExpertsStatus(state,action){
      state.createStatus = action.payload
    },
    setUpdateExpertsStatus(state,action){
      state.updateStatus = action.payload
    },
    setCreateMultipleExpertsStatus(state,action){
      state.createMultipleStatus = action.payload
    }
  },
  extraReducers
});

export const {setCreateExpertsStatus, setUpdateExpertsStatus, setCreateMultipleExpertsStatus} = addExpertSlice.actions
export default addExpertSlice.reducer;
