import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { STATUS } from '../../utils/status';
import { AnalyticsCompletedState } from '../interfaces/interface';
import { getCompletedAnalytics } from '../slices/analytics/completed';

const ExtraReducers = (builder: ActionReducerMapBuilder<AnalyticsCompletedState>) => {
  builder
  .addCase(getCompletedAnalytics.pending, (state) => {
    state.getStatus = STATUS.PENDING;
  })
  .addCase(getCompletedAnalytics.fulfilled, (state, action) => {
    state.getStatus = STATUS.SUCESS;
    state.analyticsCompleted = action.payload;
  })
  .addCase(getCompletedAnalytics.rejected, (state, action) => {
    state.getStatus = STATUS.FAILED;
  });
};

export default ExtraReducers;
