import { signInWithPopup, getAuth, GoogleAuthProvider } from "firebase/auth";
import { app } from "./firebase";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signIn } from "../../redux/slices/login/singInSlice";
import { getCookie, setCookie } from "./cookies";
import { useEffect, useState } from "react";
import RegisterPopup from "./RegisterPopup";
import google from "../../assets/img/googleIcon.png";
import { getAuthTokenFromLocalStorage } from "../../utils/api";
import { BASE_URL } from "../../utils/constants";
import SnackbarNotification from "../snackbar/snackbarNotification";
import { RootState } from "../../redux/store/appStore";

export default function OAuth() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning'>('success');
  const [googleFormURL, setGoogleFormURL] = useState<string>(
    "https://accunai.com/",
  );
  const email = useSelector((state: RootState) => state.company.company.data.email)
  const role = useSelector((state: RootState) => state.company.company.data.role)

  const isDashboardAllowed = (email.includes("accunai") || email.includes("firstsource")) && role === "ADMIN";

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleSnackbar = (message: string, severity: 'success' | 'error' | 'info' | 'warning') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const provider = new GoogleAuthProvider();
  const auth = getAuth(app);

  async function handleGoogleClick() {
    try {
      const result = await signInWithPopup(auth, provider);
  
      if (result.user) {
        const user = {
          name: result.user.displayName,
          photo: result.user.photoURL,
        };
        dispatch(signIn(user));
  
        const resToken = await result.user.getIdToken();
  
        const data = await fetch(BASE_URL + "auth/company/user/login/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: resToken,
          }),
        });
  
        const res = await data.json();
        if (res["authentication-token"]) {
          setCookie("token", res["authentication-token"], 3);
          setCookie("username", result.user.displayName || "user", 3);
          navigate("/projects");
        }else {
          setGoogleFormURL(res?.url);
          //setModalOpen(true);
          handleSnackbar('You are blocked by Company, you can not access this platform.', 'warning');
        }
      }
    } catch (err:any) {
      console.log("Error logging in with Google:", err);
      alert(`Error: ${err.message}`);
    }
  }
  

  useEffect(() => {
    const isAuthenticated = getCookie("token") !== null;
    if (isAuthenticated) {
      window.location.href = '/projects';
    }
  }, []);


  const handleClick = () => {
    window.open('https://app.accunai.com', '_blank');
  };

  return (
    <>
      {isModalOpen && (
        <RegisterPopup
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          url={googleFormURL}
        />
      )}
      <button
        onClick={handleGoogleClick}
        className="flex items-center gap-4 px-8 bg-white border-[1px] border-neutral-300 text-neutral-800 p-2 text-lg rounded-lg font-poppins"
        style={{fontSize: "17px"}}
      >
        <img src={google} alt="Google Logo" style={{paddingLeft:'85px'}}/>
        Continue with Google
      </button>
      <SnackbarNotification
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
      {/* <h1 className="text-sm ml-4">If you're looking for expert Login <button className="text-blue-500" onClick={handleClick}>Click here</button> </h1> */}
    </>
  );
}
