import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store/appStore';
import { STATUS } from '../../utils/status';
import { CircularProgress, TableHead } from '@mui/material';
import { useStyles } from '../../assets/styles/listTableStyle';

import Error from '../error/error';
import { TableCellHead } from '../table/tableCell';
import UnallocationTableRowItem from './unallocationTableRowItem';

const VendorBulkUnallocationTable = () => {
    const classes = useStyles();
    const analyseData = useSelector((state: RootState) => state.vendorList.analyse);
    // const createAnalyseStatus = useSelector((state: RootState) => state.vendorList.createAnalyseStatus)
    const unallocateAnalyseStatus = useSelector((state: RootState) => state.vendorList.unallocateAnalyseStatus)

    const headers = ["VENDOR NAME", "TOTAL AFTER THIS UNALLOCATED", "TOTAL TASKS ALLOCATED TILL NOW", "TOTAL ALLOCATED TASKS TO EXPERTS BY THIS VENDOR"]

    return (
        <>
            <TableContainer sx={{ backgroundColor: 'white', minHeight: '200px' }}>
                {unallocateAnalyseStatus === STATUS.PENDING && (
                    <div className={classes.progressDiv}>
                        <CircularProgress />
                    </div>
                )}
                {unallocateAnalyseStatus === STATUS.SUCESS && (
                    <>
                        <Table className={classes.tableContainer} aria-label="simple table">
                            <TableHead>
                                {headers.map((header) => (
                                    <TableCellHead key={header}>{header}</TableCellHead>
                                ))}
                            </TableHead>
                            <TableBody>
                                {analyseData && analyseData.length > 0 ? (
                                    analyseData.map((row: any) => (
                                        <>
                                            <UnallocationTableRowItem
                                                key={row.id}
                                                row={row}
                                            />
                                        </>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={6} className={classes.noUsersMessage}>
                                            <Error message={'There are no users added as of yet. You can add a user to see it here.'} />
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>

                    </>
                )}
            </TableContainer>
        </>
    );

}

export default VendorBulkUnallocationTable;