import React from 'react';
import { TableRow } from '@mui/material';
import { TableCellBody } from '../../table/tableCell';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';

interface TableRowItemProps {
    row: any;
    handleClick: (row: any) => void;
    onDeleteClick: (userId: string) => void;
    onToggleDisable: (row: any) => void;
}

const TableRowItem: React.FC<TableRowItemProps> = ({ row, onDeleteClick, onToggleDisable, handleClick }) => {

    return (
        <TableRow
            key={row.id}
        >
            <TableCellBody>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} onClick={() => handleClick(row)}>
                    <div style={{ flex: 3, overflow: 'hidden', textOverflow: 'ellipsis', cursor: 'pointer' }}>
                        {row.vendor_name ? row.vendor_name : '-'}
                    </div>
                    <div style={{ flex: 1 }}>
                        {<KeyboardArrowRightIcon fontSize='small' style={{ cursor: "pointer" }} />}
                    </div>
                </div>
            </TableCellBody>
            <TableCellBody>{row.vendor_pseudo_name ?? row.pseudo_name ?? '-'}</TableCellBody>
            <TableCellBody>
                <DeleteOutlineOutlinedIcon
                    sx={{ color: '#C00F00', cursor: 'pointer', marginRight: '5px' }}
                    onClick={() => onDeleteClick(row.id)}
                />
                <DoDisturbIcon
                    fontSize="small"
                    sx={{
                        color: row.is_blocked ? 'grey' : 'red',
                        cursor: 'pointer',
                        marginRight: '5px',
                    }}
                    onClick={() => onToggleDisable(row)}
                />
            </TableCellBody>
        </TableRow>
    );
};

export default TableRowItem;
