import React, { useEffect, useState } from 'react';
import { Checkbox, CircularProgress, TextField, Typography } from '@mui/material';
import TaskReviewToggle from './taskReviewToggle';
import { AddButton } from '../button/button';
import SnackbarNotification from '../snackbar/snackbarNotification';
import { AppDispatch, RootState } from '../../redux/store/appStore';
import { STATUS } from '../../utils/status';
import { useDispatch, useSelector } from 'react-redux';
import { createConfig, setCreateConfigStatus, setUpdateConfigStatus, updateConfig } from '../../redux/slices/config';
import { useNavigate, useParams } from 'react-router-dom';
import { useStyles } from '../../assets/styles/configStyle';
interface StringTypeContentProps {
  chipLabel: string;
  chipId: string;
  inputValue: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  taskCategory: string;
}

const StringTypeContent: React.FC<StringTypeContentProps> = ({
  chipLabel,
  chipId,
  inputValue,
  handleChange,
  taskCategory
}) => {
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const classes = useStyles();
  const createStatus = useSelector((state: RootState) => state.config.createStatus);
  const updateStatus = useSelector((state: RootState) => state.config.updateStatus);
  const stringTypeData = useSelector((state: RootState) => state.config.currentConfig)
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({ identifier:'' });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning'>('success');
  const [selectedOption, setSelectedOption] = useState<'TASK' | 'REVIEW'>('TASK');
  const getConfig = useSelector((state: RootState) => state.config.currentConfig);
  const [order, setOrder] = useState('');
  const [localIsChecked, setLocalIsChecked] = useState<boolean>(false);

  const handleOrderChange = (e: any) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === '' || regex.test(e.target.value)) {
      setOrder(e.target.value);
    }
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setLocalIsChecked(checked);
  };

  const [localInputValue, setLocalInputValue] = useState<string>(inputValue);

  useEffect(() => {
    setLocalInputValue(inputValue);
  }, [inputValue]);

  useEffect(() => {
    if (getConfig) {
      setLocalInputValue(getConfig.value);
      setSelectedOption(getConfig.type);
      setOrder(getConfig.order);
      setLocalIsChecked(getConfig);
      setFormValues({identifier:getConfig.identifier});
    }
  }, [getConfig]);

  const handleSnackbar = (message: string, severity: 'success' | 'error' | 'info' | 'warning') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSubmit = () => {
    const payload = {
      ...(getConfig.id && { id: getConfig.id }),
      project_id: id,
      task_category_id: taskCategory,
      config_id: chipId,
      name: chipLabel,
      value: localInputValue,
      type: selectedOption,
      identifier: formValues.identifier,
      ...(order && Number(order) !== 0 && { order: Number(order) }),
      required: localIsChecked
    };

    const newUpdateValues = Object.keys(payload).reduce((acc, key) => {
      const fetchedValue = stringTypeData[key];
      const newValue = payload[key];
      if (
        key in stringTypeData &&
        newValue !== fetchedValue &&
        (fetchedValue !== '' || newValue !== undefined) &&
        newValue !== ''
      ) {
        acc[key] = newValue;
      }
    
      return acc;
    }, {} as Record<string, string | boolean | string[] | undefined>);

    if (getConfig.id) {
      dispatch(updateConfig({id: getConfig.id, ...newUpdateValues}));
    } else {
      dispatch(createConfig(payload));
    }
  };

  useEffect(() => {

    // const handleCreateStatus = () => {
    if (createStatus === STATUS.SUCESS) {
      handleSnackbar('Config Added Successfully', 'success');
      // setTimeout(() => {
      console.log({ navigate })
      console.log('i am herwerwerwer')
      navigate(`/project/${id}`);
      // }, 1000);
    }
    if (createStatus === STATUS.FAILED) {
      handleSnackbar('error occured please try again', 'warning');
    }
    // }
    // handleCreateStatus();

    return () => {
      if(createStatus === STATUS.SUCESS){
        dispatch(setCreateConfigStatus(STATUS.NOT_STARTED));
      }
    };

  }, [createStatus]);

  useEffect(() => {
    const handleUpdateStatus = () => {
      if (updateStatus === STATUS.SUCESS) {
        handleSnackbar('Config Updated Successfully', 'success');
        setTimeout(() => {
          navigate(`/project/${id}`);
        }, 1000);
      }

      if (updateStatus === STATUS.FAILED) {
        handleSnackbar('Error Occurred, Please try again!', 'error');
      }
    };

    handleUpdateStatus();
    return () => {
      if(updateStatus === STATUS.SUCESS){
        dispatch(setUpdateConfigStatus(STATUS.NOT_STARTED));
      }
    };
  }, [updateStatus, navigate, dispatch]);

  const handleOptionChange = (
    event: React.MouseEvent<HTMLElement>,
    newOption: 'TASK' | 'REVIEW'
  ) => {
    if (newOption !== null) {
      setSelectedOption(newOption);
    }
  };

  return (
    <>
      <div style={{ display: 'flex' }}>
        <Typography sx={{ fontFamily: 'Montserrat', fontSize: 20, fontWeight: 600, lineHeight: '24px', color: '#10383D', marginTop: 2, marginBottom: 2, textTransform: 'capitalize', }}>
          {chipLabel.replace(/_/g, ' ')}
        </Typography>
      </div>
      <div style={{ display: 'flex', marginBottom: '20px' }}>
        <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '5px' }}>
          Enter Name:<br />
          <Typography sx={{ fontFamily: 'Montserrat', fontSize: '12px', fontWeight: 400, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}> This Name will identify unique configs </Typography>
        </Typography>
        <TextField
          placeholder="Enter Name"
          name="formValues"
          value={formValues.identifier}
          onChange={(e) => setFormValues({ identifier: e.target.value })}
          sx={{ marginLeft: '70px', marginTop: '10px' }}
          size="small"
        />
      </div>
      <div style={{ display: 'flex', marginBottom: '20px' }}>
        <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '5px' }}>Enter Value:<br />
          <Typography sx={{ fontFamily: 'Montserrat', fontSize: '12px', fontWeight: 400, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}> This Title will be visible on expert side </Typography>
        </Typography>
        <TextField
          placeholder={`Enter ${chipLabel}`}
          name="phone"
          value={localInputValue}
          onChange={handleChange}
          sx={{ marginLeft: '50px', marginTop: '10px' }}
          size='small'
        />
      </div>
      <div style={{ display: 'flex', marginBottom: '20px' }}>
        <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '5px' }}>
          Order:<br />
          <Typography sx={{ fontFamily: 'Montserrat', fontSize: '12px', fontWeight: 400, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}> This Order will arrange config on expert side </Typography>
        </Typography>
        <TextField
          placeholder="Enter Order"
          name="order"
          value={order}
          onChange={handleOrderChange}
          sx={{ marginLeft: '35px', marginTop: '10px' }}
          size="small"
        />
      </div>
      <div style={{ display: 'flex' }}>
        <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '15px' }}>
          Do you want to mark it required?
        </Typography>
        <Checkbox
          checked={localIsChecked}
          onChange={handleCheckboxChange}
          color="primary"
          sx={{ marginTop: '5px' }}
        />
      </div>
      <div style={{ display: 'flex' }}>
        <Typography
          sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '25px', marginRight: '22px' }}
        >
          Choose Type:
        </Typography>
        <TaskReviewToggle
          selectedOption={selectedOption}
          onChange={handleOptionChange}
        />
      </div>
      <AddButton 
        className={classes.addButton}  
        disabled={getConfig.id? updateStatus == STATUS.PENDING : createStatus == STATUS.PENDING} 
        startIcon={getConfig.id? updateStatus == STATUS.PENDING : createStatus == STATUS.PENDING ? <CircularProgress size={20} /> : null} 
        onClick={handleSubmit}>{getConfig.id ? "Update" : "Submit"}
      </AddButton>
      <SnackbarNotification
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
    </>
  );
};

export default StringTypeContent;
