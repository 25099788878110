import React from 'react';
import { Select, MenuItem, Typography, FormControl, FormHelperText } from '@mui/material';
import { SelectChangeEvent } from '@mui/material';

interface Option {
  value: string;
  label: string;
  coming_soon

?: boolean;
}

interface UserRoleSelectProps {
  label: string;
  value?: string;
  defaultValue?: string;
  onChange: (event: SelectChangeEvent<string>) => void;
  options: Option[];
  error?: string;
  typographySx?: React.CSSProperties; 
  textFieldSx?: React.CSSProperties; 
}

export const UserRoleSelect: React.FC<UserRoleSelectProps> = ({ label, value, defaultValue, onChange, options, error,textFieldSx,typographySx }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '30.5%' }}>
      <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', ...typographySx }}>
        {label}
      </Typography>
      <FormControl error={!!error}>
        <Select
          value={value ?? defaultValue ?? ''}
          onChange={onChange}
          size="small"
          displayEmpty
          sx={textFieldSx}
        >
          <MenuItem value="" disabled>
            <Typography>Select {label}</Typography>
          </MenuItem>
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <span>{option.label}</span>
                {option.coming_soon && (
                  <span style={{
                    backgroundColor: '#3D85C6',
                    color: 'white',
                    borderRadius: '4px',
                    padding: '2px 8px',
                    marginLeft: '10px',
                    fontSize: '0.75rem'
                  }}>
                    Coming Soon


                  </span>
                )}
              </div>
            </MenuItem>
          ))}
        </Select>
        {error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    </div>
  );
};
