import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store/appStore';
import { STATUS } from '../../utils/status';
import { TableCellHead } from '../table/tableCell';
import { useStyles } from '../../assets/styles/listTableStyle';
import Error from '../error/error';
import TableRowItem from './expertAnalyticsTableRow';
import { useParams } from 'react-router-dom';
import { getExpertAnalytics } from '../../redux/slices/analytics/experts';
import Pagination from './analyticsExpertPagination';
import SwapVertIcon from '@mui/icons-material/SwapVert';

const AnalyticsListTable = ({tableSwitch}:any) => {
  const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const analytics = useSelector((state:RootState)=> state.analyticsExpert.analyticsExpert)
  const getStatus = useSelector((state: RootState) => state.analyticsExpert.getStatus);
  const [currentPageTasker, setCurrentPageTasker] = useState(1)
  const [currentPageReviewer, setCurrentPageReviewer] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [sortColumn, setSortColumn] = useState<string>('name');
  const [sortOrder, setSortOrder] = useState<string>('asc');
  
  
  const {id} = useParams();
  const association_type = useSelector((state: RootState) => state.projects.association_type);

  const handleSort = (column: string) => {
    const formattedHeader = column.toLowerCase().replace(/\s+/g, '_');
    const newOrder = sortColumn === formattedHeader && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortColumn(formattedHeader);
    setSortOrder(newOrder);
  };


  useEffect(()=>{
    dispatch(getExpertAnalytics({
      project_id: id,
      page: tableSwitch === "TASK" ? currentPageTasker : currentPageReviewer, 
      pageSize: itemsPerPage, 
      sort_by: sortColumn, 
      sort_order: sortOrder, 
      type: tableSwitch,
      association_type: association_type
    }))
   },[dispatch, id, currentPageTasker, currentPageReviewer, itemsPerPage, sortColumn, sortOrder, tableSwitch])



   console.log(analytics.count)
   const totalPages = Math.ceil((analytics.count || 0) / itemsPerPage);

   const handlePageTasker = (pageNumber: number) => {
    setCurrentPageTasker(pageNumber);

  };
  const handlePageReviewer = (pageNumber: number) => {
    setCurrentPageReviewer(pageNumber);
  }

  console.log(currentPageReviewer)
  console.log(currentPageTasker)


  const headers = ["NAME", "EMAIL", "PSEUDO NAME", "TOTAL ALLOCATED", "ACTIVE TASKS", "TOTAL COMPLETED", "TOTAL QUALITY ACCEPTED", "TOTAL DELIVERED", "NOT STARTED TASKS", "TOTAL REJECTED", "TOTAL AVAILABLE TO REVIEW", "AVERAGE TIME TO COMPLETE", "LAST TASK TIME" ];

  

  return (
      <>
      <TableContainer>
        {getStatus === STATUS.PENDING && (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <CircularProgress />
          </div>
        )}
        {getStatus === STATUS.SUCESS && (
          <Table sx={{ minWidth: 650 }} aria-label="simple table"> 
            <TableHead sx={{ backgroundColor: '#F7F9FA', '&:first-child td, &:first-child th': { borderBottom: 0 } }}>
              <TableRow>
                {headers.map((header) => (
                   <TableCell align="left" onClick={() => handleSort(header)} sx={{ fontSize: '11px', fontWeight: 700, lineHeight: '14px'}} key={header}>
                     {header}  <SwapVertIcon fontSize='small' sx={{marginLeft:'1px', cursor:'pointer'}}/>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              
            {getStatus === STATUS.SUCESS && analytics.results && analytics.results.length > 0 ? (
            <>
              {analytics.results.map((row: any) => (
                <>
                <TableRowItem
                  key={row.id}
                  row={row}
                />
                </>
              ))}
            </>
          ) : (
            <TableRow>
              <TableCell colSpan={8} className={classes.noUsersMessage}>
                <Error message={'There is no data added as of yet.'} />
              </TableCell>
            </TableRow>
          )}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <Pagination
          currentPage= {tableSwitch === "TASK" ? currentPageTasker : currentPageReviewer}
          totalPages={totalPages}
          paginate={tableSwitch === "TASK" ? handlePageTasker : handlePageReviewer}
          itemsPerPage={itemsPerPage} 
        />
    </>
  );
};

export default AnalyticsListTable;
