import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import useStyles from '../../assets/styles/style';
import UploadDeliveredTask from './uploadDeliveredTask';
import DeliveredTaskUploadHeading from './taskDeliveredHeading';

const DeliveredTaskUploadMain = () => {
  const classes = useStyles(); 
  return (
    <div className={classes.formMainContainer}>
      <Box className={classes.formMainHeadingBox}>
        <DeliveredTaskUploadHeading/>
      </Box>
      <Box component="main" className={classes.formMainBox}>
        <Paper className={classes.formMainpaper}>
          <UploadDeliveredTask />
        </Paper>
      </Box>
    </div>
  );
};

export default DeliveredTaskUploadMain;
