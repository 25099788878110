import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Container } from '@mui/material';

interface UploadSheetsPreviewProps {
    uploadSheets: any;
}

const UploadSheetsPreview: React.FC<UploadSheetsPreviewProps> = ({ uploadSheets }) => {
    const [previewTabIndex] = React.useState(0);
    

    return (
        <Container maxWidth="md" sx={{
            background: 'linear-gradient(135deg, #4a90e2, #add8e6)',
            borderRadius: '10px',
            padding: '20px',
            marginTop: '20px',
            boxShadow: 3,
        }}>
            {uploadSheets.length > 0 && (
                <>
                    <div style={{
                        border: '2px dashed #4a90e2',
                        borderRadius: '10px',
                        padding: '20px',
                        backgroundColor: '#ffffff',
                        width: '100%',
                        textAlign: 'center',
                        cursor: 'pointer'
                    }} >
                        <TableContainer component={Paper} >
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {uploadSheets[previewTabIndex]?.data[0]?.map((header: string, index: number) => (
                                            <TableCell key={index}>{header}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {uploadSheets[previewTabIndex]?.data.slice(1).map((row: any, rowIndex: number) => (
                                        <TableRow key={rowIndex}>
                                            {row.map((cell: any, cellIndex: number) => (
                                                <TableCell key={cellIndex}>{cell}</TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </>
            )}
        </Container>
    );
};

export default UploadSheetsPreview;
