import { Modal } from "@mui/material";
import Card from "./Card";

type Props = {
  isOpen: boolean;
  url: string;
  onClose: () => void;
};

export default function RegisterPopup({ isOpen, onClose, url}: Props) {
  const redirectToURL = () => {
    if(!!url){
        window.open(url, "_blank");
    }else{
        window.open("https://accunai.com/", "_blank");
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className="grid place-items-center h-screen">
        <div className="max-w-lg mx-auto">
          <Card>
            <div className="font-poppins">
              <section className="text-sec-text text-lg text-center font-semibold mb-8 pb-6 border-b-[1px] border-neutral-300 flex justify-between ">
                <div className="items-center ">
                  Welcome! 🎉 Ready to join our community?
                  <span className="font-normal">
                    {" "}
                    Simply fill out the form below, and our team will get in
                    touch with you promptly. Thank you for choosing us!{" "}
                  </span>
                </div>
                <div className="w-6 h-6 flex justify-end" onClick={onClose}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="items-end cursor-pointer"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18 18 6M6 6l12 12"
                    />
                  </svg>
                </div>
              </section>

              <section>
                <img src="/Ink.svg" />
              </section>
              <section className="flex flex-col items-center mt-4">
                <p className="text-lg text-sec-text">Register Here:</p>
              </section>
              <section className="flex justify-center mt-2">
                <button
                  onClick={redirectToURL}
                  className="px-3 py-2 rounded-lg text-white bg-pri-text"
                >
                  Fill Form Now
                </button>
              </section>
              <p className="text-sm text-center mt-4">
                Can't wait to have you onboard! If you have any questions,
                feel free to reach out to us at{" "}
                <span className="text-blue-600"> hiring@accunai.com </span>
              </p>
            </div>
          </Card>
        </div>
      </div>
    </Modal>
  );
}
