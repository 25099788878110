import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import useStyles from '../../assets/styles/style';
import TaskUploadHeading from '../../components/uploadTask/taskUploadHeading';
import UploadTask from '../../components/uploadTask/uploadTask';

const TaskUploadMain = () => {
  const classes = useStyles(); 
  return (
    <div className={classes.formMainContainer}>
      <Box className={classes.formMainHeadingBox}>
        <TaskUploadHeading />
      </Box>
      <Box component="main" className={classes.formMainBox}>
        <Paper className={classes.formMainpaper}>
          <UploadTask />
        </Paper>
      </Box>
    </div>
  );
};

export default TaskUploadMain;
