import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';

type SelectedEmailsState = {
  selectedEmail: string[];
};

const initialState: SelectedEmailsState = {
  selectedEmail: []
};

const selectedEmailsSlice = createSlice({
  name: 'selectedEmails',
  initialState,
  reducers: {
    addEmail: (state, action: PayloadAction<string>) => {
      state.selectedEmail.push(action.payload);
    },
    removeEmail: (state, action: PayloadAction<string>) => {
      state.selectedEmail =  state.selectedEmail.filter(email => email !== action.payload);
    },
    clearEmails: (state) => {
      state.selectedEmail = [];
    }
  }
});

export const { addEmail, removeEmail, clearEmails } = selectedEmailsSlice.actions;
export default selectedEmailsSlice.reducer;
