import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import UserHeading from '../../components/users/userHeading';
import UserForm from '../../components/users/userForm';
import useStyles from '../../assets/styles/style';
import { Divider, Typography } from '@mui/material';

const UserMain = () => {
  const classes = useStyles(); 
  return (
    <div className={classes.formMainContainer}>
      <Box className={classes.formMainHeadingBox}>
        <UserHeading />
      </Box>
      <Divider />
      <Box component="main" className={classes.formMainBox} style={{marginTop: "0px"}}>
      
        <Paper className={classes.formMainpaper}>
          <UserForm />
        </Paper>
      </Box>
    </div>
  );
};

export default UserMain;
