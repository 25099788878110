// export const BASE_URL  = process.env.REACT_APP_BASE_URL;    
export const BASE_URL  = "https://api.accunai.com/";    
// export const BASE_URL  = "http://localhost:8000/";    

export const colors = [
  "#1B2631", // Dark Slate Blue
  "#4B0082", // Indigo
  "#FF4500", // Dark Orange Red
  "#006400", // Dark Green
  "#8B008B", // Dark Magenta
  "#483D8B", // Dark Slate Blue
  "#2F4F4F", // Dark Slate Gray
  "#8B0000", // Dark Red
  "#556B2F", // Dark Olive Green
  "#191970", // Midnight Blue
  "#9400D3", // Dark Violet
  "#008080", // Teal
  "#D2691E", // Chocolate (dark)
  "#808000", // Olive
  "#B22222", // Firebrick
  "#5F9EA0", // Cadet Blue
  "#6A5ACD", // Slate Blue
  "#2E8B57", // Sea Green
  "#8B4513", // Saddle Brown
  "#00008B"  // Dark Blue
];
