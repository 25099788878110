import React from 'react'
import { Button, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import Error from '../error/error';
import { STATUS } from '../../utils/status';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/store/appStore';
import { useStyles } from '../../assets/styles/listTableStyle';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getVendorWiseAnalyticsAction } from '../../redux/slices/dashboard/dashboard';
import VendorListTableRow from './VendorListTableRow';
// import { ActiveProjectPagination } from '../Active Project List/ActiveProjectsList';

const ActiveProjectVendorList = ({data, dataStatus, currentPage, setCurrentPage, itemsPerPage}: any) => {

  const headers = ["PSEUDO NAME", "TOTAL TASKER ONBOARDED ON PROJECT", "TOTAL TASK ALLOCATION", "PERCENT COMPLETED TASK", "NUMBER OF TASK WITH NO REVIEW", "NUMBER OF TASK NOT STARTED", "NUMBER OF TASK COMPLETED", "NUMBER OF QUALITY REJECTED TASK", "NUMBER OF QUALITY ACCEPTED TASK", "NUMBER OF DELIVERED TASK", "NUMBER OF ACTIVE TASK", "AVG TASK COMPLETION PER DAY"]
  const totalPages = Math.ceil((data.count || 0) / itemsPerPage);
  const dispatch = useDispatch<AppDispatch>();
  const classes = useStyles();
  const { id } = useParams()

  useEffect(() => {
    dispatch(getVendorWiseAnalyticsAction({ page: currentPage, pageSize: itemsPerPage as number, association_type: "VENDOR" }));
  }, [dispatch, id, currentPage, itemsPerPage])

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  console.log({data})
// const vendorData = (data)
// console.log({vendorData})

// useEffect(() => {
//   vendorData.map((d: any)=> {
// console.log(d)
//   })
// },[])

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: '#F7F9FA', '&:first-child td, &:first-child th': { borderBottom: 0 } }}>
            <TableRow>
              {headers.map((header) => (
                <TableCell align="left"
                  //  onClick={() => handleSort(header)} sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '14px' }} 
                  key={header}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>

            {dataStatus === STATUS.PENDING ?
              <TableRow>
                <TableCell style={{textAlign:'center'}} colSpan={8}>
                  <CircularProgress size={20} />
                </TableCell>
              </TableRow>
              : dataStatus === STATUS.SUCESS && data && data.length > 0 ? (
                <>
                  {data.map((row: any) => {
                    console.log(row)
                    return(
                      <VendorListTableRow key={row.id} row={row} />
                    )
                  })}

                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={8} className={classes.noUsersMessage}>
                    <Error message={'There is no data added as of yet.'} />
                  </TableCell>
                </TableRow>
              )}
          </TableBody>
        </Table>

      </TableContainer>
      {/* <ActiveProjectPagination
        currentPage={currentPage}
        totalPages={totalPages}
        paginate={paginate}
        itemsPerPage={itemsPerPage}
        data={data}
        dataStatus={dataStatus}
      /> */}
    </>
  )
}

export default ActiveProjectVendorList