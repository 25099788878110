import * as React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Box } from '@mui/material';
import { AddButton } from '../button/button';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store/appStore';
import { useNavigate, useParams } from 'react-router-dom';
import { createBulkAnalyse, setCreateAnalyseStatus } from '../../redux/slices/bulkAllocation';
import { STATUS } from '../../utils/status';
import SnackbarNotification from '../snackbar/snackbarNotification';
import DeleteModal from '../modal/deleteModal';
import { createAnalyseBulkVendor, createBulkVendor, setCreateUnallocateAnalyseStatus, setCreateUnallocateVendorStatus, setCreateVendorStatus, unallocateAnalyseBulkVendor, unallocateBulkVendor } from '../../redux/slices/vendorList';
import VendorUnallocationBody from './vendorUnallocationBody';
import VendorBulkUnallocationTable from './vendorBulkUnallocAnalyseTable';
import VendorUnallocationStepHeading from './vendorUnallocationHeading';
import { Id } from 'react-beautiful-dnd';


export default function VendorUnallocationSteps() {
  const [activeStep, setActiveStep] = useState(0);
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();
  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  const createStatus = useSelector((state: RootState) => state.upload.createBulkAllocationStatus);
  // const createVendorStatus = useSelector((state: RootState)=> state.vendorList.createVendorStatus)
  const vendorUnallocationAnalyseStatus = useSelector((state: RootState) => state.vendorList.createAnalyseStatus)
  const vendorBulkUnallocationStatus = useSelector((state: RootState) => state.vendorList.unallocateBulkVendorStatus)
  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [formDataArray, setFormDataArray] = useState([{ vendor_id: '', task_count: '', type: '', status: '', new_vendor_id: '' }]);
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning'>('success');

  const handleOpenSubmitModal = () => {
    setOpenSubmitModal(true);
  }

  const handleCloseSubmitModal = () => {
    setOpenSubmitModal(false);
  }

  React.useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  React.useEffect(() => {
    window.history.pushState(null, '', window.location.href);

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = '';
    };

    const handleBeforeUnloadDialog = (event: PopStateEvent) => {
      event.preventDefault();
      window.history.pushState(null, '', window.location.href);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('popstate', handleBeforeUnloadDialog);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handleBeforeUnloadDialog);
    };
  }, []);

  const steps = ["Add Vendor", "Verify & Submit"];

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <VendorUnallocationBody formDataArray={formDataArray} setFormDataArray={setFormDataArray} />
        );

      case 1:
        return (
          <VendorBulkUnallocationTable />
        );

      default:
        return null;
    }
  };

  const handleNext = () => {
    if (activeStep !== 2) {
      setActiveStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
    }
    if (activeStep === 0) {
      const payload = {
        project_id: id,
        data: formDataArray
      }
      dispatch(unallocateAnalyseBulkVendor( payload ))
    }
  };

  const handleUnallocate = () => {
    const payload = {
      project_id: id,
      data: formDataArray
    }
    dispatch(unallocateBulkVendor(payload))
    setOpenSubmitModal(false)
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSnackbar = (message: string, severity: 'success' | 'error' | 'info' | 'warning') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };


  React.useEffect(() => {
    if (vendorBulkUnallocationStatus === STATUS.SUCESS) {
      handleSnackbar('Bulk unallocated successfully', 'success');
      setTimeout(() => {
        navigate(`/project/${id}?tab=task`);
      }, 1000);
    }

    if (vendorBulkUnallocationStatus === STATUS.FAILED) {
      handleSnackbar('No Task Allocated', 'error');
    }
    return () => {
      if (vendorBulkUnallocationStatus === STATUS.SUCESS || vendorBulkUnallocationStatus === STATUS.FAILED) {
        dispatch(setCreateUnallocateVendorStatus(STATUS.NOT_STARTED));
      }
    };
  }, [vendorBulkUnallocationStatus, navigate, dispatch]);

  React.useEffect(() => {
    if (vendorUnallocationAnalyseStatus === STATUS.SUCESS) {
      if (activeStep === 0) {
        setActiveStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
      }
    }

    if (vendorUnallocationAnalyseStatus === STATUS.FAILED) {
      handleSnackbar('Error Occured, Please try again!', 'error');
    }
    return () => {
      if (vendorUnallocationAnalyseStatus === STATUS.SUCESS) {
        dispatch(setCreateUnallocateAnalyseStatus(STATUS.NOT_STARTED));
      }
    };
  }, [vendorUnallocationAnalyseStatus, navigate, dispatch]);

  const handleBack = () => {
    setActiveStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  const isNextDisabled = () => {
    return false;
  };

  return (
    <div style={{ width: '100vw', height: '100vh', overflow: 'hidden' }}>
      <section className="fixed top-0 inset-x-0 h-[10px] z-40 drop-shadow bg-[#F7F9FA]" style={{ marginLeft: '4%' }}>
        <VendorUnallocationStepHeading />
      </section>
      <section className="fixed top-0 inset-x-0 h-[88px] z-40 drop-shadow mt-44 ml-52 mb-56">
        <Box sx={{ width: '100%' }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={index}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </section>
      <Box component="main" sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '4rem' }}>
        <section
          style={{
            height: 'calc(100vh - 400px)',
            overflowY: 'auto',
            padding: '12px, 0px, 12px, 0px',
            marginBottom: '15rem',
            marginTop: '14rem',
            flexGrow: 1
          }}
        >
          {renderStepContent(activeStep)}
        </section>
      </Box>
      <section className="fixed bottom-0 inset-x-0 h-[58px] bg-white z-40 drop-shadow">
        <Box
          sx={{
            mt: '0.1rem',
            display: 'flex',
            justifyContent: 'flex-end',
            mr: '0.4rem'
          }}
        >
          {activeStep !== 0 && (
            <AddButton variant="contained" onClick={handleBack}>
              Previous
            </AddButton>
          )}

          <AddButton
            variant="contained"
            onClick={activeStep === steps.length - 1 ? handleOpenSubmitModal : handleNext}
            disabled={isNextDisabled() || formDataArray.some((data) =>
              !data.vendor_id || !data.task_count || !data.type || !data.status
            )}
          >
            {activeStep === steps.length - 1 ? 'Unallocate' : 'Next'}
          </AddButton>
        </Box>
      </section>
      <SnackbarNotification
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
      <DeleteModal
        open={openSubmitModal}
        onCancel={handleCloseSubmitModal}
        onConfirm={handleUnallocate}
        deleteText='Unallocate'
        isButtonLoading={createStatus === STATUS.PENDING}
      />
    </div>
  );
}