import React from 'react';
import { TableRow } from '@mui/material';
import { TableCellBody } from '../table/tableCell';

interface TableRowItemProps {
  row: any;
}

const TableRowItem: React.FC<TableRowItemProps> = ({ row }) => {
  return (
    <TableRow key={row.id}>
      <TableCellBody>{row.Vendor_ID ? row.Vendor_ID : '-'}</TableCellBody>
      <TableCellBody>{row.Pseudo_Name ? row.Pseudo_Name : '-'}</TableCellBody>
      <TableCellBody>{row.Total_Tasker_Onboarded_On_Project ? row.Total_Tasker_Onboarded_On_Project : 0}</TableCellBody>
      <TableCellBody>{row.Total_Task ? row.Total_Task : '-'}</TableCellBody>
      <TableCellBody>{row.Total_Task_Allocated ? row.Total_Task_Allocated : 0}</TableCellBody>
      <TableCellBody>{row.Number_Of_Task_Completed ? row.Number_Of_Task_Completed : 0}</TableCellBody>
      <TableCellBody>{row.Percent_Completed_task ? row.Percent_Completed_task : '-'}</TableCellBody>
      <TableCellBody>{row.Number_Of_Task_Not_Started ? row.Number_Of_Task_Not_Started : 0}</TableCellBody>
      <TableCellBody>{row.Number_Of_Active_Task ? row.Number_Of_Active_Task : 0}</TableCellBody>
      <TableCellBody>{row.Number_Of_Quality_Accepted_Task ? row.Number_Of_Quality_Accepted_Task : 0}</TableCellBody>
      <TableCellBody>{row.Number_Of_Quality_Rejected_Task ? row.Number_Of_Quality_Rejected_Task : 0}</TableCellBody>
      <TableCellBody>{row.Number_Of_Delivered_Task ? row.Number_Of_Delivered_Task : 0}</TableCellBody>
      <TableCellBody>{row.Number_Of_Task_With_No_Review ? row.Number_Of_Task_With_No_Review : 0}</TableCellBody>
      <TableCellBody>{row.Time_Taken_To_Completed ? row.Time_Taken_To_Completed : 0}</TableCellBody>
      <TableCellBody>{row.Avg_Task_Completion_Per_Day ? row.Avg_Task_Completion_Per_Day : 0}</TableCellBody>

    </TableRow>
  );
};

export default TableRowItem;
