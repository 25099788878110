import { Autocomplete, CircularProgress, TextField, Typography } from '@mui/material';
import React from 'react'
import { STATUS } from '../../../utils/status';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store/appStore';

const VendorSearchBar = ({open, handleOpen, handleClose, options, selectedRow, selectedVendor, setVendorId, setSelectedVendor, loading, handleAutocompleteInput}:any) => {

    const getStatus = useSelector((state: RootState) => state.vendors.getVendorsStatus)

    return (
        <>
            <Typography sx={{ marginTop: '10px', fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}>Select Vendor</Typography>
            <Autocomplete
                sx={{ width: 750 }}
                open={open}
                onOpen={handleOpen}
                onClose={handleClose}
                disabled={selectedRow}
                autoSelect
                value={selectedVendor}
                getOptionLabel={(option) =>
                    // option.vendor_pseudo_name ? `${option.vendor_name} (${option.vendor_pseudo_name})` : option.vendor_name
                    option.pseudo_name ? option.pseudo_name : option.vendor_pseudo_name
                }
                options={options}
                loading={getStatus === STATUS.PENDING}
                onChange={(event, value) => {
                    if (value) {
                        setVendorId(value.vendor_id);
                        setSelectedVendor(value);
                    } else {
                        setSelectedVendor(null);
                    }
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder="Select Vendor name"
                        size="small"
                        sx={{ width: '100%' }}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                        onChange={(e) => { handleAutocompleteInput(e) }}
                    />
                )}
            />
        </>
    )
}

export default VendorSearchBar