import React, { useState } from 'react';
import { Typography, CircularProgress, FormControl, Select, MenuItem } from '@mui/material';
import { AddButton } from '../button/button';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store/appStore';
import { updateCompletedTask } from '../../redux/slices/taskCompleted';
import { STATUS } from '../../utils/status';
import { useParams } from 'react-router-dom';
import { setSingleSelectEdited } from '../../redux/reducers/isEditTaskCompleted';

interface SingleSelectActionProps {
    actionValue: any[];
    actionIndex: number;
    result: any;
    action: string;
    projectStatus: string;
    heading?: string;
}

export default function SingleSelectAction({ action, actionValue, actionIndex, result, projectStatus, heading }: SingleSelectActionProps) {
    const isProjectStatusCompleted = projectStatus && projectStatus === 'COMPLETED';
    const [selectedValues, setSelectedValues] = useState<any[]>(actionValue);
    const dispatch = useDispatch<AppDispatch>();
    const { updateCompletedTaskStatus } = useSelector((state: RootState) => state.taskCompleted);
    const fetchPermissions = useSelector((state: RootState) => state.projects.permissions)
    const [currentConfig, setcurrentConfig] = useState<any>(undefined);
    const { id } = useParams();
    const [isEdited, setIsEdited] = useState(false);
    console.log({ isEdited })

    if (actionValue?.length === 0) {
        return <Typography>No radio options available.</Typography>;
    }

    const handleChange = (valueIndex: number, selectedValue: string, optionIndex: number) => {
        if (isProjectStatusCompleted) return;

        const updatedValues = [...selectedValues];
        updatedValues[valueIndex] = {
            ...updatedValues[valueIndex],
            selected: [{ index: optionIndex, value: selectedValue }]
        };
        setSelectedValues(updatedValues);
        setIsEdited(true);
        dispatch(setSingleSelectEdited(true));
    };

    const handleSave = () => {
        if (isProjectStatusCompleted) return;

        setcurrentConfig(actionIndex);
        const payload = {
            project_id: id,
            task_id: result.tasks[0].task_id,
            allocation_id: result.tasks[0].task_allocation_id,
            response_id: result.tasks[0].responses[0].id,
            action_id: actionIndex,
            action: selectedValues[0].config_details.name,
            action_value: {
                id: selectedValues[0].id,
                selected: selectedValues[0].selected
            }
        };
        dispatch(updateCompletedTask(payload));
        setIsEdited(false);
        dispatch(setSingleSelectEdited(false));
    };

    return (
        <>
            {selectedValues.map((value: any, valueIndex: number) => (
                <div
                    key={valueIndex}
                    style={{ display: 'flex', marginBottom: '10px', alignItems: 'center' }}
                >
                    <Typography sx={{ fontWeight: 'bold', marginRight: '20px' }}>
                        {value.config_details?.value?.label}:
                    </Typography>
                    {value.selected?.length === 0 || value.selected?.[0]?.length === 0 ? (
                        <Typography sx={{ fontStyle: 'italic', color: 'gray' }}>
                            No selection made.
                        </Typography>
                    ) : (
                        fetchPermissions?.completing_task_action_details?.[1] === "read" ? (
                            <FormControl fullWidth>
                                <Select
                                    value={value.selected?.[0]?.value || ''}
                                >
                                    {value.config_details?.value?.value?.map((option: { index: number; value: string }) => (
                                        <MenuItem key={option.index} value={option.value}>
                                            {option.value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        ) : (
                            <FormControl fullWidth>
                                <Select
                                    value={value.selected?.[0]?.value || ''}
                                    onChange={(e) => {
                                        const selectedValue = e.target.value;
                                        const optionIndex = value.config_details?.value?.value?.findIndex(
                                            (option: any) => option.value === selectedValue
                                        );
                                        handleChange(valueIndex, selectedValue, optionIndex);
                                    }}
                                >
                                    {value.config_details?.value?.value?.map((option: { index: number; value: string }) => (
                                        <MenuItem key={option.index} value={option.value}>
                                            {option.value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )

                    )}
                </div>
            ))}

            {!isProjectStatusCompleted && isEdited && (
                fetchPermissions.completing_task_action_details?.[1] === "read" ? (
                    <></>
                ) : (
                    <AddButton
                        startIcon={actionIndex === currentConfig && updateCompletedTaskStatus === STATUS.PENDING ? <CircularProgress /> : null}
                        disabled={actionIndex === currentConfig && updateCompletedTaskStatus === STATUS.PENDING}
                        onClick={() => handleSave()}
                    >
                        Save
                    </AddButton>
                )
            )}
        </>
    );
}
