import React, { ChangeEvent } from 'react';
import { TextField, Typography, TextFieldProps } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

interface FormFieldProps {
  label: string;
  placeholder: string;
  name: string;
  value?: string; 
  defaultValue?: string; 
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  error?: string;
  typographySx?: React.CSSProperties; 
  textFieldSx?: React.CSSProperties; 
  InputProps?: TextFieldProps['InputProps']; 
  multiline?: boolean;
  rows?: number;
}

interface SearchBarProps {
  searchTerm?: string;
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

export const FormField: React.FC<FormFieldProps> = ({ label, placeholder, name, value, defaultValue, onChange, error, typographySx, textFieldSx, InputProps, multiline, rows }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '32%' }}>
      <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', ...typographySx }} >{label}</Typography>
      <TextField
        placeholder={placeholder}
        name={name}
        value={value || defaultValue || ''} 
        onChange={onChange}
        size="small"
        InputLabelProps={{
          shrink: false,
        }}
        error={!!error}
        helperText={error}
        sx={textFieldSx}
        InputProps={InputProps}
        multiline={multiline}
        rows={multiline ? rows : undefined}
      />
    </div>
  );
};

export const SearchBar: React.FC<SearchBarProps> = ({onSearchChange, disabled }) => {
  return (
    <TextField
      id="outlined-basic"
      variant="outlined"
      placeholder="Search"
      // value={searchTerm}
      size="small"
      onChange={onSearchChange}
      disabled={disabled}
      InputProps={{
        startAdornment: (
          <SearchIcon color="action" />
        ),
      }}
      InputLabelProps={{
        shrink: false,
      }}
      sx={{ 
        padding: '8px, 16px', 
        border: disabled ? '1px solid' : '1px', 
        borderRadius: '4px', 
        cursor: disabled ? 'not-allowed' : 'pointer',
        gap: '12px', minWidth: '180px', marginBottom: '4px' }}
        />
  );
};
