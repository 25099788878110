import React from 'react';
import { Modal, Box, Typography, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'

interface VideoModalProps {
  open: boolean;
  onClose: () => void;
  onGotItClick: () => void;
}

const VideoModal: React.FC<VideoModalProps> = ({
  open,
  onClose,
  onGotItClick,
}) => {
  return (
    <Modal
  open={open}
  onClose={onClose}
  aria-labelledby="modal-title"
  aria-describedby="modal-description"
>
  <Box
    sx={{
      p: 3,
      maxWidth: 600,
      width: '90%',
      maxHeight: '90vh',
      backgroundColor: 'white',
      borderRadius: '12px',
      margin: 'auto',
      marginTop: '9%',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    }}
  >
    <IconButton
      sx={{ position: 'absolute', top: 10, right: 10 }}
      onClick={onClose}
      aria-label="close"
    >
      <CloseIcon />
    </IconButton>

    <iframe
      width="100%"
      height="315"
      src="https://www.loom.com/embed/47588fb46feb4fda95b2134873a75bf8"
      title="Allocate Video"
      style={{ borderRadius: '8px', marginBottom: '16px' }}
    />

    <Typography
      variant="h6"
      sx={{
        fontFamily: 'Montserrat',
        fontWeight: '500',
        fontSize: '16px',
        color: '#333',
        marginBottom: '16px',
        textAlign: 'center',
      }}
    >
      On average, admins save 15 minutes per project by allocating tasks in bulk. See how it works.
    </Typography>

    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        gap: '10px',
        borderTop: '1px solid #E0E0E0',
        pt: 2,
        mt: 'auto',
      }}
    >
      <Button
        sx={{
          minWidth: '90px',
          backgroundColor: '#F7F9FA',
          padding: '8px 16px',
          borderRadius: '8px',
          '&:hover': { backgroundColor: '#E0E0E0' },
        }}
        onClick={onClose}
      >
        <Typography
          sx={{
            fontFamily: 'Montserrat',
            fontWeight: '600',
            fontSize: '14px',
            color: '#10383D',
            textTransform: 'none',
          }}
        >
          Cancel
        </Typography>
      </Button>

      <Button
        sx={{
          minWidth: '90px',
          backgroundColor: '#3D85C6',
          padding: '8px 16px',
          borderRadius: '8px',
          '&:hover': { backgroundColor: '#3D85C6' },
        }}
        onClick={onGotItClick}
      >
        <Typography
          sx={{
            fontFamily: 'Montserrat',
            fontWeight: '600',
            fontSize: '14px',
            color: '#FFFFFF',
            textTransform: 'none',
          }}
        >
          Got it!
        </Typography>
      </Button>
    </Box>
  </Box>
</Modal>
  );
};

export default VideoModal;
