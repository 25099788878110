import { Autocomplete, CircularProgress, TextField, Typography } from '@mui/material';
import React from 'react'
import { STATUS } from '../../../utils/status';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store/appStore';

interface adminSearchBarProps {
    open: any,

}

const AdminSearchBar = ({open, handleOpen, handleClose, options, selectedRow, selectedAdmin, setAdminId, setSelectedAdmin, loading, handleAutocompleteInput }:any) => {

    const getStatus = useSelector((state: RootState) => state.users.getStatus)

    return (
        <>
            <Typography sx={{ marginTop: '10px', fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}>Select Admin Name</Typography>
            <Autocomplete
                sx={{ width: 750 }}
                open={open}
                onOpen={handleOpen}
                onClose={handleClose}
                autoSelect
                getOptionLabel={(option) =>
                    option.last_name ? `${option.first_name} (${option.last_name}) ${option.email}` : `${option.first_name} (${option.email})`
                }
                options={options}
                disabled={selectedRow}
                value={selectedAdmin}
                loading={getStatus === STATUS.PENDING}
                onChange={(event, value) => {
                    if (value) {
                        setAdminId(value.id);
                        setSelectedAdmin(value);
                    } else {
                        setSelectedAdmin(null);
                    }
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder="Select Admin"
                        size="small"
                        sx={{ width: '100%' }}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                        onChange={handleAutocompleteInput}
                    />
                )}
            />
        </>
    )
}

export default AdminSearchBar