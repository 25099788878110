import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { SelectChangeEvent, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { STATUS } from '../../utils/status';
import { RootState } from '../../redux/store/appStore';
import { useSelector } from 'react-redux';

interface UserPaginationProps {
  currentPage: number;
  totalPages: number;
  paginate: (pageNumber: number) => void;
  itemsPerPage: number;
}

const UserPagination: React.FC<UserPaginationProps> = ({ currentPage, totalPages, paginate, itemsPerPage }) => {

  const getStatus = useSelector((state: RootState) => state.users.getStatus);
  const users = useSelector((state: RootState) => state.users.users);
  
  return (
    <>
      <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ color: '#10383D', fontFamily: 'Montserrat', fontSize: '14px', fontWeight: '400', lineHeight: '24px' }}>
          {getStatus === STATUS.SUCESS ? (currentPage - 1) * itemsPerPage + 1 : '-'} to{' '}
            {getStatus === STATUS.SUCESS
              ? Math.min(currentPage * itemsPerPage, users.count || 0)
              : '-'}{' '}
            of {getStatus === STATUS.SUCESS ? (users.count || 0) : '-'}
          </Typography>
        </div>
        <Button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          sx={{ '&:hover': { backgroundColor: 'transparent' } }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ChevronLeftIcon
              sx={{
                color: 'black',
                backgroundColor: currentPage === 1 ? '#F3F5F7' : '#E8EBEE',
                width: '32px',
                height: '32px',
              }}
            />
          </div>
        </Button>
        <Button
                  onClick={() => paginate(currentPage + 1)}
                  disabled={currentPage == totalPages}
                  sx={{ '&:hover': { backgroundColor: 'transparent' } }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ChevronRightIcon
                      sx={{
                        color: 'black',
                        backgroundColor: !users.results || users.results.length < itemsPerPage ? '#F3F5F7' : '#E8EBEE',
                        width: '32px',
                        height: '32px',
                      }}
                    />
                  </div>
                </Button>
      </div>
    </>
  );
}

export default UserPagination;
