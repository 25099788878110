import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  formHeadingBox: {
    backgroundColor: '#FFFFFF',
    border: '0px 0px 1px 0px',
    borderColor: '#E8EAEF',
    borderRadius: '8px',
    padding: '16px 24px',
    color: '#13282A',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '36px',
    marginTop: '4rem',
    height: '84px',
  },
  backButton: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '18px',
    marginBottom: '5px',
  },
  formHeadingAdd: {
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '36px',
  },
  backIcon: {
    fontSize: 'inherit',
    cursor: 'pointer',
  },
  formContainer: {
    noValidate: true,
    autoComplete: 'off',
  },
  formLabel: {
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#10383D',
  },
  formMainContainer: {
    width: '100vw',
    overflow: 'hidden',
  },
  formMainHeadingBox: {
    flexGrow: 1,
    backgroundColor: '#F7F9FA',
    padding: '0rem',
  },
  formMainBox: {
    flexGrow: 1,
    backgroundColor: '#F7F9FA',
    padding: '2rem',
  },
  formMainpaper: {
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    padding: '1.5rem',
    marginBottom: '2rem',
    boxShadow: '2',
  },
});

export default useStyles;
