import React, { useEffect, useState } from 'react';
import { IconButton, Menu, MenuItem, TableRow, Select, MenuItem as SelectMenuItem, FormControl, SelectChangeEvent, Tooltip } from '@mui/material';
import { TableCellBody } from '../table/tableCell';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CachedIcon from '@mui/icons-material/Cached';
import EditNoteIcon from '@mui/icons-material/EditNote';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import SnackbarNotification from '../snackbar/snackbarNotification';
import { STATUS } from '../../utils/status';
import { setUpdateProjectStatus } from '../../redux/slices/projectSlice/addProjectSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store/appStore';
import { useNavigate } from 'react-router-dom';
interface TableRowItemProps {
  row: any;
  handleClick: (row: any) => void;
  onDeleteClick: (userId: string) => void;
  onToggleDisable: (row: any) => void;
  statuses: string[];
  onStatusChange: (row: any, newStatus: string) => void;
}

const TableRowItem: React.FC<TableRowItemProps> = ({
  row,
  onDeleteClick,
  onToggleDisable,
  handleClick,
  statuses,
  onStatusChange,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [editingRowId, setEditingRowId] = useState<string | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<string>(row.status);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning'>('success');
  const updateStatus = useSelector((state: RootState) => state.projects.updateStatus);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { tableActionsEditAccess, tableActionsStatusAccess } = useSelector((state: RootState) => {
    const tableActions = state.company?.company?.data?.modules?.projects?.table?.table_actions || [];
    return {
      tableActionsEditAccess: tableActions.includes('edit'),
      tableActionsStatusAccess: tableActions.includes('change_status'),
    };
  });

  const { name, project_status, total_tasks, total_completed_tasks } = useSelector((state: RootState) => {
    const tableActions = state.company?.company?.data?.modules?.projects?.table?.table_fields || [];
    return {
      name: tableActions.includes('name'),
      project_status: tableActions.includes('project_status'),
      total_tasks: tableActions.includes('total_tasks_added'),
      total_completed_tasks: tableActions.includes('total_tasks_completed'),
      actions: tableActions.includes('actions'),
    };
  });

  const handleSnackbar = (message: string, severity: 'success' | 'error' | 'info' | 'warning') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const truncateName = (name: string): string => {
    const maxLength = 30; 
    if (name.length > maxLength) {
        return `${name.substring(0, maxLength)}...`;
    }
    return name;
};

  const handleChangeStatusClick = () => {
    setEditingRowId(row.id);
    handleMenuClose();
  };

  const handleStatusChange = (event: SelectChangeEvent<string>) => {
    const newStatus = event.target.value as string;
    setSelectedStatus(newStatus);
  };

  useEffect(() => {
    if (updateStatus === STATUS.SUCESS) {
      handleSnackbar('Status Updated Successfully', 'success');
      dispatch(setUpdateProjectStatus(STATUS.NOT_STARTED));
    }

    if (updateStatus === STATUS.FAILED) {
      handleSnackbar('Error Occurred, Please try again!', 'error');
      dispatch(setUpdateProjectStatus(STATUS.NOT_STARTED));
    }
  }, [updateStatus, navigate, dispatch]);

  const handleCancelClick = () => {
    setSelectedStatus(row.status);
    setEditingRowId(null);
  };

  const handleSaveClick = () => {
    onStatusChange(row, selectedStatus);
    setEditingRowId(null);
  };

  return (
    <TableRow key={row.id}>
      {name && (<TableCellBody>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}} onClick={() => handleClick(row)}>
          <Tooltip title={row.project_name ? row.project_name : '-'}>
            <div style={{ flex: 3, overflow: 'hidden', textOverflow: 'ellipsis', cursor:'pointer'}}>
              {truncateName(row.project_name ? row.project_name : '-')}
            </div>
          </Tooltip>
          {tableActionsEditAccess && (
          <div style={{ flex: 1 }}>
            <KeyboardArrowRightIcon fontSize='small' sx={{cursor:'pointer'}}/>
          </div>
          )}
        </div>
      </TableCellBody>
      )}
      {project_status && (
      <TableCellBody>
        {editingRowId === row.id ? (
          <FormControl>
            <Select value={selectedStatus} onChange={handleStatusChange} size='small'>
              {statuses.map((status) => (
                <SelectMenuItem key={status} value={status}>
                  {status}
                </SelectMenuItem>
              ))}
            </Select>
            {editingRowId &&
          <>
            <div style={{display:'flex'}}>
            <CloseIcon fontSize='small' onClick={handleCancelClick} sx={{ marginRight: '1px', marginTop:'2px', cursor:'pointer' }}/>
            <CheckIcon onClick={handleSaveClick} sx={{ cursor:'pointer' }}/>
            </div>
          </>
          }
          </FormControl>
        ) : (
          row.project_status ? row.project_status : '-'
        )}
      </TableCellBody>
      )}
      {total_tasks && (
      <TableCellBody>{Object.keys(row).includes('total_tasks') ? row.total_tasks: '-'}</TableCellBody>
      )}

      {total_completed_tasks && (
      <TableCellBody> {Object.keys(row).includes('total_completed_tasks') ? row.total_completed_tasks: '-'} </TableCellBody> 
      )}

      <TableCellBody>
        {row.association_type === 'COMPANY' ? 'ADMIN' : row.association_type}
      </TableCellBody>
      
      {/* {actions && (
      <TableCellBody>
        
          <IconButton onClick={handleMenuClick}>
          {(tableActionsEditAccess || tableActionsStatusAccess) && ( <MoreVertIcon /> )}
          </IconButton>
          
        
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        {tableActionsStatusAccess && (
          <MenuItem onClick={handleChangeStatusClick}>
            <CachedIcon fontSize='small' sx={{ marginRight: '5px' }} /> Change Status 
          </MenuItem>
        )}
          {tableActionsEditAccess && (
          <MenuItem onClick={() => handleClick(row)}>
            <EditNoteIcon fontSize='small' sx={{ marginRight: '5px' }} /> Edit
          </MenuItem>
          )}
        </Menu>
      </TableCellBody>
      )} */}
      <SnackbarNotification
          open={snackbarOpen}
          message={snackbarMessage}
          severity={snackbarSeverity}
          onClose={handleSnackbarClose}
        />
    </TableRow> 
  );
};

export default TableRowItem;
