import React, { useEffect } from 'react';
import { Chip } from '@mui/material';
import { CreateConfigPayload } from '../../redux/interfaces/interface';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store/appStore';
import { useParams, useLocation } from 'react-router-dom';

interface ChipListProps {
  configData?: {
    data: any;
    results: CreateConfigPayload[];
  };
  handleChipClick: (chipLabel: string, chipId: string, chipInteractive: boolean) => void;
  selectedChip: string | null;
}

const chipColors = ['#FFCDD2', '#E1F5FE', '#E1BEE7', '#F8BBD0', '#C8E6C9'];

const ChipList: React.FC<ChipListProps> = ({ configData = { results: [],data:[] }, handleChipClick, selectedChip }) => {
  const getConfig = useSelector((state: RootState) => state.config.currentConfig);
  const config_id = getConfig.id;

  const { pathname } = useLocation();
  const { configId } = useParams<{ configId?: string }>();

  

  useEffect(() => {
    if (pathname.includes('editconfig') && Array.isArray(configData.data)) {
      const chipToSelect = configData.data.find((config: any) => {
        const configIdMatch = String(config.id) === String(configId);
        return configIdMatch;
      });

      if (chipToSelect) {
        handleChipClick(chipToSelect.value || '', chipToSelect.id || '', chipToSelect.interactive_config || false);
      }
    }
  }, [pathname, configId, configData.data, handleChipClick]);

  
  return (
    <>
      {Array.isArray(configData.data) && configData.data.map((config: any, index: number) => {
        const isSelected = selectedChip !== null && selectedChip === config.value;
        const isVisible = !config_id || getConfig.name === config.value;
        if (isVisible) {
          return (
            <Chip
              key={config.id}
              label={config.name}
              sx={{
                marginRight: '10px',
                backgroundColor: chipColors[index % chipColors.length],
                border: isSelected ? '2px solid #2196F3' : 'none',
                marginTop: '10px'
              }}
              onClick={() => handleChipClick(config.value || '', config.id || '', config.interactive_config || false)}
            />
          );
        } else {
          return null; 
        }
      })}
    </>
  );
};

export default ChipList;
