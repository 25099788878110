import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Dropzone, FileItem, ExtFile } from '@dropzone-ui/react';
import * as XLSX from 'xlsx';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { AddButton } from '../button/button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { AnyAction } from '@reduxjs/toolkit';
import SnackbarNotification from '../snackbar/snackbarNotification';
import { RootState } from '../../redux/store/appStore';
import { createUploadDeliveredFile, setCreateDeliveredStatus } from '../../redux/slices/deliveredFile';
import { STATUS } from '../../utils/status';

interface SheetData {
    name: string;
    data: any[][];
}

const TaskDeliveredFileUpload = () => {
    const createStatus = useSelector((state: RootState) => state.delivered.createDeliveredStatus);
    const [files, setFiles] = useState<ExtFile[]>([]);
    const [sheets, setSheets] = useState<SheetData[]>([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning'>('success');

    const handleDrop = (newFiles: ExtFile[]) => {
        setFiles(newFiles);
        if (newFiles.length > 0) {
            const file = newFiles[0].file;
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const binaryStr = e.target?.result;
                    if (typeof binaryStr === 'string') {
                        setLoading(true);
                        const workbook = XLSX.read(binaryStr, { type: 'binary' });
                        const sheetDataArray = workbook.SheetNames.map(sheetName => {
                            const worksheet = workbook.Sheets[sheetName];
                            const sheetData = XLSX.utils.sheet_to_json<any[][]>(worksheet, { header: 1 });
                            return { name: sheetName, data: sheetData };
                        });
                        setSheets(sheetDataArray);
                        setLoading(false);
                    }
                };
                reader.readAsBinaryString(file);
            }
        }
    };

    const handleSubmit = () => {
        if (files.length > 0 && id) {
            const file = files[0].file;
            if (file) {
                dispatch(createUploadDeliveredFile({ id, selectedFile: file }) as unknown as AnyAction)
            }
        }
        else {
            handleSnackbar('Please upload a file to submit', 'warning');
        }
    };

    useEffect(() => {
        const handleCreateStatus = () => {
            if (createStatus === STATUS.SUCESS) {
                handleSnackbar('File Uploaded Successfully', 'success');
                setTimeout(() => {
                    navigate(`/project/${id}?tab=task`);
                }, 1000);
            }
            if (createStatus === STATUS.FAILED) {
                handleSnackbar('Wrong Format or No Allocations', 'error');
            }
        }
        handleCreateStatus();
        return () => {
            dispatch(setCreateDeliveredStatus(STATUS.NOT_STARTED));
        };
    }, [createStatus]);

    const handleSnackbar = (message: string, severity: 'success' | 'error' | 'info' | 'warning') => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <Box>
                <Box mt={2} display="flex" flexDirection="column" alignItems="center" position="relative">
                    <Dropzone
                        onChange={handleDrop}
                        accept={'.csv'}
                        maxFiles={1}
                        header={false}
                        footer={false}
                        style={{
                            border: '2px dashed #4a90e2',
                            borderRadius: '10px',
                            padding: '20px',
                            backgroundColor: '#ffffff',
                            width: '100%',
                            textAlign: 'center',
                            cursor: 'pointer'
                        }}
                    >
                        {files.length === 0 ? (
                            <>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography variant="body2" sx={{ textTransform: 'none', fontSize: '1rem', fontWeight: 'bold', color: '#333' }}>
                                        Upload a file here
                                    </Typography>
                                    <Typography variant="body2" sx={{ textTransform: 'none', fontSize: '14px', color: '#333', marginTop: '10px' }}>
                                        You can upload .csv
                                    </Typography>
                                    <AddButton>Choose a file</AddButton>
                                </div>
                            </>
                        ) : (
                            files.map((file, index) => (
                                <FileItem
                                    key={index}
                                    file={file.file}
                                    onDelete={() => setFiles(files.filter((_, i) => i !== index))}
                                />
                            ))
                        )}
                    </Dropzone>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Box mt={2} display="flex" justifyContent="space-around" >
                            <Box display="flex" alignItems="center" width="100%">
                                <Box flexGrow={1} borderBottom="1px solid black" />
                                <Typography variant="body1" mx={2}>
                                    OR
                                </Typography>
                                <Box flexGrow={1} borderBottom="1px solid black" />
                            </Box>
                        </Box>
                        <div style={{ display: 'flex', marginLeft: '10px' }}>
                            <AddButton disabled><FileUploadIcon />Create Manually</AddButton>
                            <AddButton component="a" href="https://data-platform-resources.s3.ap-south-1.amazonaws.com/web-platform-assets/Mark+Delivered+Template.csv"><FileDownloadIcon /> Download Template</AddButton>
                        </div>
                    </div>
                    <br />
                    <Box mt={2} display="flex" justifyContent="flex-end" width="100%" sx={{ marginBottom: '10px' }}>
                        <AddButton onClick={handleSubmit} disabled={files.length === 0}>Submit File</AddButton>
                    </Box>
                </Box>
            <SnackbarNotification
                open={snackbarOpen}
                message={snackbarMessage}
                severity={snackbarSeverity}
                onClose={handleSnackbarClose}
            />
        </Box>

    );
};

export default TaskDeliveredFileUpload;
