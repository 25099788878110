import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Task {
  id: string;
  task_name: string;
}

interface Review {
  id: string;
  task_name: string;
}

interface TaskState {
  tasksWithAllocation: Task[];
  tasksWithoutAllocation: Task[];
  reviewWithAllocation: Review[];
  reviewWithoutAllocation: Review[];
}

const initialState: TaskState = {
  tasksWithAllocation: [],
  tasksWithoutAllocation: [],
  reviewWithAllocation: [],
  reviewWithoutAllocation: []
};

const taskSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    setTasksWithAllocation(state, action: PayloadAction<Task[]>) {
      state.tasksWithAllocation = action.payload;
    },
    setTasksWithoutAllocation(state, action: PayloadAction<Task[]>) {
      state.tasksWithoutAllocation = action.payload;
    },
    setReviewssWithAllocation(state, action: PayloadAction<Task[]>) {
      state.reviewWithAllocation = action.payload;
    },
    setReviewsWithoutAllocation(state, action: PayloadAction<Task[]>) {
      state.reviewWithoutAllocation = action.payload;
    },
  },
});

export const { setTasksWithAllocation, setTasksWithoutAllocation, setReviewsWithoutAllocation, setReviewssWithAllocation } = taskSlice.actions;
export default taskSlice.reducer;
