import { CircularProgress, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import SwapVertIcon from '@mui/icons-material/SwapVert';
import TableRowItem from '../../components/analytics/taskCompletedTableRow';
import Error from '../../components/error/error';
import { STATUS } from '../../utils/status';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store/appStore';
import { useStyles } from '../../assets/styles/listTableStyle';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getCompletedAnalytics } from '../../redux/slices/analytics/completed';
import CompletedTaskTableRow from './CompletedTaskTableRow';

const ActiveProjectsList: React.FC<{ data: any, dataStatus: string }> = ({ data, dataStatus }) => {
  const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [sortColumn, setSortColumn] = useState<string>('date');
  const [sortOrder, setSortOrder] = useState<string>('asc');
  const { id } = useParams()

  const getStatus = useSelector((state: RootState) => state.analyticsCompleted.getStatus);
  const analytics = useSelector((state: RootState) => state.analyticsCompleted.analyticsCompleted)

  // useEffect(()=>{
  //     dispatch(getCompletedAnalytics({project_id: id,page: currentPage, pageSize: itemsPerPage, sort_by: sortColumn, sort_order: sortOrder}))
  //    },[dispatch, id, currentPage, itemsPerPage, sortColumn, sortOrder])

  const totalPages = Math.ceil((analytics.count || 0) / itemsPerPage);

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  // const handleSort = (column: string) => {
  //   const formattedHeader = column.toLowerCase().replace(/\s+/g, '_');
  //   const newOrder = sortColumn === formattedHeader && sortOrder === 'asc' ? 'desc' : 'asc';
  //   setSortColumn(formattedHeader);
  //   setSortOrder(newOrder);
  // };

  const headers = ["PROJECT NAME", "TOTAL TASK", "TOTAL TASK ALLOCATION", "NOT STARTED TASK", "ACTIVE TASK", "TASK COMPLETED", "AVG TASK PER DAY"]

  return (

    <>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: '#F7F9FA', '&:first-child td, &:first-child th': { borderBottom: 0 }   }}>
            <TableRow>
              {headers.map((header) => (
                <TableCell align="left"
                //  onClick={() => handleSort(header)} sx={{ fontSize: '10px', fontWeight: 700, lineHeight: '14px' }} 
                key={header}> 
                    {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>

            {dataStatus === STATUS.PENDING ?
              <TableRow>
                <TableCell colSpan={8}>
                  <CircularProgress size={20} />
                </TableCell>
              </TableRow>
              : dataStatus === STATUS.SUCESS && data.results && data.results.length > 0 ? (
                <>

                  {data.results.map((row: any) => (
                    <CompletedTaskTableRow  key={row.id}  row={row}/>
                  ))}

                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={8} className={classes.noUsersMessage}>
                    <Error message={'There is no data added as of yet.'} />
                  </TableCell>
                </TableRow>
              )}
          </TableBody>
        </Table>

      </TableContainer>
      {/* <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        paginate={paginate}
        itemsPerPage={itemsPerPage} 
      /> */}
    </>

  )
}

export default ActiveProjectsList