import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import AllTable from '../../components/projectList/projectTable';
import ProjectListHeading from '../../components/projectList/projectListHeading';
import { Divider, Typography } from '@mui/material';

const ProjectListMain = () => {
  return (
    <div style={{ width: '100vw', overflow: 'hidden' }}>
      <Box sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '0rem' }}>
        <ProjectListHeading />
      </Box>
      <Divider />
      <Box component="main" sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '1rem' }}>
        <Paper sx={{
          bgcolor: '#FFFFFF',
          borderRadius: '8px',
          padding: '1rem',
          mb: '2rem',
          boxShadow: 2,
        }}>
          <div
            style={{
              borderBottom: '1px solid',
              borderColor: '#E8EAEF',
              margin: 2,
              padding: '0px, 24px, 0px, 24px',
            }}
          >
            <AllTable />
          </div>
        </Paper>
      </Box>
    </div>
  );
}

export default ProjectListMain;   
