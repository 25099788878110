import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';
import { Alert, Snackbar } from '@mui/material';
import { setCookie } from '../cookies';
import { getCookie } from '../cookies'; 
import axios from 'axios';
import { BASE_URL } from '../../../utils/constants';
import { MuiOtpInput } from 'mui-one-time-password-input'
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store/appStore';
import { useNavigate } from 'react-router-dom';

const defaultTheme = createTheme();

export default function   SignInSide() {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [showOTPField, setShowOTPField] = useState(false);
  const [resentOTP, setResentOTP] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning' | undefined>('success');
  const [emailError, setEmailError] = useState('');
  const [otpError, setOtpError] = useState('');
  const emails = useSelector((state: RootState) => state.company.company.data.email)
  const role = useSelector((state: RootState) => state.company.company.data.role)

  const isDashboardAllowed = (emails.includes("accunai") || emails.includes("firstsource")) && role === "ADMIN";
  const navigate = useNavigate()

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSnackbar = (message: string, severity: 'success' | 'error' | 'info' | 'warning') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleVerifyEmail = async () => {
    if (!email.includes('@') || !email.includes('.')) {
      setEmailError('Invalid email address');
      return;
    }
  
    try {
      const response = await axios.post(BASE_URL + 'auth/company/user/login/other/', { email });
      if (response.status === 200) {
        setShowOTPField(true);
        setResentOTP(true);
        handleSnackbar('OTP sent successfully!', 'success');
      }
    } catch (error) {
      handleSnackbar('Failed to send OTP. Please try again.', 'error');
    }
  };

  const handleResendOTP = async () => {
    setResentOTP(true);
    setOtp('');
    setOtpError('');
    try {
      const response = await axios.post(BASE_URL + 'auth/company/user/login/other/', { email });
      if (response.status === 200) {
        handleSnackbar('OTP resent successfully!', 'success');
      }
    } catch (error) {
      handleSnackbar('Failed to resend OTP. Please try again.', 'error');
    }
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (showOTPField) {
      if (otp.length !== 6) {
        handleSnackbar('OTP must be of 6 digits', 'info');
        return;
      } else {
        setOtpError('');
      }
  
      try {
        const response = await axios.post(BASE_URL + 'auth/company/user/login/other/verify/', { email, otp });
  
        if (response.status === 200) {
          const authToken = response.data['authentication-token'];
          
          if (authToken) {
            localStorage.setItem('authenticated', 'true');
            setCookie('token', authToken, 7); 
            handleSnackbar('Login Successful', 'success');
            setTimeout(() => {
              // if (isDashboardAllowed) {
              //   window.location.href = '/dashboard';
              // } else {
              // }
              window.location.href = '/projects';
            }, 1000);
          }
        }
      } catch (error) {
        handleSnackbar('Invalid OTP. Please try again.', 'error');
        setOtpError('You’ve entered an incorrect OTP. Please try again or resend another OTP.');
      }
    }
  };

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setEmailError('');
  };

  const handleOtpChange = (newValue: React.SetStateAction<string>) => {
    setOtp(newValue);
    setOtpError('');
  };

  useEffect(() => {
    const isAuthenticated = getCookie('token') !== null;

    if (isAuthenticated) {
      // if (isDashboardAllowed) {
      //   window.location.href = '/dashboard';
      // } else {
      // }
      window.location.href = '/projects';
    }
  }, []);

  return (
      <>
              <Box component="form" noValidate onSubmit={handleSubmit}>
                <label htmlFor="email" style={{ fontFamily: 'Montserrat', fontSize: '13px', fontWeight: '400', lineHeight: '20px', color: "white" }}>Enter your email address.</label>
                <TextField
                  required
                  fullWidth
                  id="email"
                  name="email"
                  placeholder="Enter email address"
                  type="email"
                  autoComplete="email"
                  size="small"
                  value={email}
                  onChange={handleEmailChange}
                  error={!!emailError}
                  helperText={emailError}
                  sx={{fontSize: "40px"}}
                  style={{backgroundColor: "white", borderRadius: "7px", color: "black"}}

                />
                <br /><br />
                {showOTPField && (
                  <>
                    <label htmlFor="otp" style={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: '400', lineHeight: '20px', color: "white" }}>OTP</label>
                    <Box
                    sx={{
                      color: "white",
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '10px',
                      borderColor: otpError ? 'red' : '#DDDDDD',
                      '& input': { width: '1.5rem', height: '1.5rem' },
                      marginBottom: '10px'
                    }}
                  >
                    <MuiOtpInput
                      value={otp}
                      sx={{
                        '& input': {
                          color: 'white',
                          border: '1px solid white',
                          borderRadius: "10px",
                          
                        },
                        '& .MuiInputBase-root': {
                          borderColor: 'white',
                          borderRadius: "10px"
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'white',
                            borderRadius: "10px"
                          },
                          '&:hover fieldset': {
                            borderColor: 'white',
                            borderRadius: "10px"
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'white',
                            borderRadius: "10px"
                          },
                        },
                      }}
                      onChange={handleOtpChange}
                      length={6}
                    />
                  </Box>
                    {/* <TextField
                      required
                      fullWidth
                      name="otp"
                      placeholder="Enter OTP"
                      type="text"
                      id="otp"
                      autoComplete="one-time-code"
                      size="small"
                      value={otp}
                      onChange={handleOtpChange}
                      error={!!otpError}
                      sx={{ borderColor: otpError ? 'red' : '#DDDDDD', border: '1px', marginBottom: '10px' }}
                    /> */}
                  </>
                )}

                {showOTPField && (
                  <>
                    <div style={{ display: 'flex' }}>
                      <Typography sx={{ fontFamily: 'Montserrat', fontSize: '12px', fontWeight: '600', lineHeight: '20px', letterSpacing: '0.3px', minWidth: '9rem', color: "white" }}>Didn't receive code?</Typography>
                      <Button
                        fullWidth
                        variant="text"
                        onClick={handleResendOTP}
                        sx={{
                          fontFamily: 'Montserrat',
                          fontSize: '12px',
                          fontWeight: 600,
                          lineHeight: '20px',
                          textAlign: 'center',
                          border: 'none',
                          textTransform: 'none',
                          '&:hover': {
                            border: 'none',
                            backgroundColor: 'none',
                          },
                          width: '5rem',
                          color: '#3D85C6',
                          p: 0,
                          m: 0,
                        }}
                      >
                        Resend OTP
                      </Button>
                    </div>
                    <div style={{ color: 'yellow', marginTop: '2rem' }}>
                      {otpError}
                    </div>
                  </>
                )}

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  onClick={showOTPField ? () => {} : handleVerifyEmail}
                  sx={{
                    mt: '1rem',
                    minWidth: '384px',
                    minHeight: '44px',
                    fontFamily: 'Montserrat',
                    fontSize: '14px',
                    fontWeight: '600',
                    lineHeight: '24px',
                    padding: '8px, 24px, 8px, 24px',
                    borderRadius: "10px",
                    backgroundColor: '#3D85C6',
                    transition: 'background-color 0.3s ease',
                    textTransform: 'none',
                    '&:hover': {
                      backgroundColor: '#2C5996',
                    },
                  }}
                >
                  {showOTPField ? <h1 style={{fontSize: "15px"}}>Verify OTP</h1> : <h1 style={{fontSize: "15px"}}>Continue</h1>}
                </Button>
              </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={9000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{ borderRadius: '8px' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity || 'success'} sx={{ width: '100%' }}>
          <div style={{ display: 'flex' }}>
            <div style={{ fontFamily: 'Montserrat', fontWeight: '600' }}>{snackbarMessage}</div>
          </div>
        </Alert>
      </Snackbar>
      </>
  );
}
