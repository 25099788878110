import React from "react";
import { Typography } from "@mui/material";

interface AudioShowActionProps {
    action_value?: any;
}

export default function AudioShowAction({ action_value }: AudioShowActionProps) {

    return (
        <>
            {action_value && action_value.map((action: any, index: number) => (
                <div key={index} style={{ display: 'flex', marginBottom: '10px', flexDirection: 'column' }}>
                    <Typography sx={{ fontWeight: 'bold', marginRight: '20px' }}>{action.config_details.value.title} : </Typography>
                    {action.config_details.default_value ?
                        <audio controls className="w-full">
                            <source src={action.config_details.default_value} type="audio/wav" />
                            Your browser does not support the audio element.
                        </audio>
                        : (
                            <p>Audio not available.</p>
                        )}
                </div>
            ))}
        </>
    );
}
