import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface EmailTask {
    email: string;
    count: string;
}

interface EmailTaskState {
    emailTasks: EmailTask[];
}

const initialState: EmailTaskState = {
    emailTasks: [],
};

const emailTaskSlice = createSlice({
    name: 'emailTasks',
    initialState,
    reducers: {
        addEmailTask(state, action: PayloadAction<EmailTask>) {
            state.emailTasks.push(action.payload);
        },
        clearEmailTasks(state) {
            state.emailTasks = [];
        },
        updateEmailTask: (state, action: PayloadAction<{ index: number; task: EmailTask }>) => {
            const { index, task } = action.payload;
            state.emailTasks[index] = task;
        },
        deleteEmailTask: (state, action: PayloadAction<number>) => {
            state.emailTasks.splice(action.payload, 1);
        },
    },
});

export const { addEmailTask, clearEmailTasks, updateEmailTask, deleteEmailTask } = emailTaskSlice.actions;
export default emailTaskSlice.reducer;
