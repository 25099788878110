import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SelectedId {
  id: string;
  task_name: string;
}

interface SelectedIdsState {
  selectedIds: SelectedId[];
}

const initialState: SelectedIdsState = {
  selectedIds: [],
};

const selectedIdsSlice = createSlice({
  name: 'selectedIds',
  initialState,
  reducers: {
    addId: (state, action: PayloadAction<SelectedId>) => {
      state.selectedIds.push(action.payload);
    },
    removeId: (state, action: PayloadAction<string>) => {
      state.selectedIds = state.selectedIds.filter(item => item.id !== action.payload);
    },
    setIds: (state, action: PayloadAction<SelectedId[]>) => {
      state.selectedIds = action.payload;
    },
    clearIds: (state) =>{
      state.selectedIds = [];
    }
  },
});

export const { addId, removeId, setIds, clearIds } = selectedIdsSlice.actions;
export default selectedIdsSlice.reducer;
