import React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Paper } from '@mui/material';
import ExpertHeading from '../../components/experts/expertsHeading';
import ExpertForm from '../../components/experts/expertsForm';
import useStyles from '../../assets/styles/style';

const ExpertMain = () => {
  const classes = useStyles(); 
  return (
    <div className={classes.formMainContainer}>
      <Box className={classes.formMainHeadingBox}>
      <ExpertHeading/>
      </Box>
      <Box component="main" className={classes.formMainBox}>
        <Paper className={classes.formMainpaper}>
          <ExpertForm/>
        </Paper>
      </Box>
    </div>
  );
};

export default ExpertMain;
