import React from 'react';
import { Box, Paper } from '@mui/material';
import SpreadsheetImportWizard from '../../components/uploadTask/uploadExcel';
import UploadTaskHeading from '../../components/uploadTask/uploadTaskHeading';

const BulkUploadTask: React.FC = () => {
  return (
    <div style={{ width: '100vw', overflow: 'hidden' }}>
      <Box sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '0rem' }}>
       <UploadTaskHeading/>
      </Box>
    <Box component="main" sx={{ flexGrow: 1, bgcolor: '#F7F9FA', p: '2rem' }}>
        <Paper sx={{
          bgcolor: '#dfe9f5',
          borderRadius: '8px',
          padding: '1.5rem',
          mb: '2rem',
          boxShadow: 2,
        }}>
          <div
            style={{
              borderBottom: '1px solid',
              borderColor: '#E8EAEF',
              margin: 2,
              padding: '0px, 24px, 0px, 24px',
            }}
          > 
            <SpreadsheetImportWizard/>
          </div>
        </Paper>
      </Box>
      </div>
  );
};

export default BulkUploadTask;
