import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface EmailList {
  email: string;
}

interface enteredEmailsState {
  enteredEmails: EmailList[];
}

const initialState: enteredEmailsState = {
  enteredEmails: [],
};

const enteredEmailsSlice = createSlice({
  name: 'enteredEmails',
  initialState,
  reducers: {
    setEmails: (state, action: PayloadAction<EmailList[]>) => {
      state.enteredEmails = action.payload;
    },
  },
});

export const { setEmails } = enteredEmailsSlice.actions;
export default enteredEmailsSlice.reducer;
