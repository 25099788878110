import React from 'react';
import { Snackbar, SnackbarContent } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import SuccessIcon from '../../assets/img/success.png';
import FailIcon from '../../assets/img/error.png';

interface SnackbarNotificationProps {
  open: boolean;
  message: string;
  severity: 'success' | 'error' | 'info' | 'warning';
  onClose: () => void;
}

const SnackbarNotification: React.FC<SnackbarNotificationProps> = ({ open, message, severity = 'success', onClose }) => {
  const path = window.location.href.includes('allocation')
  const icon = severity === 'success' ? SuccessIcon : FailIcon;
  const backgroundColor =
    severity === 'success' ? '#008F46' :
    severity === 'warning' ? '#FFC107' :
    severity === 'info' ? '#1976D2' :
    '#C00F00';

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      autoHideDuration={!path ? 6000 : 2000}
      onClose={onClose}
    >
      <SnackbarContent
        sx={{ backgroundColor, color: '#FFFFFF', borderRadius: '8px' }}
        message={
          <div style={{ display: 'flex' }}>
            <img src={icon} style={{ marginRight: '12px' }} alt="icon" />
            <div style={{ fontFamily: 'Montserrat', fontWeight: '600' }}>{message}</div>
          </div>
        }
        action={
          <ClearIcon sx={{ fontSize: 'large', color: '#FFFFFF' }} onClick={onClose} />
        }
      />
    </Snackbar>
  );
};

export default SnackbarNotification;
