import React from 'react';
import { TableRow } from '@mui/material';
import { TableCellBody } from '../table/tableCell';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import { RootState } from '../../redux/store/appStore';
import { useSelector } from 'react-redux';

interface TableRowItemProps {
  row: any;
  onEditClick: (row: any) => void;
  onDeleteClick: (userId: string) => void;
  onToggleDisable: (row: any) => void;
}

const TableRowItem: React.FC<TableRowItemProps> = ({ row, onEditClick, onDeleteClick, onToggleDisable }) => {
  const { tableActionsDeleteAccess, tableActionsDisableAccess } = useSelector((state: RootState) => {
    const tableActions = state.company?.company?.data?.modules?.experts?.table?.table_actions || [];
    return {
      tableActionsDeleteAccess: tableActions.includes('delete'),
      tableActionsDisableAccess: tableActions.includes('disable'),
    };
  });

  const { name, email, phone, last_login_time, actions } = useSelector((state: RootState) => {
    const tableActions = state.company?.company?.data?.modules?.experts?.table?.table_fields || [];
    return {
      name: tableActions.includes('name'),
      email: tableActions.includes('email'),
      phone: tableActions.includes('phone'),
      last_login_time: tableActions.includes('last_login_time'),
      actions: tableActions.includes('actions'),
    };
  });

  const formatDate = (dateString: string) => {
    if (!dateString) return '-';
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-GB', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    }).format(date);
  };

  return (
    <TableRow
      key={row.id}
      sx={{
        '&:last-child td, &:last-child th': { borderBottom: 0 },
      }}
    >
      {email && (<TableCellBody>{row.expert_details.email}</TableCellBody> )}
      {name && (<TableCellBody>{row.expert_details.first_name? row.expert_details.first_name: "-"} {row.expert_details.last_name}</TableCellBody> )}
      {phone && ( <TableCellBody>{row.expert_details.phone? row.expert_details.phone: "-"}</TableCellBody> )}
      {last_login_time && ( <TableCellBody>{row.last_login ? formatDate(row.last_login) : '-'}</TableCellBody> )}
      {actions && (
      <TableCellBody>
        {/* <EditNoteOutlinedIcon onClick={() => onEditClick(row)} /> */}
        {tableActionsDeleteAccess && (
        <DeleteOutlineOutlinedIcon
          sx={{ color: '#C00F00', cursor: 'pointer', marginRight: '5px' }}
          onClick={() => onDeleteClick(row.id)}
        /> )}

        {tableActionsDeleteAccess && (<DoDisturbIcon
          fontSize="small"
          color={row.is_disabled ? 'disabled' : 'inherit'}
          style={{ color: row.is_blocked ? undefined : 'red' }}
          onClick={() => onToggleDisable(row)}
        /> )}
      </TableCellBody>
      )}
    </TableRow>
  );
};

export default TableRowItem;
