import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../../utils/api';
import { STATUS } from '../../../utils/status';
import { AllocationState } from '../../interfaces/interface';
import extraReducers from '../../extraReducers/allocationExtraReducer';

const initialState: AllocationState = {
  allocation: { 
    count: undefined,
    results: [] 
   },
  error: null,
  createStatus: STATUS.NOT_STARTED,
  deleteStatus: STATUS.NOT_STARTED,
  updateStatus: STATUS.NOT_STARTED,
  getStatus: STATUS.NOT_STARTED,
};

export const createAllocation = createAsyncThunk(
  'task/company/allocate-tasks/POST', 
  async (payload: any, thunkAPI) => {
    const response = await api.post('task/company/allocate-tasks/', payload);
    const { status, data } = response;
    if (status === 200) {
      return {data, payload};
    } else {
      return thunkAPI.rejectWithValue({ response, data });
    }
  }
)

const allocationSlice = createSlice({
  name: 'allocation',
  initialState,
  reducers: {
    setCreateAllocationStatus(state,action){
    state.createStatus = action.payload
  },
  setUpdateAllocationStatus(state,action){
    state.updateStatus = action.payload
  }},
  extraReducers
});

export const {setCreateAllocationStatus, setUpdateAllocationStatus} = allocationSlice.actions
export default allocationSlice.reducer;
