import React, { useEffect, useMemo, useState } from 'react'
import { useStyles } from '../../assets/styles/listTableStyle';
import { AddButton } from '../button/button';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import TableHeader from '../table/tableHeader';
import { useNavigate } from 'react-router-dom';
import PaginationEntries from '../pagination/paginationEntries';
import { AppDispatch, RootState } from '../../redux/store/appStore';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import { SearchBar } from '../inputField/textfield';
import DeleteModal from '../modal/deleteModal';
import SnackbarNotification from '../snackbar/snackbarNotification';
import { getVendorAction, updateVendorAction } from '../../redux/slices/vendor';
import { STATUS } from '../../utils/status';
import Error from '../error/error';
import VendorTableRowItem from './VendorTableRowItem';
import VendorPagination from './VendorPagination';

const VendorListTable = () => {
    const classes = useStyles();
    const navigate = useNavigate()
    const dispatch = useDispatch<AppDispatch>();
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState<number>(10);
    const [searchTerm, setSearchTerm] = useState('')
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning'>('success');
    const [sortColumn] = useState('vendor_name');
    const [isVendorDelete] = useState(false)
    const [vendorIdToDelete, setVendorToDelete] = useState('')

    const { vendors } = useSelector((state: RootState) => state.vendors)
    const getVendorStatus = useSelector((state: RootState) => state.vendors.getVendorsStatus)

    const tableFields = ["Vendor Company Name", "Vendor Pseudo Name", "Vendor User Email", "Vendor User Phone", "Actions"]

    const handleSnackbar = (message: string, severity: 'success' | 'error' | 'info' | 'warning') => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };


    const capitalizeAll = (str: string) => {
        return str.replace(/_/g, ' ')
            .split(' ')
            .map(word => word.toUpperCase())
            .join(' ');
    };
    const handleSnackbarClose = () => {
        setSnackbarOpen(false)
    }

    const handleAddVendorClick = () => {
        navigate("/addvendor")
    }

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
        // setCurrentPage(1);
    };
    const debouncedResults = useMemo(() => {
        return debounce(handleSearchChange, 300);
    }, []);

    useEffect(() => {
        return () => {
            debouncedResults.cancel();
        };
    });

    const handleDeleteVendor = (vendorId: string) => {
        setVendorToDelete(vendorId)
        setShowDeleteModal(true)
        // setIsVendorDelete(true)
    }

    const handleChangeRowsPerPage = (value: number) => {
        setItemsPerPage(value as number);
        setCurrentPage(1);

        dispatch(getVendorAction({
            search_query: searchTerm,
            page: 1,
            pageSize: value as number,
            phone_number: 0,
            is_deleted: isVendorDelete,
            ordering: "-created_at"
        }));
    };
    const handleOnChange = (value: number) => {
        setItemsPerPage(value)
    }

    const cancelDelete = () => {
        setShowDeleteModal(false)
    }
    const confirmDelete = async () => {
        setShowDeleteModal(false);
        try {
            await dispatch(updateVendorAction({ id: vendorIdToDelete, is_deleted: true }));
            dispatch(getVendorAction({search_query: searchTerm,page: currentPage,pageSize: itemsPerPage,phone_number: 0,is_deleted: isVendorDelete,ordering: "-created_at"}));
            handleSnackbar('User Deleted Successfully.', 'success');
        } catch (error) {
            handleSnackbar('Error occured please try again.', 'error')
        }
    }

    useEffect(() => {
        dispatch(getVendorAction({search_query: searchTerm,page: currentPage,pageSize: itemsPerPage,phone_number: 0,is_deleted: isVendorDelete,ordering: "-created_at"}));
    }, [dispatch, searchTerm, currentPage, sortColumn, itemsPerPage, isVendorDelete]);

    const totalPages = Math.ceil((vendors.count || 0) / itemsPerPage);

    const paginate = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };


    return (
        <>
            <div className={classes.container}>
                <SearchBar onSearchChange={debouncedResults} />
                <div className={classes.actionsContainer}>
                    <PaginationEntries itemsPerPage={itemsPerPage} onChangeRowsPerPage={handleChangeRowsPerPage} handleOnChange={handleOnChange} />
                    <AddButton onClick={handleAddVendorClick}>
                        <PersonAddAlt1Icon fontSize='small' sx={{ marginRight: '5px' }} />Add Vendor
                    </AddButton>
                </div>
            </div>

            <TableContainer>
                {getVendorStatus === STATUS.PENDING && (
                    <div className={classes.progressDiv}>
                        <CircularProgress />
                    </div>
                )}
                {getVendorStatus === STATUS.SUCESS && (
                    <Table className={classes.tableContainer} aria-label="simple table">
                        <TableHeader headers={tableFields.map(capitalizeAll)} />
                        <TableBody>
                            {vendors && vendors.results && vendors.results.length > 0 ? (
                                vendors.results.map((row: any) => {
                                    return (
                                        <VendorTableRowItem
                                            key={row.id}
                                            row={row}
                                            onDeleteClick={handleDeleteVendor}
                                        />
                                    );
                                })
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={6} className={classes.noUsersMessage}>
                                        <Error message={'There are no vendors added as of yet. You can add a vendor to see it here.'} />
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                )}
            </TableContainer>
            <VendorPagination
                currentPage={currentPage}
                totalPages={totalPages}
                paginate={paginate}
                itemsPerPage={itemsPerPage}
            />
            <DeleteModal
                open={showDeleteModal}
                onCancel={cancelDelete}
                onConfirm={confirmDelete}
            />
            <SnackbarNotification
                open={snackbarOpen}
                message={snackbarMessage}
                severity={snackbarSeverity}
                onClose={handleSnackbarClose}
            />
        </>
    )
}

export default VendorListTable