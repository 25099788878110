import React, { useState, useRef, useEffect, useMemo } from 'react';
import { TextField, Typography, IconButton, InputAdornment, Box, Checkbox, CircularProgress, FormControl, RadioGroup, FormControlLabel, Radio, Autocomplete } from '@mui/material';
import { AddButton } from '../button/button';
import DeleteIcon from '@mui/icons-material/Delete';
import { ChromePicker } from 'react-color';
import ColorizeIcon from '@mui/icons-material/Colorize';
import { useNavigate, useParams } from 'react-router-dom';
import { createConfig, getConfigCategoryList, setCreateConfigStatus, setUpdateConfigStatus, updateConfig } from '../../redux/slices/config';
import { AppDispatch, RootState } from '../../redux/store/appStore';
import { useDispatch, useSelector } from 'react-redux';
import SnackbarNotification from '../snackbar/snackbarNotification';
import { STATUS } from '../../utils/status';
import TaskReviewToggle from './taskReviewToggle';
import debounce from 'lodash.debounce';

interface JsonTypeContentProps {
  chipLabel: string;
  chipId: string;
  keys: string[];
  handleSubmit: (jsonData: Array<{ [key: string]: string }>) => void;
  taskCategory: string;
}

const JsonTypeContent: React.FC<JsonTypeContentProps> = ({ chipLabel, chipId, keys, handleSubmit, taskCategory}) => {
  const initialField = keys.reduce((acc, key) => ({ ...acc, [key]: '' }), {});
  const [jsonFields, setJsonFields] = useState<Array<{ [key: string]: string }>>([initialField]);
  const [errors, setErrors] = useState<{ [index: number]: { [key: string]: string } }>({});
  const [colorPickerOpenIndex, setColorPickerOpenIndex] = useState<number | null>(null);
  const configValue = useSelector((state: RootState) => state.config.currentConfig);
  const [colorPickerValue, setColorPickerValue] = useState<string>('#000000');
  const [formValues, setFormValues] = useState({ identifier:'' });
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const createStatus = useSelector((state: RootState) => state.config.createStatus);
  const updateStatus = useSelector((state: RootState) => state.config.updateStatus);
  const jsonData = useSelector((state: RootState) => state.config.currentConfig)
  const navigate = useNavigate()
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning'>('success');
  const [selectedOption, setSelectedOption] = useState<'TASK' | 'REVIEW'>('TASK');
  const colorPickerRef = useRef<HTMLDivElement>(null);
  const getConfig = useSelector((state: RootState) => state.config.currentConfig);
  const config_id = getConfig.id;
  const [loading, setLoading] = useState(false);
  const [configType, setConfigType] = useState<'DYNAMIC' | 'STATIC'>('STATIC');
  const [options, setOptions] = useState<any[]>([]);
  const [open, setOpen] = React.useState(false);
  const [selectedCategory, setSelectedCategory] = useState<any>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [order, setOrder] = useState('');
  const [localIsChecked, setLocalIsChecked] = useState<boolean>(false);
  const categoryList = useSelector((state: RootState)=> state.config.configCategories)
  const getConfigCategpryListStatus = useSelector((state: RootState)=> state.config.getConfigCategoryListStatus)

  useEffect(()=>{
    if(chipLabel === 'label' || chipLabel === 'audio_labeling' || chipLabel === 'image_annotation' ){
    dispatch(getConfigCategoryList({project_id:id}));
    }
  },[chipId,id])

  const handleOrderChange = (e: any) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === '' || regex.test(e.target.value)) {
      setOrder(e.target.value);
    }
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setLocalIsChecked(checked);
  };

  useEffect(() => {
    if (getConfig && getConfig.value) {
      const populatedFields = getConfig && getConfig.value && getConfig.value.map((item: any) =>
        keys.reduce((acc: any, key: string) => ({ ...acc, [key]: item[key] || '' }), {})
      );
      setJsonFields(populatedFields);
      setSelectedOption(getConfig.type);
      setOrder(getConfig.order);
      setFormValues({identifier:getConfig.identifier});
      setLocalIsChecked(getConfig.required)
      setConfigType(getConfig.default_value_type || 'STATIC');
      setSelectedCategory(getConfig.default_value)
    }
  }, [getConfig, keys]);

  const handleSnackbar = (message: string, severity: 'success' | 'error' | 'info' | 'warning') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (colorPickerRef.current && !colorPickerRef.current.contains(event.target as Node)) {
        handleColorPickerClose();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleFieldChange = (index: number, key: string, value: string) => {
    const newFields = jsonFields.map((field, i) =>
      i === index ? { ...field, [key]: value } : field
    );
    setJsonFields(newFields);
    if (errors[index] && errors[index][key]) {
      const newErrors = { ...errors };
      delete newErrors[index][key];
      setErrors(newErrors);
    }
  };

  const handleColorPickerChange = (color: any) => {
    if (colorPickerOpenIndex !== null) {
      const index = colorPickerOpenIndex;
      const newFields = [...jsonFields];
      newFields[index]['color'] = color.hex;
      setJsonFields(newFields);
      // setColorPickerValue(color.hex);
    }
  };

  const handleColorPickerToggle = (index: number) => {
    if (colorPickerOpenIndex === index) {
      setColorPickerOpenIndex(null);
    } else {
      setColorPickerOpenIndex(index);
    }
  };

  const handleColorPickerClose = () => {
    setColorPickerOpenIndex(null);
  };
  const handleAdd = () => {
    const newFields = [...jsonFields, initialField];
    setJsonFields(newFields);
  };

  const handleDelete = (index: number) => {
    const newFields = jsonFields.filter((_, i) => i !== index);
    setJsonFields(newFields);
    const newErrors = { ...errors };
    delete newErrors[index];
    setErrors(newErrors);
  };

  const validateFields = () => {
    const newErrors: { [index: number]: { [key: string]: string } } = {};

    jsonFields.forEach((fieldSet, index) => {
      keys.forEach(key => {
        if (key === 'range_labels') return;

        const value = fieldSet[key];
        if (typeof value === 'string' && !value.trim()) {
          if (!newErrors[index]) newErrors[index] = {};
          newErrors[index][key] = 'This field is required';
        } else if (value === undefined || value === null) {
          if (!newErrors[index]) newErrors[index] = {};
          newErrors[index][key] = 'This field is required';
        }
      });
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const handleFormSubmit = () => {
    if (validateFields()) {
      const payload = {
        ...(config_id && { id: config_id }),
        project_id: id,
        task_category_id: taskCategory,
        config_id: chipId,
        name: chipLabel,
        value: jsonFields,
        type: 'TASK',
        identifier: formValues.identifier,
        ...(order && Number(order) !== 0 && { order: Number(order) }),
        required: localIsChecked,
        default_value_type: configType,
        default_value: configType==='DYNAMIC' ? selectedCategory?.key : selectedCategory
      }

      const newUpdateValues = Object.keys(payload).reduce((acc, key) => {
        const fetchedValue = jsonData[key];
        const newValue = payload[key];
        if (
          key in jsonData &&
          newValue !== fetchedValue &&
          (fetchedValue !== '' || newValue !== undefined) &&
          newValue !== ''
        ) {
          acc[key] = newValue;
        }
      
        return acc;
      }, {} as Record<string, string | boolean | string[] | undefined>);

      if (config_id) {
        dispatch(updateConfig({id: config_id, ...newUpdateValues}))
      }
      else {
        dispatch(createConfig(payload));
      }
    }
  };

  useEffect(() => {
    if (createStatus === STATUS.SUCESS) {
      handleSnackbar('Config Added Successfully', 'success');
      setTimeout(() => {
        navigate(`/project/${id}`);
      }, 1000);
    }
    
    if (createStatus === STATUS.FAILED) {
      handleSnackbar('error occured please try again', 'warning');
    }
    
    return () => {
      if(createStatus === STATUS.SUCESS){
        dispatch(setCreateConfigStatus(STATUS.NOT_STARTED));
      }
    };
  }, [createStatus]);

  useEffect(() => {
    const handleUpdateStatus = () => {
      if (updateStatus === STATUS.SUCESS) {
        handleSnackbar('Config Updated Successfully', 'success');
        setTimeout(() => {
          navigate(`/project/${id}`);
        }, 1000);
      }

      if (updateStatus === STATUS.FAILED) {
        handleSnackbar('Error Occurred, Please try again!', 'error');
      }
    };

    handleUpdateStatus();

    return () => {
      if(updateStatus == STATUS.SUCESS){
        dispatch(setUpdateConfigStatus(STATUS.NOT_STARTED));
      }
    };
  }, [updateStatus, navigate, dispatch]);

  const isLastRowFilled = () => {
    const lastFieldSet = jsonFields[jsonFields.length - 1];
    return keys.every(key => {
      const value = lastFieldSet[key];
      const trimmedValue = (typeof value === 'string') ? value.trim() : value;
      return trimmedValue !== '';
    });
  };

  const handleOptionChange = (
    event: React.MouseEvent<HTMLElement>,
    newOption: 'TASK' | 'REVIEW'
  ) => {
    if (newOption !== null) {
      setSelectedOption(newOption);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const debouncedResults = useMemo(() => { return debounce(handleSearchChange, 300); }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  useEffect(() => {
    if (getConfigCategpryListStatus === STATUS.SUCESS) {
        setOptions(categoryList)
    }
}, [getConfigCategpryListStatus])

  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfigType(event.target.value as 'DYNAMIC' | 'STATIC');
  };

  const handleAutocompleteInput = (e: any) => {
    debouncedResults(e)
  };

  return (
    <>
      <div style={{ display: 'flex' }}>
        <Typography sx={{ fontFamily: 'Montserrat', fontSize: 20, fontWeight: 600, lineHeight: '24px', color: '#10383D', marginTop: 2, marginBottom: 2, textTransform: 'capitalize', }}>
          {chipLabel.replace(/_/g, ' ')}
        </Typography>
      </div>
      <div style={{ display: 'flex', marginBottom: '20px' }}>
        <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '5px' }}>
          Enter Name:<br />
          <Typography sx={{ fontFamily: 'Montserrat', fontSize: '12px', fontWeight: 400, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}> This Name will identify unique configs </Typography>
        </Typography>
        <TextField
          placeholder="Enter Name"
          name="formValues"
          value={formValues.identifier}
          onChange={(e) => setFormValues({ identifier: e.target.value })}
          sx={{ marginLeft: '70px', marginTop: '10px' }}
          size="small"
        />
      </div>
      <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '5px' }}>Enter Values:<br />
        <Typography sx={{ fontFamily: 'Montserrat', fontSize: '12px', fontWeight: 400, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}> These values will be visible on expert side </Typography>
      </Typography>
      {jsonFields && jsonFields.map((fieldSet, index) => {
        return (
          <div key={index} style={{ marginBottom: '20px' }}>
            <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
              {keys.map((key) => (
                <div key={key} style={{ position: 'relative', marginBottom: '10px', marginRight: '10px' }}>
                  <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }} >{key.replace(/_/g, ' ')}</Typography>
                  <TextField
                    size="small"
                    value={key === 'color' ? fieldSet[key] : fieldSet[key]}
                    placeholder={key}
                    variant="outlined"
                    fullWidth
                    type={key === 'color' ? 'text' : 'text'}
                    onChange={(e) => handleFieldChange(index, key, e.target.value)}
                    error={errors[index] && !!errors[index][key]}
                    helperText={errors[index] && errors[index][key]}
                    InputProps={{
                      startAdornment: key === 'color' ? (
                        <InputAdornment position="start">
                          <Box
                            sx={{
                              width: 20,
                              height: 20,
                              backgroundColor: fieldSet['color'],
                              borderRadius: '10%',
                              border: '1px solid #ccc',
                            }}
                          />
                        </InputAdornment>
                      ) : null,
                    }}
                  />
                  {key === 'color' && (
                    <>
                      <IconButton
                        onClick={() => handleColorPickerToggle(index)}
                        aria-label="toggle color picker"
                        style={{ position: 'absolute', right: '5px', top: '40%' }}
                      >
                        <ColorizeIcon />
                      </IconButton>
                      {colorPickerOpenIndex === index && (
                        <div ref={colorPickerRef} style={{ position: 'absolute', zIndex: 999, top: '100%', left: 0 }}>
                          <ChromePicker
                            color={colorPickerValue}
                            onChange={handleColorPickerChange}
                            disableAlpha
                            styles={{ default: { picker: { width: '195px', height: '50px' } } }}
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              ))}
              {index !== 0 &&
              <IconButton
                onClick={() => handleDelete(index)}
                aria-label="delete row"
                sx={{ marginTop: '10px', color: '#ec480f' }}
              >
                <DeleteIcon />
              </IconButton>
               }
            </div>
          </div>
        )
      })}
      
      {(chipLabel === 'label' || chipLabel === 'audio_labeling' || chipLabel === 'image_annotation')&& 
     (<>
      <div style={{ display: 'flex' }}>
        <FormControl component="fieldset">
          <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '5px' }}>
            Config Type:
          </Typography>
          <RadioGroup row value={configType} onChange={handleTypeChange}>
            <FormControlLabel value="DYNAMIC" control={<Radio />} label="Dynamic" />
            <FormControlLabel value="STATIC" control={<Radio />} label="Static" />
          </RadioGroup>
        </FormControl>
      </div>

      {configType === 'STATIC' && (
        <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          marginTop: "20px",
        }}
      >
        <Typography sx={{ marginTop: '2px', fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}>Default Content : </Typography>
        <TextField
          placeholder='Enter content or link here...'
          size='small'
          sx={{ width: 300, marginLeft:'20px', marginBottom:'20px' }}
          value={configValue.default_value}
          onChange={(event) => {
            if (event) {
              setSelectedCategory(event.target.value); 
            } else {
              setSelectedCategory(null);
            }
          }}
        />
        </div>
      )}

      {configType === 'DYNAMIC' && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            marginTop: "20px",
          }}
        >
          <Typography sx={{ marginTop: '2px', fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}>Select Dynamic Value: </Typography>
          <Autocomplete
            sx={{ width: 950, marginLeft:'20px', marginBottom:'20px' }}
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            autoSelect
            value={selectedCategory}
            getOptionLabel={(option) => option.value}
            options={options}
            loading={getConfigCategpryListStatus === STATUS.PENDING}
            onChange={(event, value) => {
              if (value) {
                setSelectedCategory(value); 
              } else {
                setSelectedCategory(null);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select Category"
                size="small"
                sx={{ width: '30%' }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
                onChange={(e) => { handleAutocompleteInput(e) }}
              />
            )}
          />
        </div>
      )}
      </>)
    }


      <div style={{ display: 'flex', marginBottom: '20px' }}>
        <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '5px' }}>
          Order:<br />
          <Typography sx={{ fontFamily: 'Montserrat', fontSize: '12px', fontWeight: 400, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}> This Order will arrange config on expert side </Typography>
        </Typography>
        <TextField
          placeholder="Enter Order"
          name="order"
          value={order}
          onChange={handleOrderChange}
          sx={{ marginLeft: '35px', marginTop: '10px' }}
          size="small"
        />
      </div>
      <div style={{ display: 'flex' }}>
        <Typography
          sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '15px', marginRight: '50px' }}
        >
          Choose Type:
        </Typography>

        <TaskReviewToggle
          selectedOption={selectedOption}
          onChange={handleOptionChange}
        />

      </div>
      <div style={{ display: 'flex' }}>
        <Typography sx={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D', marginTop: '15px' }}>
          Do you want to mark it required?
        </Typography>
        <Checkbox
          checked={localIsChecked}
          onChange={handleCheckboxChange}
          color="primary"
          sx={{ marginTop: '5px' }}
        />
      </div>
      <br />
      <AddButton onClick={handleAdd} disabled={!isLastRowFilled()} variant="contained">Add</AddButton>
      <AddButton 
        disabled={config_id ? updateStatus == STATUS.PENDING : createStatus == STATUS.PENDING}
        startIcon={config_id ? (updateStatus == STATUS.PENDING ? <CircularProgress size={20} /> : <></> ): (createStatus == STATUS.PENDING ? <CircularProgress size={20} /> : null)} 
        onClick={handleFormSubmit} variant="contained"
      >
        {config_id ? "Update" : "Submit"}
      </AddButton>
      <SnackbarNotification
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
    </>

  );
};

export default JsonTypeContent;
