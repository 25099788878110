import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableContainer, TableRow, Paper, TextField, Chip } from '@mui/material';
import Type2AllocationSteps from './type2AllocationStep';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import { addEmailTask, updateEmailTask, deleteEmailTask, clearEmailTasks } from '../../../redux/reducers/emailTaskSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/store/appStore';
import { getUnallocatedTaskCount } from '../../../redux/slices/upload';
import { useLocation, useParams } from 'react-router-dom';
import Error from '../../error/error';
import { TableCellBody } from '../../table/tableCell';
import TableHeader from '../../table/tableHeader';
import { useStyles } from '../../../assets/styles/listTableStyle';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SnackbarNotification from '../../snackbar/snackbarNotification';
import { setRowErrors, updateRowError } from '../../../redux/reducers/copyPasteRowErrors';

interface RowData {
    email: string;
    count: string;
}

interface RowError {
    email?: boolean;
    count?: boolean;
}

interface copyPasteInterface {
    activeStep: number;
    setIsPastingParent?: any;
    onError: (error: string | null) => void;
    selectedBox?: string | null;
    setSelectedBox: React.Dispatch<React.SetStateAction<string | null>>;
    onRowErrorsChange: (hasErrors: boolean) => void;
}

const CopyPasteEmails: React.FC<copyPasteInterface> = ({
    activeStep,
    onError,
    setIsPastingParent,
    selectedBox,
    setSelectedBox = () => { },
    onRowErrorsChange
}) => {
    const [rows, setRows] = useState<RowData[]>([{ email: '', count: '' }]);
    const rowErrors = useSelector((state: RootState) => state.rowErrors.rowErrors);
    const [isPasting, setIsPasting] = useState<boolean>(true);
    const location = useLocation();
    const [currentView, setCurrentView] = useState<'current' | 'previous'>('current');
    const dispatch = useDispatch<AppDispatch>();
    const { id } = useParams();
    const classes = useStyles();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning'>('success');
    const emailTasks = useSelector((state: RootState) => state.emailTasks.emailTasks);
    const unallocatedTaskCount = useSelector((state: RootState) => state.upload.unallocatedCount);

    useEffect(() => {
        dispatch(getUnallocatedTaskCount({ project_id: id }));
    }, [id]);

    useEffect(() => {
        if (selectedBox !== null) {
            setCurrentView('current');
        }
    }, [selectedBox]);

    useEffect(() => {
        setRows(emailTasks.map(task => ({ email: task.email, count: task.count })));
    }, [emailTasks]);

    useEffect(() => {
        const hasErrors = rowErrors.some(rowError => rowError.email || rowError.count);
        onRowErrorsChange(hasErrors);
    }, [rowErrors, onRowErrorsChange]);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidTaskCount = (value: string) => /^\d+$/.test(value.trim());

    const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
        event.preventDefault();
        const clipboardData = event.clipboardData.getData('Text');
    
        if (!clipboardData.includes('\t')) {
            onError('You can copy-paste only from an Excel sheet.');
            return;
        }

        const newRows = clipboardData.split('\n').map(row => {
            const [email, count] = row.split('\t');
            const trimmedEmail = email?.trim() || '';
            const trimmedCount = count?.trim() || '';
            const rowError: RowError = {};
    
            if (trimmedEmail && !emailRegex.test(trimmedEmail)) {
                rowError.email = true;
                onError(`Invalid email: ${trimmedEmail}`);
            }
    
            if (trimmedCount && !isValidTaskCount(trimmedCount)) {
                rowError.count = true;
                onError(`Error: Task Count Cannot be '${trimmedCount}'. Only numbers are allowed.`);
            }
    
            return {
                email: trimmedEmail,
                count: trimmedCount,
                error: rowError
            };
        });
    
        const validRows = newRows.filter(row => row.email !== '' || row.count !== '');
        const updatedRows = [...rows, ...validRows.map(row => ({ email: row.email, count: row.count }))];
        const updatedErrors = [...rowErrors, ...validRows.map(row => row.error || {})];

        setRows(updatedRows);
        dispatch(setRowErrors(updatedErrors)); 

        validRows.forEach(row => {
            dispatch(addEmailTask({ email: row.email, count: row.count }));
        });
    
        if (validRows.length > 0) {
            handleSnackbar('Emails and counts pasted successfully!', 'success');
        }
    
        setIsPasting(false);
        setIsPastingParent(false);
    };       

    const handleBackToSelection = () => {
        if (emailTasks.length > 0) {
            const confirmLeave = window.confirm('You will lose unsaved changes. Are you sure you want to leave?');
            if (confirmLeave) {
                setCurrentView('previous');
                setSelectedBox(null);
                dispatch(clearEmailTasks());
            }
        } else {
            setCurrentView('previous');
            setSelectedBox(null);
            dispatch(clearEmailTasks());
        }
    };

    // useEffect(() => {
    //     dispatch(clearEmailTasks());
    // }, [location.pathname, dispatch]);

    const handleCellEdit = (index: number, column: 'email' | 'count', value: string) => {
        const updatedRows = rows.map((row, i) =>
            i === index ? { ...row, [column]: value } : row
        );
        setRows(updatedRows);

        const updatedError: RowError = {
            ...rowErrors[index],
            [column]: column === 'email' ? !emailRegex.test(value) : !isValidTaskCount(value)
        };

        dispatch(updateRowError({ index, error: updatedError }));

        if (!updatedError.email && !updatedError.count) {
            const updatedTask = {
                email: updatedRows[index].email,
                count: updatedRows[index].count,
            };
            dispatch(updateEmailTask({ index, task: updatedTask }));
        }
    };

    const handleDeleteRow = (index: number) => {
        const updatedRows = rows.filter((_, i) => i !== index);
        setRows(updatedRows);
        setRowErrors(rowErrors.filter((_, i) => i !== index));
        dispatch(deleteEmailTask(index));
        handleSnackbar('Deleted Successfully', 'success');
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
      };
    
      const handleSnackbar = (message: string, severity: 'success' | 'error' | 'info' | 'warning') => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
      };

    return (
        <>
            {activeStep === 2 && (
                <div style={{ marginLeft: '40%', fontSize: '20px' }}>
                    <Chip label={`Unallocated Task Count ${unallocatedTaskCount}`} variant="outlined" style={{ fontSize: '16px', padding: '10px 10px', backgroundColor: '#3D85C6', color: 'white' }} />
                </div>
            )}
            {currentView === 'current' && activeStep !== 2 && <ArrowBackIosNewTwoToneIcon onClick={handleBackToSelection} sx={{ cursor: 'pointer' }} />}
            {currentView === 'previous' && <Type2AllocationSteps selectedBox={selectedBox} setSelectedBox={setSelectedBox} />}
            {currentView === 'current' &&
                <>
                    {activeStep !== 2 &&
                        <TextField
                            multiline
                            variant="outlined"
                            onPaste={handlePaste}
                            placeholder="Paste data here (from Excel)"
                            size='small'
                            sx={{

                                padding: '10px',
                                marginTop: '20px',
                                textAlign: 'center',
                                cursor: 'text',
                                width: '50%',
                                marginLeft: '25%',
                            }}
                        />
                    }
                    <TableContainer component={Paper} style={{ maxWidth: 800, margin: 'auto', marginTop: 20 }}>
                        <Table className={classes.tableContainer} aria-label="simple table">
                            <TableHeader headers={["EMAILS", "NOT STARTED TASK COUNT", "ACTION"]} />
                            <TableBody>
                                {rows.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCellBody>
                                            <TextField
                                                value={row.email}
                                                onChange={(e) => handleCellEdit(index, 'email', e.target.value)}
                                                placeholder="Enter email"
                                                fullWidth
                                                size="small"
                                                error={rowErrors[index]?.email}
                                                helperText={rowErrors[index]?.email ? "Invalid email" : ""}
                                                sx={{ borderColor: rowErrors[index]?.email ? 'red' : 'inherit' }}
                                            />
                                        </TableCellBody>
                                        <TableCellBody>
                                            <TextField
                                                value={row.count}
                                                onChange={(e) => handleCellEdit(index, 'count', e.target.value)}
                                                placeholder="Enter task count"
                                                fullWidth
                                                size="small"
                                                error={rowErrors[index]?.count}
                                                helperText={rowErrors[index]?.count ? "Invalid task count" : ""}
                                                sx={{ borderColor: rowErrors[index]?.count ? 'red' : 'inherit' }}
                                            />
                                        </TableCellBody>
                                        <TableCellBody>
                                            <DeleteOutlineOutlinedIcon sx={{ color: '#C00F00', cursor: 'pointer', marginRight: '5px' }} onClick={() => handleDeleteRow(index)} />
                                        </TableCellBody>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {emailTasks.length === 0 && activeStep !== 2 &&
                        <div style={{ marginTop: '30px' }}>
                            <Error message={'No data added as of yet. You can paste email and count to see it here.'} />
                        </div>
                    }
                    <SnackbarNotification
                        open={snackbarOpen}
                        message={snackbarMessage}
                        severity={snackbarSeverity}
                        onClose={handleSnackbarClose}
                    />
                </>
            }
        </>
    );
};

export default CopyPasteEmails;
