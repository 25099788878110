import React from 'react';
import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';

interface TaskReviewToggleProps {
  selectedOption: 'TASK' | 'REVIEW';
  onChange: (event: React.MouseEvent<HTMLElement>, newOption: 'TASK' | 'REVIEW') => void;
}

const TaskReviewToggle: React.FC<TaskReviewToggleProps> = ({ selectedOption, onChange }) => {
  return (
    <ToggleButtonGroup
      value={selectedOption}
      exclusive
      onChange={onChange}
      aria-label="task or review"
      sx={{ marginBottom: '10px', marginTop: '10px' }}
    >
      <Tooltip title="If you will select tasker then this config will be added tasker side." arrow>
        <ToggleButton value="TASK" aria-label="task">
          Tasker
        </ToggleButton>
      </Tooltip>
      <Tooltip title="If you will select reviewer then this config will be added reviewer side." arrow>
        <ToggleButton value="REVIEW" aria-label="review">
          Reviewer
        </ToggleButton>
      </Tooltip>
    </ToggleButtonGroup>
  );
};

export default TaskReviewToggle;
