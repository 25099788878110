import { Checkbox, CircularProgress, List, ListItem, ListItemText, Typography } from '@mui/material'
import React, { useState } from 'react'
import { AddButton } from '../../button/button'
import { STATUS } from '../../../utils/status'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store/appStore'

const VendorPermissions = ({ checkedItems, setCheckedItems,  handleAddVendor, createVendorStatus, selectedRow, vendorId, isAddVendorBtnEnable }: any) => {
    const Permissions = useSelector((state: RootState) => state.projects.permissions)
    const association_type = useSelector((state: RootState) => state.projects.association_type)
    let reviewAllocationSingle=false
    let reviewAllocationBulk=false
    let reviewUnallocationSingle=false 
    let reviewUnallocationBulk=false
    if(association_type == 'COMPANY') {
        reviewAllocationSingle = Permissions?.review_allocation[0]
        reviewAllocationBulk = Permissions?.review_allocation[0]
        reviewUnallocationSingle = Permissions?.review_allocation[0]
        reviewUnallocationBulk = Permissions?.review_allocation[0]
    } else {
        reviewAllocationSingle = Permissions?.review_allocation_single?.[0]
        reviewAllocationBulk = Permissions?.review_allocation_bulk?.[0]
        reviewUnallocationSingle = Permissions?.review_unallocation_single?.[0]
        reviewUnallocationBulk = Permissions?.review_unallocation_bulk?.[0]
    }

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        // console.log({ name, checked })

        setCheckedItems((prev:any) => {
            switch (name) {
                case "allowAll":
                const reviewPermissionsFalse = !reviewAllocationSingle || !reviewAllocationBulk || !reviewUnallocationSingle || !reviewUnallocationBulk;
                    return {
                        export_data: [checked],
                        task_allocation_single: [checked],
                        task_allocation_bulk: [checked],
                        task_unallocation_single: [checked],
                        task_unallocation_bulk: [checked],

                        review_allocation_single: reviewAllocationSingle === false ? [false] : [checked],
                        review_allocation_bulk: reviewAllocationBulk === false ? [false] : [checked],
                        review_unallocation_single: reviewUnallocationSingle === false ? [false] : [checked],
                        review_unallocation_bulk: reviewUnallocationBulk === false ? [false] : [checked],

                        show_completed_task_details: [checked],
                        project_analytics: [checked],
                    };

                case "allowExportData":
                    return {
                        ...prev,
                        export_data: [checked],
                    };

                case "allowProjetAnalytics":
                    return {
                        ...prev,
                        project_analytics: [checked],
                    };

                case "allowTasking":
                    return {
                        ...prev,
                        ...prev,
                        task_allocation_single: [checked],
                        task_allocation_bulk: [checked],
                        task_unallocation_single: [checked],
                        task_unallocation_bulk: [checked],
                    };

                case "singleTaskAllocation":
                case "bulkTaskAllocation":
                    return {
                        ...prev,
                        task_allocation_single: name === "singleTaskAllocation" ? [checked] : prev.task_allocation_single,
                        task_allocation_bulk: name === "bulkTaskAllocation" ? [checked] : prev.task_allocation_bulk,
                    };

                case "singleTaskUnallocation":
                case "bulkTaskUnallocation":
                    return {
                        ...prev,
                        task_unallocation_single: name === "singleTaskUnallocation" ? [checked] : prev.task_unallocation_single,
                        task_unallocation_bulk: name === "bulkTaskUnallocation" ? [checked] : prev.task_unallocation_bulk,
                    };


                case "allowReviewing":
                    return {
                        ...prev,
                        review_allocation_single: reviewAllocationSingle === false ? [false] : [checked],
                        review_allocation_bulk: reviewAllocationBulk === false ? [false] : [checked],
                        review_unallocation_single: reviewUnallocationSingle === false ? [false] : [checked],
                        review_unallocation_bulk: reviewUnallocationBulk === false ? [false] : [checked],
                    };

                case "singleReviewAllocation":
                case "bulkReviewAllocation":
                    return {
                        ...prev,
                        review_allocation_single: name === "singleReviewAllocation" ? [checked] : prev.review_allocation_single,
                        review_allocation_bulk: name === "bulkReviewAllocation" ? [checked] : prev.review_allocation_bulk,
                    };

                case "singleReviewUnallocation":
                case "bulkReviewUnallocation":
                    return {
                        ...prev,
                        review_unallocation_single: name === "singleReviewUnallocation" ? [checked] : prev.review_unallocation_single,
                        review_unallocation_bulk: name === "bulkReviewUnallocation" ? [checked] : prev.review_unallocation_bulk,
                    };

                case "viewCompletedTaskDetails":
                    return {
                        ...prev,
                        show_completed_task_details: [checked],
                    };
                default:
                    return prev;
            }
        });
    };

    return (
        <>
            <div style={{ marginTop: "20px", display: 'flex' }}>
                <Typography sx={{ marginTop: '10px', marginRight: '10px', fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}>Allow Permissions : </Typography>
                <Checkbox
                    name="allowAll"
                    checked={
                        checkedItems.export_data[0] &&
                        checkedItems.task_allocation_single[0] &&
                        checkedItems.task_allocation_bulk[0] &&
                        checkedItems.task_unallocation_single[0] &&
                        checkedItems.task_unallocation_bulk[0] &&
                        checkedItems.review_allocation_single[0] &&
                        checkedItems.review_allocation_bulk[0] &&
                        checkedItems.review_unallocation_single[0] &&
                        checkedItems.review_unallocation_bulk[0] &&
                        checkedItems.show_completed_task_details[0] &&
                        checkedItems.project_analytics[0]
                    }
                    disabled={
                        association_type == 'COMPANY' ? (
                            !Permissions.export_data[0] ||
                            !Permissions.task_allocation[0] ||
                            !Permissions.review_allocation[0] ||
                            !Permissions.project_details[0] ||
                            !Permissions.project_config[0] ||
                            !Permissions.project_payments[0] ||
                            !Permissions.project_analytics[0] ||
                            !Permissions.project_users[0] ||
                            !Permissions.completing_task_action_details[0] ||
                            !(Permissions.completing_task_action_details[1] == 'read') ||
                            !(Permissions.completing_task_action_details[2] == 'edit')
                        )   :   (
                            !Permissions.export_data[0] ||
                            !Permissions.task_allocation_single[0] ||
                            !Permissions.task_allocation_bulk[0] ||
                            !Permissions.task_unallocation_single[0] ||
                            !Permissions.task_unallocation_bulk[0] ||
                            !Permissions.review_allocation_single[0] ||
                            !Permissions.review_allocation_bulk[0] ||
                            !Permissions.review_unallocation_single[0] ||
                            !Permissions.review_unallocation_bulk[0] ||
                            !Permissions.show_completed_task_details[0] ||
                            !Permissions.project_analytics[0]
                        )
                            
                    }
                    onChange={handleCheckboxChange}
                    sx={{ marginLeft: '3px' }}
                />
                <span style={{ fontSize: "13px", marginTop: '1%' }} >All permissions</span>
            </div>

            <div style={{ display: 'flex', marginTop: '5px' }}>
                <Typography sx={{ marginTop: '17px', marginRight: '10px', fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}>Export Permission : </Typography>
                <List>
                    <Checkbox
                        name="allowExportData"
                        checked={checkedItems.export_data[0]}
                        disabled={!Permissions.export_data[0]}
                        onChange={handleCheckboxChange}
                        sx={{ marginLeft: '3px' }}
                    />
                    <span style={{ fontSize: "13px" }} >Export data</span>

                </List>
            </div>

            <div style={{ display: 'flex' }}>
                <Typography sx={{ marginTop: '17px', marginRight: '10px', fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}>Project Permission : </Typography>
                <List>
                    <Checkbox
                        name="allowProjetAnalytics"
                        checked={checkedItems.project_analytics[0]}
                        onChange={handleCheckboxChange}
                        disabled={!Permissions.project_analytics[0]}
                        sx={{ marginLeft: '0.5px' }}
                    />
                    <span style={{ fontSize: "13px" }} >Project analytics</span>

                </List>
            </div>

            <div style={{ position: "relative", marginTop: '10px' }}>
                <Typography sx={{ marginTop: '5px', marginRight: '10px', fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 600, lineHeight: '24px', letterSpacing: '0px', textAlign: 'left', color: '#10383D' }}>Task permissions : </Typography>
                <List>

                    <Checkbox
                        name="allowTasking"
                        checked={
                            checkedItems?.task_allocation_single[0] &&
                            checkedItems?.task_allocation_bulk[0] &&
                            checkedItems?.task_unallocation_single[0] &&
                            checkedItems?.task_unallocation_bulk[0]
                        }
                        disabled={
                            association_type == 'COMPANY' ? !Permissions.task_allocation[0] :
                            (
                                !Permissions?.task_allocation_single[0] &&
                                !Permissions?.task_allocation_bulk[0] &&
                                !Permissions?.task_unallocation_single[0] &&
                                !Permissions?.task_unallocation_bulk[0]
                            )
                        }
                        onChange={handleCheckboxChange}
                    />
                    <span style={{ fontSize: "13px", paddingTop: "35px", position: "absolute", top: "-16px" }} >Allow Tasking</span>
                </List>

                <div style={{ marginTop: "-20px", position: "relative" }} >
                    <List>
                        <ListItem>
                            <Checkbox
                                name="singleTaskAllocation"
                                checked={checkedItems.task_allocation_single[0]}
                                disabled={association_type == 'COMPANY' ? !Permissions.task_allocation[0] : !Permissions.task_allocation_single[0]}
                                onChange={handleCheckboxChange}
                            />
                            <ListItemText style={{}} primary={<h1 style={{ fontSize: "13px" }} >Single task allocation</h1>} />

                            <Checkbox
                                name="bulkTaskAllocation"
                                checked={checkedItems.task_allocation_bulk[0]}
                                disabled={association_type == 'COMPANY' ? !Permissions.task_allocation[0] : !Permissions.task_allocation_bulk[0]}
                                onChange={handleCheckboxChange}
                            />


                            <ListItemText style={{}} primary={<h1 style={{ fontSize: "13px" }} >Bulk task allocation</h1>} />

                            <Checkbox
                                name="singleTaskUnallocation"
                                checked={checkedItems.task_unallocation_single[0]}
                                disabled={association_type == 'COMPANY' ? !Permissions.task_allocation[0] : !Permissions.task_unallocation_single}
                                onChange={handleCheckboxChange}
                            />

                            <ListItemText style={{}} primary={<h1 style={{ fontSize: "13px" }} >Single task unallocation</h1>} />

                            <Checkbox
                                name="bulkTaskUnallocation"
                                checked={checkedItems.task_unallocation_bulk[0]}
                                disabled={association_type == 'COMPANY' ? !Permissions.task_allocation[0] : !Permissions.task_unallocation_bulk[0]}
                                onChange={handleCheckboxChange}
                            />

                            <ListItemText style={{}} primary={<h1 style={{ fontSize: "13px" }} >Bulk task unallocation</h1>} />
                        </ListItem>

                    </List>
                </div>
            </div>
            <div style={{ paddingTop: "0px", position: "relative" }}>
                <List>
                    <Checkbox
                        name="allowReviewing"
                        checked={
                            checkedItems.review_allocation_single[0] &&
                            checkedItems.review_allocation_bulk[0] &&
                            checkedItems.review_unallocation_single[0] &&
                            checkedItems.review_unallocation_bulk[0]
                        }
                        disabled={
                            association_type == 'COMPANY' ? !Permissions.review_allocation[0] : 
                            (
                                !Permissions?.review_allocation_single[0] &&
                                !Permissions?.review_allocation_bulk[0] &&
                                !Permissions?.review_unallocation_single[0] &&
                                !Permissions?.review_unallocation_bulk[0]
                            )

                        }
                        onChange={handleCheckboxChange}
                    />
                    <span style={{ fontSize: "13px", paddingTop: "35px", position: "absolute", top: "-16px" }} >Allow Reviewing</span>
                </List>

                <div style={{ marginTop: "-20px", position: "relative" }} >
                    <List>
                        <ListItem>

                                <Checkbox
                                    name="singleReviewAllocation"
                                    checked={checkedItems.review_allocation_single[0]}
                                    onChange={handleCheckboxChange}
                                    disabled={reviewAllocationSingle === false}
                                />
                                <ListItemText disableTypography={reviewAllocationSingle}
                                    primary={<h1 style={{ fontSize: "13px" }}>Single review allocation</h1>}
                                />

                            <Checkbox
                                name="bulkReviewAllocation"
                                checked={checkedItems.review_allocation_bulk[0]}
                                onChange={handleCheckboxChange}
                                disabled={reviewAllocationBulk === false}
                            />

                            <ListItemText style={{}} primary={<h1 style={{ fontSize: "13px" }} >Bulk review allocation</h1>} />

                            <br />
                            <Checkbox
                                name="singleReviewUnallocation"
                                checked={checkedItems.review_unallocation_single[0]}
                                onChange={handleCheckboxChange}
                                disabled={reviewUnallocationSingle === false}
                            />

                            <ListItemText style={{}} primary={<h1 style={{ fontSize: "13px" }} >Single review unallocation</h1>} />
                            <Checkbox
                                name="bulkReviewUnallocation"
                                checked={checkedItems.review_unallocation_bulk[0]}
                                onChange={handleCheckboxChange}
                                disabled={reviewUnallocationBulk === false}
                            />
                            <ListItemText style={{}} primary={<h1 style={{ fontSize: "13px" }} >Bulk review unallocation</h1>} />
                        </ListItem>

                    </List>
                </div>
            </div>

            <div>
                <List>

                    <Checkbox
                        name="viewCompletedTaskDetails"
                        checked={checkedItems.show_completed_task_details[0]}
                        disabled={association_type == 'COMPANY' ? !Permissions.completing_task_action_details[0] : !Permissions.show_completed_task_details[0]}
                        onChange={handleCheckboxChange}
                    />
                    <span style={{ fontSize: "13px", paddingTop: "35px", position: "absolute", top: "-16px" }} >View completed task details</span>
                </List>
            </div>
        </>
    )
}

export default VendorPermissions