import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress, Button, Divider, MenuItem, Menu, Drawer, Typography, IconButton, Box, Tooltip, Badge, DialogActions, Dialog } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store/appStore';
import { STATUS } from '../../utils/status';
import { useNavigate, useParams } from 'react-router-dom';
import { TableCellHead } from '../table/tableCell';
import PaginationEntries from '../pagination/paginationEntries';
import { useStyles } from '../../assets/styles/listTableStyle';
import TableRowItem from './taskUploadRowItem';
import DeleteModal from '../modal/deleteModal';
import { getUpload, updateUpload, createAllocation, setCreateSingleAllocationStatus } from '../../redux/slices/upload';
import Error from '../error/error';
import Pagination from './taskPagination';
import AllocationModal from './allocateModal';
import InfoModal from './infoModal';
import { getTaskers } from '../../redux/slices/expertSlice/addExpertSlice';
import { setIds } from '../../redux/reducers/taskIdSlice';
import ConfirmationDialog from './confirmationModal';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { clearEmails } from '../../redux/reducers/selectedEmail';
import SnackbarNotification from '../snackbar/snackbarNotification';
import InfoModalReview from './infoModalReview';
import { clearEmailss } from '../../redux/reducers/selectedEmailReviewer';
import TaskCompleted from '../taskCompleted/taskCompleted';
// import TaskCompleted, { getCompletedTask } from '../../redux/slices/taskCompleted';
// import TaskCompleted from '../uploadTask/'
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import debounce from 'lodash.debounce';
import { getVendorAction } from '../../redux/slices/vendor';
import { getTaskData, setGetTaskDataStatus } from '../../redux/slices/taskData';
import InfoModelTaskUnallocation from './infoModelTaskUnallocation';
import { getConfigsData, setGetConfigDataStatus } from '../../redux/slices/config';
import Filter from '../Filters/Filter';
import { setCreateMetaQC } from '../../redux/slices/upload';

const getUniqueTaskers = (taskers: any[]) => {
  const uniqueTaskers: any[] = [];
  const seenEmails = new Set<string>();

  taskers && taskers.forEach(tasker => {
    if (!seenEmails.has(tasker.email)) {
      uniqueTaskers.push(tasker);
      seenEmails.add(tasker.email);
    }
  });

  return uniqueTaskers;
};

interface Filters {
  taskName: string;
  taskAllocation: string;
  reviewAllocation: string;
  pseudoname: string;
  selectedVendor: string;
  selectedTaskType: any;
  selectedStatus: any;

}

const TaskUploadTable = () => {
  const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { id } = useParams<string>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedOption] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [openInfoModalReview, setOpenInfoModalReview] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedEmail, setSelectedEmail] = useState<string | null>(null);
  const getStatus = useSelector((state: RootState) => state.upload.getStatus);
  const { getTaskDataStatus, taskData } = useSelector((state: RootState) => state.taskData);
  const selectedEmails = useSelector((state: RootState) => state.selectedEmails);
  const selectedEmailsReview = useSelector((state: RootState) => state.selectedEmailsReview);
  const deadline = useSelector((state: RootState) => state.deadline.deadline)
  const selectedIds = useSelector((state: RootState) => state.tasks.selectedIds);
  const upload = useSelector((state: RootState) => state.upload.upload);
  const [filteredTaskers, setFilteredTaskers] = useState<any[]>([]);
  const [filteredReviewers, setFilteredReviewers] = useState<any[]>([]);
  const [allocationType] = useState('');
  const selectedTasksWithoutAllocation = useSelector((state: RootState) => state.allocationTask.tasksWithoutAllocation);
  const selectedTasksWithAllocation = useSelector((state: RootState) => state.allocationTask.tasksWithAllocation);
  const selectedReviewsWithoutAllocation = useSelector((state: RootState) => state.allocationTask.reviewWithoutAllocation);
  const fetchPermissions = useSelector((state: RootState) => state.projects.permissions);
  const association_type = useSelector((state: RootState) => state.projects.association_type);
  const association_type_company = association_type && association_type === 'COMPANY' ? true : false;
  const association_type_vendor = association_type && association_type === 'VENDOR' ? true : false;
  const [isUnsavedModalOpen, setIsUnsavedModalOpen] = useState(false);

  const { taskers: tasker_wrapper } = useSelector((state: RootState) => state.projects.projects as any);
  const { reviewers: reviewer_wrapper, project: projectDetails } = useSelector((state: RootState) => state.projects as any);
  const { getConfigDataStatus, configData } = useSelector((state: RootState) => state.configData)

  let taskers = tasker_wrapper && tasker_wrapper.map((tasker: any) => tasker.expert)
  taskers = getUniqueTaskers(taskers)

  let reviewers = reviewer_wrapper === undefined ? [] : reviewer_wrapper.map((reviewer: any) => reviewer.expert)
  reviewers = getUniqueTaskers(reviewers)

  const Vendors = useSelector((state: RootState) => state.vendors.vendors)
  const getVendorStatus = useSelector((state: RootState) => state.vendors.getVendorsStatus)

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info' | 'warning'>('success');
  const createStatus = useSelector((state: RootState) => state.allocation.createStatus);
  const createMetaQCStatus = useSelector((state: RootState) => state.upload.createMetaQCStatus);
  let [type] = useState("")
  const [drawer, setDrawer] = useState(false);
  const [currentTaskID, setcurrentTaskID] = useState<string>('')
  const [anchorE2, setAnchorE2] = useState(null);
  const [anchorE3, setAnchorE3] = useState(null);
  const [selectedValue] = useState('');
  const [value, setValue] = useState('1')
  const [searchType, setSearchType] = useState("")
  const [taskName, setTaskName] = useState("")
  const [taskAllocation, setTaskAllocation] = useState("")
  const [reviewAllocation, setReviewAllocation] = useState("")
  const [pseudoname, setPseudoName] = useState("")
  const [filterCount, setFilterCount]: any = useState(0)
  const [filterCountApplied, setFilterCountApplied]: any = useState(0)
  const [filterInFocus, setfilterInFocus] = useState('')
  const [appliedFilterValues, setAppliedFilterValues] = useState<string[]>([]);
  const [byTaskListOpen, setByTaskListOpen] = useState(false)
  const [byReviewListOpen, setByReviewListOpen] = useState(false)
  const [vendorId, setVendorId] = useState("")
  const [options, setOptions] = useState<any[]>([]);
  const [open, setOpen] = React.useState(false);
  const [openAutoComplete, setOpenAutoComplete] = React.useState(false);
  const [selectedVendor, setSelectedVendor] = useState<any>(null)
  const [selectedTaskType, setSelectedTaskType] = useState(null)
  const [selectedStatus, setSelectedStatus] = useState<string[]>([])
  const [searchTermAutocomplete, setSearchTermAutocomplete] = useState("")
  const [bySelectVendorOpen, setBySelectVendorOpen] = useState(false)
  const [taskNameListOpen, setTaskNameListOpen] = useState(false)
  const [taskEmailNameListOpen, setTaskEmailNameListOpen] = useState(false)
  const [openList, setOpenList] = useState<string | null>(null);
  const [openTaskUnallocationModel, setOpenTaskUnallocationModel]: any = useState(false)
  const [openReviewUnallocationModel, setOpenReviewUnallocationModel]: any = useState(false)
  const updateCompletedTaskStatus = useSelector((state: RootState) => state.taskCompleted.updateCompletedTaskStatus)
  const [filterTypes, setFilterType] = useState("")

  useEffect(() => {
    dispatch(getVendorAction({
      is_vendorblock: false,
      search_query: searchTermAutocomplete
    }))
  }, [dispatch, searchTermAutocomplete])
  


  useEffect(() => {
    const handleCreateStatus = () => {
      if (createStatus === STATUS.SUCESS) {
        // dispatch(getUpload(
        //   {
        //     project_id: id || '',
        //     pageSize: itemsPerPage
        //   }))
      }

      if (createStatus === STATUS.FAILED) {
        handleSnackbar('Error Occurred, Please try again!', 'error');
      }
    };

    handleCreateStatus();
    return () => {
      if (createStatus === STATUS.SUCESS) {
        dispatch(setCreateSingleAllocationStatus(STATUS.NOT_STARTED));
      }
    };
  }, [createStatus, navigate, dispatch])


  useEffect(() => {
    const handleCreateStatus = () => {
      if (updateCompletedTaskStatus === STATUS.SUCESS) {
        // handleSnackbar('Success', 'success');
        dispatch(getUpload(
          {
            project_id: id || '',
            pageSize: itemsPerPage
          }))
      }

      if (updateCompletedTaskStatus === STATUS.FAILED) {
        handleSnackbar('Error Occurred, Please try again!', 'error');
      }
    };

    handleCreateStatus();
    return () => {
      if (updateCompletedTaskStatus === STATUS.SUCESS || updateCompletedTaskStatus === STATUS.FAILED) {
        dispatch(setCreateSingleAllocationStatus(STATUS.NOT_STARTED));
      }
    };
  }, [updateCompletedTaskStatus, navigate, dispatch])

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleTaskNameToggle = (listName: string) => {
    setTaskNameListOpen(!taskNameListOpen);
    setfilterInFocus("task_name");
    setOpenList((prev) => (prev === listName ? null : listName))
  };
  const resetTaskName = () => {
    setTaskName("")
  };

  const handleTaskEmailNameToggle = (listName: string) => {
    setTaskEmailNameListOpen(!taskEmailNameListOpen);
    setfilterInFocus("task_allocation");
    setOpenList((prev) => (prev === listName ? null : listName))
  };
  const resetTaskEmailName = () => {
    setTaskAllocation("");
  };

  const resetTaskType = () => {
    setSelectedTaskType(null);
    setByTaskListOpen(false)
  }
  const resetStatus = () => {
    setSelectedStatus([])
    setByReviewListOpen(false)
  }
  const resetSelectVendor = () => {
    setSearchTermAutocomplete("")
    setSelectedVendor(null)
    setBySelectVendorOpen(false)
  }

  // List Open Close
  const handleByTaskListClick = (listName: string) => {
    setByTaskListOpen(!byTaskListOpen)
    setfilterInFocus("by_task_list_open");
    setOpenList((prev) => (prev === listName ? null : listName))
  }
  const handleByReviewListClick = (listName: string) => {
    setByReviewListOpen(!byReviewListOpen)
    setfilterInFocus("by_review_list_open");
    setOpenList((prev) => (prev === listName ? null : listName))
  }
  const handleBySelectVendorClick = (listName: string) => {
    setBySelectVendorOpen(!bySelectVendorOpen)
    setfilterInFocus("by_select_vendor_open");
    setOpenList((prev) => (prev === listName ? null : listName))
  }

  const handleSnackbar = (message: string, severity: 'success' | 'error' | 'info' | 'warning') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };


  const handleMenuClick = (event: any) => {
    setAnchorE2(event.currentTarget);
  };

  const handleMenuItemClick = (option: string) => {
    switch (option) {
      case 'Task Allocation':
        handleOpenVideoModal(option);
        break;
      case 'Task Unallocation':
        handleOpenVideoModal(option);
        break;
      case 'Review Allocation':
        handleOpenVideoModal(option);
        break;
      case 'Review Unallocation':
        handleOpenVideoModal(option);
        break;
      case 'Download Task Data':
        handleDownloadBtn();
        break;
      case 'Upload Task':
        handleAddClick();
        break;
      case 'Delivered Task':
        handleDeliveredAddClick();
        break;
      case 'Config Data':
        handleConfigData();
        break;
      default:
        break;
    }
  };

  const handleMenuClose = () => {
    setAnchorE2(null);
  };

  useEffect(() => {
    dispatch(getTaskers({ projectId: id || '' }));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getUpload(
      {
        search: searchTerm,
        project_id: id || '',
        page: currentPage,
        type: filterTypes,
        pageSize: itemsPerPage,
        allocated_task_type: selectedTaskType,
        statuses: selectedStatus,
        vendor_id: vendorId
      }
    ));
  }, [dispatch, searchTerm, id, currentPage, itemsPerPage, searchTerm, type]);

  useEffect(() => {
    if (type == 'get_completed_tasks' || type == 'get_unallocated_tasks') {
      dispatch(getUpload({ search: searchTerm, project_id: id || '', page: currentPage, type: type, pageSize: itemsPerPage }));
    }
  }, [type])

  const redirectingAllocationPage = (option?: string) => {
    // console.log(option)
    navigate(`/allocation/${id}/${option}`);
  };

  const handleOpenVideoModal = (option: string) => {
    if (option === 'Task Allocation') {
      redirectingAllocationPage(option);
    }
    if (option === 'Task Unallocation') {
      redirectingAllocationPage(option);
    }
    if (option === 'Review Allocation') {
      redirectingAllocationPage(option);
    }
    if (option === 'Review Unallocation') {
      redirectingAllocationPage(option);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);

    if (allocationType === 'TASK') {
      dispatch(clearEmails());
    } else {
      dispatch(clearEmailss());
    }

    if (allocationType === 'TASK') {
      setOpenInfoModal(true)
    } else {
      setOpenInfoModalReview(true)
    }
  };

  const handleCloseInfoModal = () => {
    setOpenInfoModal(false);
  };

  const handleCloseInfoModalReview = () => {
    setOpenInfoModalReview(false);
  };

  const handleCancelConfirmation = () => {
    setOpenConfirmationDialog(false);
    setOpenModal(true)
  };

  const handleEmailClick = (email: string) => {
    setSelectedEmail(email);
  };

  const handleAllocateClick = () => {
    setOpenConfirmationDialog(true)
    setOpenModal(false)
    setOpenInfoModal(false)
    setOpenInfoModalReview(false)
  };

  const handleConfirmAllocation = () => {
    const payload = {
      project_id: id,
      task_ids: allocationType === 'TASK' ?
        selectedTasksWithoutAllocation.map((task) => task.id)
        : allocationType === 'REVIEW' ?
          selectedTasksWithAllocation
            .filter(task =>
              selectedReviewsWithoutAllocation.some(review => review.id === task.id)
            )
            .map(task => task.id)
          : [],
      expert_ids: allocationType === 'TASK' ? selectedEmails : selectedEmailsReview,
      type: allocationType,
      deadline: deadline,
      action: "assign"
    }

    dispatch(createAllocation(payload))
    handleCloseModal();
    setOpenInfoModal(false);
    setOpenInfoModalReview(false);
    setOpenConfirmationDialog(false)
  }

  useEffect(() => {
    const handleCreateStatus = () => {
      if (createStatus === STATUS.SUCESS) {
        // handleSnackbar('Success', 'success');
        // window.location.reload()
        //dispatch(getUpload({ search: searchTerm, project_id: id || '', pageSize: itemsPerPage, page: currentPage, type: type }))
      }

      if (createStatus === STATUS.FAILED) {
        handleSnackbar('Error Occurred, Please try again!', 'error');
      }
    };

    handleCreateStatus();
    return () => {
      if (createStatus === STATUS.SUCESS) {
        dispatch(setCreateSingleAllocationStatus(STATUS.NOT_STARTED));
      }
    };
  }, [createStatus, navigate, dispatch]);


  useEffect(() => {
    const handleGetStatus = () => {
      if (getStatus === STATUS.PENDING) {
        setLoading(true)
      }
      if (getStatus === STATUS.SUCESS) {
        setLoading(false)
      }
      if (getStatus === STATUS.FAILED) {
        setLoading(false)
      }
    };

    handleGetStatus();
  }, [getStatus])

  const handleNextClick = () => {
    setOpenModal(true);
  };

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(1)
    setSearchTerm(event.target.value);
  };
  const handleSearchChangeAutoComplete = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTermAutocomplete(event.target.value)
  }

  const cancelDelete = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteClick = (userId: string) => {
    setUserIdToDelete(userId);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    setShowDeleteModal(false);
    try {
      await dispatch(updateUpload({ id: userIdToDelete, is_deleted: true }));
      dispatch(getUpload({ search: searchTerm, project_id: id || '', pageSize: itemsPerPage, page: currentPage }));
    } catch (error) {
      console.error('Failed to delete config', error);
    }
  };

  useEffect(() => {
    if (allocationType === 'TASK') {
      setFilteredTaskers(
        taskers.filter((tasker: { name: string; email: string; }) =>
        (tasker.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          tasker.email?.toLowerCase().includes(searchQuery.toLowerCase()))
        )
      );
    } else if (allocationType === 'REVIEW') {
      setFilteredReviewers(
        reviewers.filter((reviewer: { name: string; email: string; }) =>
        (reviewer.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          reviewer.email?.toLowerCase().includes(searchQuery.toLowerCase()))
        )
      );
    }
  }, [searchQuery, allocationType, type]);

  const handleAddClick = () => {
    navigate(`/uploadtask/${id}`);
  };

  const handleDeliveredAddClick = () => {
    navigate(`/deliveredTask/${id}`);
  };

  const handleEditClick = (row: any) => {
    // Handle edit click
  };

  const handleChangeRowsPerPage = (value: number) => {
    dispatch(getUpload({
      search: searchTerm,
      project_id: id || "",
      page: currentPage,
      type: filterTypes,
      pageSize: value as number,
      allocated_task_type: selectedTaskType,
      statuses: selectedStatus,
      vendor_id: vendorId
    }));
    setItemsPerPage(value as number);
    setCurrentPage(1);
    // dispatch(getUpload({ search: searchTerm, page: 1, pageSize: event.target.value as number, project_id: id || "" }));
  };

  const headers =
    [
      "TASK NAME",
      ...(association_type_company ? ["META QC STATUS"] : []),
      ((
        association_type_vendor &&
        (
          (
            Object.keys(fetchPermissions).includes('task_allocation_single') &&
            fetchPermissions['task_allocation_single'][0] === true
          ) ||
          (
            Object.keys(fetchPermissions).includes('task_allocation_bulk') &&
            fetchPermissions['task_allocation_bulk'][0] === true
          ) ||
          (
            Object.keys(fetchPermissions).includes('task_unallocation_single') &&
            fetchPermissions['task_unallocation_single'][0] === true
          ) ||
          (
            Object.keys(fetchPermissions).includes('task_unallocation_bulk') &&
            fetchPermissions['task_unallocation_bulk'][0] === true
          )
        )
      ) ||
        (
          association_type_company &&
          (
            Object.keys(fetchPermissions).includes('task_allocation') &&
            fetchPermissions['task_allocation'][0] == true
          )
        )
      ) ? "TASK STATUS" : undefined,


      ((
        association_type_vendor &&
        (
          (
            Object.keys(fetchPermissions).includes('review_allocation_single') &&
            fetchPermissions['review_allocation_single'][0] === true
          ) ||
          (
            Object.keys(fetchPermissions).includes('review_unallocation_single') &&
            fetchPermissions['review_unallocation_single'][0] === true
          ) ||
          (
            Object.keys(fetchPermissions).includes('review_unallocation_single') &&
            fetchPermissions['review_unallocation_single'][0] === true
          ) ||
          (
            Object.keys(fetchPermissions).includes('review_unallocation_bulk') &&
            fetchPermissions['review_unallocation_bulk'][0] === true
          )
        )
      ) ||
        (
          association_type_company &&
          (
            Object.keys(fetchPermissions).includes('review_allocation') &&
            fetchPermissions['review_allocation'][0] == true
          )
        )

      ) ? "REVIEW STATUS" : undefined,

      ((
        association_type_vendor &&
        (
          (
            Object.keys(fetchPermissions).includes('task_allocation_single') &&
            fetchPermissions['task_allocation_single'][0] === true
          ) ||
          (
            Object.keys(fetchPermissions).includes('task_allocation_bulk') &&
            fetchPermissions['task_allocation_bulk'][0] === true
          ) ||
          (
            Object.keys(fetchPermissions).includes('task_unallocation_single') &&
            fetchPermissions['task_unallocation_single'][0] === true
          ) ||
          (
            Object.keys(fetchPermissions).includes('task_unallocation_bulk') &&
            fetchPermissions['task_unallocation_bulk'][0] === true
          )
        )
      ) ||
        (
          association_type_company &&
          (
            Object.keys(fetchPermissions).includes('task_allocation') &&
            fetchPermissions['task_allocation'][0] == true
          )
        )
      ) ? "TASKER PSEUDO NAME" : undefined,

      ((
        association_type_vendor &&
        (
          (
            Object.keys(fetchPermissions).includes('task_allocation_single') &&
            fetchPermissions['task_allocation_single'][0] === true
          ) ||
          (
            Object.keys(fetchPermissions).includes('task_allocation_bulk') &&
            fetchPermissions['task_allocation_bulk'][0] === true
          ) ||
          (
            Object.keys(fetchPermissions).includes('task_unallocation_single') &&
            fetchPermissions['task_unallocation_single'][0] === true
          ) ||
          (
            Object.keys(fetchPermissions).includes('task_unallocation_bulk') &&
            fetchPermissions['task_unallocation_bulk'][0] === true
          )
        )
      ) ||
        (
          association_type_company &&
          (
            Object.keys(fetchPermissions).includes('task_allocation') &&
            fetchPermissions['task_allocation'][0] == true
          )
        )
      ) ? "TASKER ALLOCATION" : undefined,

      ((
        association_type_vendor &&
        (
          (
            Object.keys(fetchPermissions).includes('reivew_allocation_single') &&
            fetchPermissions['review_allocation_single'][0] === true
          ) ||
          (
            Object.keys(fetchPermissions).includes('review_allocation_bulk') &&
            fetchPermissions['review_allocation_bulk'][0] === true
          ) ||
          (
            Object.keys(fetchPermissions).includes('review_unallocation_single') &&
            fetchPermissions['review_unallocation_single'][0] === true
          ) ||
          (
            Object.keys(fetchPermissions).includes('review_unallocation_bulk') &&
            fetchPermissions['review_unallocation_bulk'][0] === true
          )
        )
      ) ||
        (
          association_type_company &&
          (
            Object.keys(fetchPermissions).includes('review_allocation') &&
            fetchPermissions['review_allocation'][0] == true
          )
        )
      ) ? "REVIEWER PSEUDO NAME" : undefined,

      ((
        association_type_vendor &&
        (
          (
            Object.keys(fetchPermissions).includes('review_allocation_single') &&
            fetchPermissions['review_allocation_single'][0] === true
          ) ||
          (
            Object.keys(fetchPermissions).includes('review_unallocation_single') &&
            fetchPermissions['review_unallocation_single'][0] === true
          ) ||
          (
            Object.keys(fetchPermissions).includes('review_unallocation_single') &&
            fetchPermissions['review_unallocation_single'][0] === true
          ) ||
          (
            Object.keys(fetchPermissions).includes('review_unallocation_bulk') &&
            fetchPermissions['review_unallocation_bulk'][0] === true
          )
        )
      ) ||
        (
          association_type_company &&
          (
            Object.keys(fetchPermissions).includes('review_allocation') &&
            fetchPermissions['review_allocation'][0] == true
          )
        )

      ) ? "REVIEW ALLOCATION" : undefined,

      !association_type_vendor ? "ACTIONS" : undefined
    ].filter(header => header != undefined);

  const ByReviewListItems = ["Active", "Completed", "Not Started", "Quality Reject", "Awaiting Review", "Quality Accepted", "Delivered", "Not Allocated"]
  const totalPages = Math.ceil((upload.count || 0) / itemsPerPage);

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleSearchAllocation = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleDoneClick = () => {
    if (selectedOption === 'One') {
      const allIds = (upload.results || []).map(row => ({ id: row.id, task_name: row.task_name }));
      dispatch(setIds(allIds));
    } else {
      dispatch(setIds([]));
    }
    handleClosePopover();
  };

  useEffect(() => {
    handleDoneClick();
  }, [selectedOption]);

  const handleTaskClick = (row: any) => {
    // navigate(`/task/${id}/${row.id}`);
    setDrawer(true)
    setcurrentTaskID(row.id)

  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  const handleDownloadBtn = () => {
    const res = dispatch(getTaskData({ project_id: id, association_type: association_type }));
  }

  const handleConfigData = () => {
    const res = dispatch(getConfigsData({ project_id: id }))
  }

  const handleClick = (event: any) => {
    setAnchorE3(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorE3(null);
    setSearchType("")
  };

  // const handleMenuItemClick = (value: any) => {
  //   setSelectedValue(value);
  //   handleClose(); // Close the menu after selection
  //   setType(value)
  //   // console.log("Selected value:", value); // You can also log or perform any action with the selected value
  // };

  const handleApplyClick = () => {
    let local_type = type
    let local_search_term = searchTerm
    let local_filter_count = 0

    setFilterCountApplied(filterCount)

    if (taskAllocation.length > 0) {
      local_type = "allocation"
      local_search_term = taskAllocation
      setSearchTerm(taskAllocation)
      local_filter_count = local_filter_count + 1
    }

    if (reviewAllocation.length > 0) {
      local_type = "allocation"
      local_search_term = reviewAllocation
      setSearchTerm(reviewAllocation)
      local_filter_count = local_filter_count + 1

    }

    if (taskName.length > 0) {
      local_type = "task_name"
      local_search_term = taskName
      local_filter_count = local_filter_count + 1

    }

    if (pseudoname.length > 0) {
      local_type = "pseudo_name"
      local_search_term = pseudoname
      setSearchTerm(pseudoname)
      local_filter_count = local_filter_count + 1

    }

    if (vendorId) {
      // local_type = local_search_term ? local_type + ',' + 'GET_VENDOR_STATUS' : 'GET_VENDOR_STATUS';
      local_type = local_type + ',' + 'GET_VENDOR_STATUS';
      local_filter_count = local_filter_count + 1
    }
    if (selectedStatus.length > 0) {
      // if(!local_type.includes('GET_VENDOR_STATUS')) {
      // local_type = local_search_term ? local_type + ',' + 'GET_BY_STATUS' : 'GET_BY_STATUS';
      // }
      local_type = local_type + ',' + "GET_BY_STATUS";
      local_filter_count = local_filter_count + 1
    }
    if (selectedTaskType != null) {
      local_filter_count = local_filter_count + 1

    }

    dispatch(getUpload({
      search: local_search_term,
      project_id: id || '',
      page: currentPage,
      type: local_type,
      pageSize: itemsPerPage,
      allocated_task_type: selectedTaskType,
      statuses: selectedStatus,
      vendor_id: vendorId,
    }))
    setAnchorE3(null)
    setFilterCountApplied(local_filter_count)
    setFilterType(local_type)
  }

  const handleFieldChange = (action: string, e: any) => {
    switch (action) {
      case "taskName":
        setTaskName(e.target.value);
        break;
      case "alloc":
        setTaskAllocation(e.target.value);
        break;
      case "reviewAllocation":
        setReviewAllocation(e.target.value);
        break;
      case "pseudoName":
        setPseudoName(e.target.value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const filters: Filters = {
      taskName,
      taskAllocation,
      reviewAllocation,
      pseudoname,
      selectedVendor,
      selectedTaskType,
      selectedStatus,
    };
    const activeFilters: (keyof Filters)[] = Object.keys(filters).filter((key) => filters[key as keyof Filters] !== "") as (keyof Filters)[];
    const activeFilterValues = Object.values(filters).filter((value) => value !== "");

    setFilterCount(activeFilters.length);
    setAppliedFilterValues(activeFilterValues);
  }, [taskName, taskAllocation, reviewAllocation, pseudoname, selectedTaskType, selectedStatus, selectedVendor]);

  useEffect(() => {
    dispatch(getVendorAction({
      search_query: searchTerm,
    }));
  }, [dispatch, searchTerm]);

  useEffect(() => {
    if (getVendorStatus === STATUS.SUCESS) {
      setOptions(Vendors.results)
    }
  }, [getVendorStatus])

  useEffect(() => {
    if (getConfigDataStatus === STATUS.SUCESS) {
      setSnackbarMessage('File Available for download.')
      setSnackbarSeverity('success')
      setSnackbarOpen(true)
      if (typeof configData.data == "string") {
        window.open(configData.data, '_blank')
      }
    }

    if (getConfigDataStatus === STATUS.FAILED) {
      setSnackbarMessage('Couldn\'t Download File. Please Try Again.')
      setSnackbarSeverity('error')
      setSnackbarOpen(true)
    }

    if (getTaskDataStatus === STATUS.SUCESS) {
      setSnackbarMessage('File Available for download.')
      setSnackbarSeverity('success')
      setSnackbarOpen(true)
      if (typeof taskData.data == "string") {
        window.open(taskData.data, '_blank')
      }
    }

    if (getTaskDataStatus === STATUS.FAILED) {
      setSnackbarMessage('Couldn\'t Download File. Please Try Again.')
      setSnackbarSeverity('error')
      setSnackbarOpen(true)
    }

    return () => {
      if (getTaskDataStatus === STATUS.SUCESS || getTaskDataStatus === STATUS.FAILED) {
        dispatch(setGetTaskDataStatus(STATUS.NOT_STARTED))
      }

      if (getConfigDataStatus === STATUS.SUCESS || getConfigDataStatus === STATUS.FAILED) {
        dispatch(setGetConfigDataStatus(STATUS.NOT_STARTED))
      }

    }
  }, [getTaskDataStatus, getConfigDataStatus])

  // const handleOpenTaskSwapping = ()  =>{
  //   setOpenTaskSwapping(true);
  //   setAnchorE2(null);
  // }
  const debouncedResults = useMemo(() => debounce(handleSearchChange, 300), []);
  const debouncedResultsForAutoComplete = useMemo(() => { return debounce(handleSearchChangeAutoComplete, 300); }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });
  useEffect(() => {
    return () => {
      debouncedResultsForAutoComplete.cancel();
    };
  });


  const handleAutocompleteInput = (e: any) => {
    debouncedResultsForAutoComplete(e)
  };

  const handleAutoCompleteOpen = () => {
    setOpenAutoComplete(true);
  };

  const handleAutoCompleteClose = () => {
    setOpenAutoComplete(false);
  };


  const handleTaskTypeSelect = (type: any) => {
    setSelectedTaskType(type);
    setByTaskListOpen(false)
  };

  const handleStatusToggle = (status: any) => {
    setSelectedStatus((prev: any) => {
      if (prev.includes(status)) {
        return prev.filter((item: any) => item !== status);
      } else {
        return [...prev, status];
      }
    });
  }

  const handleVendorAllocation = () => {
    navigate(`/vendorbulkallocation/${id}`)
  }
  const handleVendorUnallocation = () => {
    navigate(`/vendorbulkunallocation/${id}`)
  }

  const handleConfirmClose = () => {
    setDrawer(false);
    setIsUnsavedModalOpen(false);
  };

  const handleCancelClose = () => {
    setIsUnsavedModalOpen(false);
  };

  useEffect(() => {
    const handleCreateQCStatus = () => {
      if (createMetaQCStatus === STATUS.SUCESS) {
        handleSnackbar('Marked Meta QC Successfully', 'success');
      }
      if (createMetaQCStatus === STATUS.FAILED) {
        handleSnackbar('Error Occured, try again', 'error');
      }
    }
    handleCreateQCStatus();
    return () => {
      if (createMetaQCStatus === STATUS.SUCESS || createMetaQCStatus === STATUS.FAILED) {
        dispatch(setCreateMetaQC(STATUS.NOT_STARTED));
      }
    };
  }, [createMetaQCStatus]);



  return (
    <>
      <Drawer
        anchor={"right"}
        open={drawer}
        onClose={() => setDrawer(false)}
        PaperProps={{ sx: { width: "50%", padding: "40px" } }}
      >
        <TaskCompleted taskID={currentTaskID} />
      </Drawer>

      <div className={classes.container}>
        <div style={{ flex: 3 }}>
          {/* <SearchBar
            searchTerm={searchTerm}
            onSearchChange={handleSearchChange}
          /> */}

          {/* <Divider orientation='vertical' variant='inset' flexItem/> */}

          <Filter 
            anchorE3={anchorE3}
            anchorEl={anchorE3}
            setAnchorE3={setAnchorE3}
            onClose={handleClose}
            value={value}
            handleChange={handleChange}
            handleTaskNameToggle={handleTaskNameToggle}
            openList={openList}
            resetTaskName={resetTaskName}
            filterInFocus={filterInFocus}
            taskName={taskName}
            handleFieldChange={handleFieldChange}
            setfilterInFocus={setfilterInFocus}
            handleTaskEmailNameToggle={handleTaskEmailNameToggle}

            resetTaskEmailName={resetTaskEmailName}
            handleByTaskListClick={handleByTaskListClick}
            taskAllocation={taskAllocation}
            resetTaskType={resetTaskType}
            selectedTaskType={selectedTaskType}
            handleTaskTypeSelect={handleTaskTypeSelect}
            handleByReviewListClick={handleByReviewListClick}
            byReviewListOpen={byReviewListOpen}
            resetStatus={resetStatus}
            ByReviewListItems={ByReviewListItems}
            handleStatusToggle={handleStatusToggle}
            selectedStatus={selectedStatus}
            handleBySelectVendorClick={handleBySelectVendorClick}

            resetSelectVendor={resetSelectVendor}
            openAutoComplete={openAutoComplete}
            handleAutoCompleteOpen={handleAutoCompleteOpen}
            handleAutoCompleteClose={handleAutoCompleteClose}
            selectedVendor={selectedVendor}
            options={options}
            setVendorId={setVendorId}
            setSelectedVendor={setSelectedVendor}
            loading={loading}
            handleAutocompleteInput={handleAutocompleteInput}
            handleApplyClick={handleApplyClick}
          />
        </div >

        <div style={{ flex: 3 }} className={classes.actionsContainer}>
          <PaginationEntries
            itemsPerPage={itemsPerPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "20px",
            }}
          >
            <Badge badgeContent={filterCountApplied} color="error">
              <IconButton
                color="primary"
                aria-label="filter options"
                onClick={handleClick}
                value={selectedValue}
                autoFocus={false}
                sx={{

                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                  background: "linear-gradient(45deg, #42a5f5, #478ed1)",
                  borderRadius: "30%",
                  transition: "transform 0.3s ease-in-out",
                  "&:hover": {
                    background: "linear-gradient(45deg, #478ed1, #42a5f5)",
                    transform: "scale(1.1)",
                  },
                }}
              >
                <Tooltip title={
                  "Filters"
                }
                  placement="bottom-end"
                  arrow>
                  <FilterAltIcon fontSize="medium" sx={{ color: "white" }} />
                </Tooltip>
              </IconButton>
            </Badge>
          </Box>
          <IconButton
            // fontSize="small"
            onClick={handleMenuClick}
            sx={{
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              background: "linear-gradient(45deg, #42a5f5, #478ed1)",
              borderRadius: "30%",
              transition: "transform 0.3s ease-in-out",
              width: "40px",
              height: "40px",
              marginTop: "8px",
              color: "white",
              cursor: "pointer",
              "&:hover": {
                background: "linear-gradient(45deg, #478ed1, #42a5f5)",
                transform: "scale(1.1)",
              },
            }}
          >
            {getTaskDataStatus === STATUS.PENDING ? <CircularProgress size={17} /> : <MoreVertIcon style={{ color: "white" }} />}
          </IconButton>
          {/* <AddButton onClick={handleMenuClick}>Actions</AddButton> */}
          <Divider orientation="vertical" flexItem sx={{ height: '1px' }} />
          <Menu
            id="invite-menu"
            anchorEl={anchorE2}
            open={Boolean(anchorE2)}
            onClose={handleMenuClose}
          >
            {association_type_company && (
              <>
                <MenuItem onClick={() => handleVendorAllocation()}>Vendor Bulk Allocation</MenuItem>
                <MenuItem onClick={() => handleVendorUnallocation()}>Vendor Bulk Unallocation</MenuItem>
                {/* {fetchPermissions.vendor_unallocation ?.[0] && <MenuItem disabled={!fetchPermissions.vendor_unallocation?.[0]} onClick={() => handleMenuItemClick('Vendor Unallocation')}>Bulk Vendor Unallocation</MenuItem>} */}
                {fetchPermissions.task_allocation?.[0] && <MenuItem disabled={!fetchPermissions.task_allocation?.[0]} onClick={() => handleMenuItemClick('Task Allocation')}>Bulk Task Allocation</MenuItem>}
                {fetchPermissions.task_allocation?.[0] && <MenuItem disabled={!fetchPermissions.task_allocation?.[0]} onClick={() => handleMenuItemClick('Task Unallocation')}>Bulk Task Unallocation</MenuItem>}
                {fetchPermissions.review_allocation?.[0] && <MenuItem disabled={!fetchPermissions.review_allocation?.[0]} onClick={() => handleMenuItemClick('Review Allocation')}>Bulk Review Allocation</MenuItem>}
                {fetchPermissions.review_allocation?.[0] && <MenuItem onClick={() => handleMenuItemClick('Review Unallocation')}>Bulk Review Unallocation</MenuItem>}
                {fetchPermissions.export_data?.[0] && <MenuItem disabled={!fetchPermissions.export_data?.[0]} onClick={() => handleMenuItemClick('Download Task Data')}>Download Task Data</MenuItem>}
                <MenuItem onClick={() => handleMenuItemClick('Config Data')}>Export Completed Tasks</MenuItem>


                <MenuItem onClick={() => handleMenuItemClick('Upload Task')}>Upload Task</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick('Delivered Task')}>Mark Task as Delivered</MenuItem>
              </>
            )}
            {association_type_vendor && (
              <>
                {/* {fetchPermissions.vendor_unallocation ?.[0] && <MenuItem disabled={!fetchPermissions.vendor_unallocation?.[0]} onClick={() => handleMenuItemClick('Vendor Unallocation')}>Bulk Vendor Unallocation</MenuItem>} */}
                {fetchPermissions.task_allocation_bulk?.[0] && <MenuItem disabled={!fetchPermissions.task_allocation_bulk?.[0]} onClick={() => handleMenuItemClick('Task Allocation')}>Bulk Task Allocation</MenuItem>}
                {fetchPermissions.task_unallocation_bulk?.[0] && <MenuItem disabled={!fetchPermissions.task_unallocation_bulk?.[0]} onClick={() => handleMenuItemClick('Task Unallocation')}>Bulk Task Unallocation</MenuItem>}
                {fetchPermissions.review_allocation_bulk?.[0] && <MenuItem disabled={!fetchPermissions.review_allocation_bulk?.[0]} onClick={() => handleMenuItemClick('Review Allocation')}>Bulk Review Allocation</MenuItem>}
                {fetchPermissions.review_unallocation_bulk?.[0] && <MenuItem onClick={() => handleMenuItemClick('Review Unallocation')}>Bulk Review Unallocation</MenuItem>}
                {fetchPermissions.export_data?.[0] && <MenuItem disabled={!fetchPermissions.export_data?.[0]} onClick={() => handleMenuItemClick('Download Task Data')}>Download Task Data</MenuItem>}

              </>
            )}
          </Menu>
        </div>
      </div >
      <TableContainer>
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <CircularProgress />
          </div>
        )}
        {!loading && (
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead
              sx={{
                backgroundColor: "#F7F9FA",
                "&:first-child td, &:first-child th": { borderBottom: 0 },
              }}
            >
              {headers.map((header) => (
                <TableCellHead key={header}>{header}</TableCellHead>
              ))}
              {/* </TableRow> */}
            </TableHead>
            <TableBody>
              {getStatus === STATUS.SUCESS &&
                upload &&
                upload.results &&
                upload.results.length > 0 ? (
                upload.results.map((row: any) => (
                  <>
                    <TableRowItem
                      key={row.id}
                      row={row}
                      onEditClick={handleEditClick}
                      onDeleteClick={handleDeleteClick}
                      handleClick={() => handleTaskClick(row)}
                    />
                  </>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={headers.length}
                    className={classes.noUsersMessage}
                  >
                    <Error
                      message={
                        "There are no tasks added as of yet. You can add a task to see it here."
                      }
                    />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
      </TableContainer>

      <Dialog
        open={isUnsavedModalOpen}
        onClose={handleCancelClose}
      >
        <div style={{ padding: '16px', borderRadius: '8px', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <Typography variant="h6" gutterBottom style={{ fontFamily: 'Montserrat', fontWeight: '600', fontSize: '16px', lineHeight: '18px', display: 'flex', alignItems: 'center', color: '#000000' }}>
                Unsaved Changes
              </Typography>
            </div>
          </div>
          <Divider style={{ marginBottom: '10px' }} />
          <Typography sx={{ fontFamily: 'Montserrat', fontWeight: '500', fontSize: '14px', lineHeight: '18px', color: '#000000', marginTop: '30px' }}>
            You have unsaved changes. Are you sure you want to leave?
          </Typography>

          <DialogActions>
            <Button
              sx={{
                minWidth: '70px',
                minHeight: '40px',
                backgroundColor: '#F7F9FA',
                padding: '8px 16px',
                gap: '4px',
                ml: '60%',
                borderRadius: '8px',
                '&:hover': { backgroundColor: '#E0E0E0' },
              }}
              onClick={handleCancelClose}
            >
              <Typography
                sx={{
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  fontSize: '14px',
                  color: '#10383D',
                  textTransform: 'none',
                }}
              >
                No
              </Typography>
            </Button>
            <Button
              sx={{
                minWidth: '66px',
                minHeight: '40px',
                backgroundColor: '#3D85C6',
                padding: '8px 16px',
                gap: '4px',
                borderRadius: '8px',
                '&:hover': { backgroundColor: '#3D85C6' },
              }}
              onClick={handleConfirmClose}
            >
              <Typography
                sx={{
                  fontFamily: 'Montserrat',
                  fontWeight: '600',
                  fontSize: '14px',
                  color: '#FFFFFF',
                  textTransform: 'none',
                }}
              >
                Yes
              </Typography>
            </Button>
          </DialogActions>
        </div>
      </Dialog>

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        paginate={paginate}
        itemsPerPage={itemsPerPage}
      />
      <DeleteModal
        open={showDeleteModal}
        onCancel={cancelDelete}
        onConfirm={confirmDelete}
      />
      <AllocationModal
        open={openModal}
        onClose={handleCloseModal}
        onEmailClick={handleEmailClick}
        taskers={
          allocationType === "TASK" ? filteredTaskers : filteredReviewers
        }
        selectedEmail={selectedEmail}
        searchQuery={searchQuery}
        onSearchChange={handleSearchAllocation}
        onAllocateClick={handleAllocateClick}
        isCheckboxMode={true}
        selectedIds={selectedIds}
        allocationType={allocationType}
      // taskers={taskers}
      // onSearchChange={handleSearchAllocation}
      // searchQuery={searchQuery}
      />
      <InfoModal
        open={openInfoModal}
        onClose={handleCloseInfoModal}
        selectedIds={selectedIds}
        onNextClick={handleNextClick}
      />
      <InfoModalReview
        open={openInfoModalReview}
        onClose={handleCloseInfoModalReview}
        selectedIds={selectedIds}
        onNextClick={handleNextClick}
      />


      <InfoModelTaskUnallocation
        open={open} setOpen={setOpen}
        openTaskUnallocationModel={openTaskUnallocationModel}
        openReviewUnallocationModel={openReviewUnallocationModel}
        setOpenTaskUnallocationModel={setOpenTaskUnallocationModel}
        setOpenReviewUnallocationModel={setOpenReviewUnallocationModel} />


      <ConfirmationDialog
        open={openConfirmationDialog}
        onClose={handleCancelConfirmation}
        onConfirm={handleConfirmAllocation}
      />
      <SnackbarNotification
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
    </>
  );
};

export default TaskUploadTable;
