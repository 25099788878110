import React from 'react';
import { IconButton, TableRow, Tooltip } from '@mui/material';
import { TableCellBody } from '../../table/tableCell';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store/appStore';

interface TableRowItemProps {
    row: any;
    handleClick: (row: any) => void;
    onDeleteClick: (userId: string) => void;
    onToggleDisable: (row: any) => void;
}

const TableRowItem: React.FC<TableRowItemProps> = ({ row, onDeleteClick, onToggleDisable, handleClick }) => {
    const access = useSelector((state: RootState) => state.company.company.data)
    return (
        <TableRow
            key={row.id}
        >
            {access.id === row.user_id ? (<TableCellBody>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} >
                    <div style={{ flex: 3, overflow: 'hidden', textOverflow: 'ellipsis'}}>
                        {row.user_first_name}
                    </div>
                    
                </div>
            </TableCellBody>): 
            <TableCellBody>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} onClick={() => handleClick(row)}>
                    <div style={{ flex: 3, overflow: 'hidden', textOverflow: 'ellipsis', cursor: 'pointer' }}>
                        {row.user_first_name}
                    </div>
                    <div style={{ flex: 1 }}>
                        {<KeyboardArrowRightIcon fontSize='small' style={{ cursor: "pointer" }} />}
                    </div>
                </div>
            </TableCellBody>
            }

            <TableCellBody>{row.project_name}</TableCellBody>
            <TableCellBody>{row.user_email}</TableCellBody>
            <TableCellBody>
            <Tooltip title={access.id === row.user_id ? 'Cannot Take Actions On Your own user': 'Delete this user'}>
                <IconButton sx={{ color: '#C00F00', cursor:  access.id === row.user_id ? 'not-allowed' : 'pointer', marginRight: '5px' }} onClick={() => access.id === row.user_id ? () => {} : onDeleteClick(row.id)} >
                    <DeleteOutlineOutlinedIcon fontSize="small" />
                </IconButton>
            </Tooltip>
                <Tooltip title={access.id === row.user_id ? 'Cannot Take Actions On Your own user': 'Block this user'}>
                    <IconButton sx={{ color: row.is_blocked ? 'grey' : 'red', cursor: access.id === row.user_id ? 'not-allowed' : 'pointer', marginRight: '5px'}} onClick={() => access.id === row.user_id ? () => {} : onToggleDisable(row)} >
                        <DoDisturbIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </TableCellBody>
        </TableRow>
    );
};

export default TableRowItem;

