import React, { ReactNode } from 'react';
import TableCell from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';

const StyledTableCellBody = styled(TableCell)(({ theme }) => ({
  fontFamily: 'Montserrat',
  color: '#10383D',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '24px',
  textAlign: 'left',
}));

const StyledTableCellHead = styled(TableCell)(({ theme }) => ({
  fontFamily: 'Montserrat',
  fontWeight: 700,
  fontSize: '10px',
  lineHeight: '14px',
  textAlign: 'left',
}));

interface TableCellProps {
  children: ReactNode;
}

export const TableCellBody: React.FC<TableCellProps> = ({ children }) => {
  return <StyledTableCellBody>{children}</StyledTableCellBody>;
};

export const TableCellHead: React.FC<TableCellProps> = ({ children }) => {
  return <StyledTableCellHead>{children}</StyledTableCellHead>;
};

