import React from 'react';
import { Typography } from '@mui/material';

interface AudioActionProps {
    actionValue: any[];
    heading?: string;
}

export default function AudioAction({ actionValue, heading }: AudioActionProps) {
    if (actionValue?.length === 0) {
        return <Typography>No audio recordings available.</Typography>;
    }

    return (
        <>
            {actionValue.map((value: any, valueIndex: number) => {
                return (
                    <div key={valueIndex} style={{ display: 'flex', justifyContent:'center', alignItems:'center', flexDirection:'row', marginBottom: '10px'}}>
                        <Typography sx={{ flex:1, fontWeight: 'bold', marginRight: '10px', marginTop: '15px'}}>
                        {value.config_details?.value?.title || 'Audio'} :
                        </Typography>
                        <div style={{flex:2 }}>
                            <audio controls style={{ marginLeft: "3.4%" }}>
                                <source src={value.url} type="audio/mpeg" />
                                Your browser does not support the audio element.
                            </audio>
                        </div>

                    </div>  
                )
            })}
        </>
    );
}
