import React, { useState } from "react";
import { Typography } from "@mui/material";

interface ImageShowActionProps {
    action_value?: any;
}

export default function ImageShowAction({ action_value }: ImageShowActionProps) {
    const [loading, setLoading] = useState(false);

    const handleLoad = () => {
        setLoading(false);
    };

    return (
        <>
        {action_value && action_value.map((action: any, index: number) => (
            <div key={index} style={{ display: 'flex', marginBottom: '10px', flexDirection:'column'}}>
                <Typography sx={{ fontWeight: 'bold', marginRight: '20px' }}>{action.config_details.value.title}: </Typography>
                {loading && <p>Loading Image...</p>}
                {action.config_details.default_value ?
                    <img src={action.config_details.default_value} alt="action-img" className="w-96 h-96" style={{ textAlign: 'center', marginLeft: '30%', display: loading ? 'none' : 'block' }} onLoad={handleLoad} /> : (
                        <p>Image not available.</p>
                    )}
            </div>
        ))}
        </>
    );
}
